import { ReactElement, useEffect, useState } from 'react';
import { Button, Modal } from '@sicpama/core-components';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { CenterLoader } from '../../components/loader';
import { foodCourtService, storeService, fingerPrintService } from '../../services';
import { LOCAL_STORAGE_KEY } from '../../constants';
import { useAuthState } from 'stores';

const FoodCourtPage = (): ReactElement => {
  const { foodCourtId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState<any[]>([]);
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isNavigating, tableToken, setIsNavigating, setTableToken } = useAuthState();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const _stores = (await foodCourtService.getFoodCourtData(foodCourtId as string))?.stores;
      if (_stores.length === 1) {
        return goToASpecificStore(_stores[0].id);
      }
      setStores(_stores);
      setIsLoading(false);
    })();
  }, [foodCourtId]);

  const goToASpecificStore = async (storeId: number): Promise<void> => {
    const fingerprintRequestId = localStorage.getItem(LOCAL_STORAGE_KEY.FINGERPRINT_REQUEST_ID);
    if (!fingerprintRequestId) {
      const fingerPrintResult = await fingerPrintService.getFingerPrintData();
      localStorage.setItem(LOCAL_STORAGE_KEY.FINGERPRINT_REQUEST_ID, fingerPrintResult.requestId);
      localStorage.setItem(LOCAL_STORAGE_KEY.FINGERPRINT, fingerPrintResult.fingerPrint);
    }
    setIsLoading(true);
    const { token } = await storeService.createVirtualTable(storeId);
    setIsLoading(false);
    setIsNavigating(false);
    setTableToken(token);
  };

  useEffect(() => {
    if (tableToken && !isNavigating) {
      navigate({
        pathname: '/',
        search: `?${createSearchParams({ token: tableToken })}`,
      });
    }
  }, [tableToken, isNavigating]);

  if (isLoading) {
    return <CenterLoader />;
  }

  return (
    <div className="fixed inset-0 flex flex-col p-8 pt-0 overflow-y-auto">
      <div className="flex flex-col flex-wrap justify-between gap-6 mt-8 ">
        {stores.map((store, index) => (
          <div
            className="flex items-center relative pl-[25.5px] pr-6 py-2 rounded min-h-[84] border-2 border-[#A6C7E9] gap-4 text-[32px]"
            key={index}
            onClick={async () => goToASpecificStore(store.id)}
            onKeyDown={async () => goToASpecificStore(store.id)}
          >
            <img
              className="w-[60px] h-[60px] object-contain z-0 rounded-lg"
              src={
                store.coverPhotoKey ||
                'https://cdn.sicpama.com/food-court/menu_selection_cartoon.jpg'
              }
            />
            <div className="w-full text-center text-[#212121] font-bold">{store.displayName}</div>
          </div>
        ))}
      </div>
      {foodCourtId === 'c4b7550b-4b2d-455e-b2f7-08cc84a263e4' ? (
        <div className="mt-[40px] flex items-center justify-center">
          {stores.map((store, index) => (
            <div
              key={index}
              onClick={async () => goToASpecificStore(store.id)}
              onKeyDown={async () => goToASpecificStore(store.id)}
            >
              <Button
                borderColor="red"
                title="자몽마켓으로 접속하기"
                titleColor="orange"
                type="outline"
              />
            </div>
          ))}
        </div>
      ) : null}

      {foodCourtId === 'c4b7550b-4b2d-455e-b2f7-08cc84a263e4' ? null : (
        <>
          <div className="mt-[40px] flex items-center justify-center">
            <img className="w-8 h-8 shrink-0" src="/icons/fork.svg" />
            <div className="text-[22px] text-[#1A154C] font-bold">
              {t('pages.food-court.instruction.title')}
            </div>
          </div>
          <div className="mt-2 p-2 flex flex-col w-full text-[#1A154C] bg-[#A6C7E9] rounded-[16px]">
            <div className="flex w-full gap-2">
              <div className="w-[14px] shrink-0">1.</div>
              <div>{t('pages.food-court.instruction.instruction1')}</div>
            </div>
            <div className="flex w-full gap-2">
              <div className="w-[14px] shrink-0">2.</div>
              <div>{t('pages.food-court.instruction.instruction2')}</div>
            </div>
            <div className="flex w-full gap-2">
              <div className="w-[14px] shrink-0">3.</div>
              <div>{t('pages.food-court.instruction.instruction3')}</div>
            </div>
          </div>
        </>
      )}

      <div className="flex justify-center w-full mt-auto">
        <img src="/images/sicpama-text-logo.png" className="w-[192px] h-auto" />
      </div>
      <Modal
        opened={
          foodCourtId === 'c4b7550b-4b2d-455e-b2f7-08cc84a263e4'
            ? !isOpenCouponModal
            : isOpenCouponModal
        }
        withCloseButton={true}
        closeOnClickOutside={true}
        centered={true}
        onClose={() => {
          setIsOpenCouponModal(true);
        }}
      >
        <img src="/images/jamong.png" alt="JamongPromotion" />
      </Modal>
    </div>
  );
};

export default FoodCourtPage;
