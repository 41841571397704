import * as React from "react";

function SvgUserSquare(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.929 20v.5h1V20h-1zm9.142 0v.5h1V20h-1zM7.93 20v-2.286h-1V20h1zm2.928-5.214h2.286v-1h-2.286v1zm5.214 2.928V20h1v-2.286h-1zm-2.928-2.928a2.928 2.928 0 012.928 2.928h1a3.928 3.928 0 00-3.928-3.928v1zm-5.214 2.928a2.928 2.928 0 012.928-2.928v-1a3.928 3.928 0 00-3.928 3.928h1zM12 6.929a2.786 2.786 0 00-2.786 2.785h1c0-.986.8-1.785 1.786-1.785v-1zm2.786 2.785A2.786 2.786 0 0012 6.93v1c.986 0 1.786.8 1.786 1.785h1zM12 12.5a2.786 2.786 0 002.786-2.786h-1c0 .986-.8 1.786-1.786 1.786v1zm0-1c-.986 0-1.786-.8-1.786-1.786h-1A2.786 2.786 0 0012 12.5v-1zm-6.857-7h13.714v-1H5.143v1zm14.357.643v13.714h1V5.143h-1zM18.857 19.5H5.143v1h13.714v-1zM4.5 18.857V5.143h-1v13.714h1zm.643.643a.643.643 0 01-.643-.643h-1c0 .908.736 1.643 1.643 1.643v-1zm14.357-.643a.643.643 0 01-.643.643v1c.908 0 1.643-.735 1.643-1.643h-1zM18.857 4.5c.355 0 .643.288.643.643h1c0-.907-.735-1.643-1.643-1.643v1zm-13.714-1c-.907 0-1.643.736-1.643 1.643h1c0-.355.288-.643.643-.643v-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUserSquare;
