import * as React from 'react';

function SvgCutlery(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox='0 0 30 30'
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon_pork">
        <g id="color_fill">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.42164 2.29416C6.03182 2.68538 6.03295 3.31854 6.42417 3.70837L10.4127 7.68265C10.6265 7.89571 10.7961 8.14855 10.9116 8.42648C11.0272 8.7044 11.0866 9.00216 11.0866 9.30277L11.0866 10.0671C11.0866 10.3071 11.134 10.5449 11.2261 10.7664C11.3182 10.9879 11.4531 11.1889 11.6229 11.358L12.2068 11.9399C12.5981 12.3297 13.2312 12.3286 13.6211 11.9374C14.0109 11.5462 14.0098 10.913 13.6185 10.5232L13.0866 9.99312L13.0866 9.30278C13.0866 8.73849 12.975 8.17981 12.7584 7.65868C12.5417 7.13759 12.2243 6.66436 11.8244 6.26592L7.83586 2.29163C7.44463 1.90181 6.81147 1.90294 6.42164 2.29416Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.29101 6.40999C1.90119 6.80121 1.90232 7.43437 2.29354 7.8242L6.28196 11.7984C6.68182 12.1969 7.15642 12.5129 7.67843 12.7284C8.20046 12.9439 8.75985 13.0547 9.3247 13.0547L10.0211 13.0547L10.5545 13.5863C10.9457 13.9761 11.5789 13.975 11.9687 13.5837C12.3585 13.1925 12.3574 12.5594 11.9662 12.1695L11.3825 11.5879C11.2127 11.4187 11.0112 11.2845 10.7899 11.1931C10.5685 11.1017 10.3313 11.0547 10.0919 11.0547L9.32469 11.0547C9.02154 11.0547 8.72144 10.9952 8.44156 10.8797C8.16168 10.7642 7.90762 10.5949 7.69379 10.3818L3.70522 6.40746C3.314 6.01764 2.68084 6.01877 2.29101 6.40999Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.35802 4.35262C3.96819 4.74384 3.96932 5.37701 4.36054 5.76683L8.49102 9.8826C8.88225 10.2724 9.51541 10.2713 9.90524 9.88007C10.2951 9.48885 10.2939 8.85569 9.90271 8.46586L5.77223 4.3501C5.38101 3.96027 4.74784 3.9614 4.35802 4.35262Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.516 3.09867C25.1253 2.713 24.4966 2.7145 24.1077 3.10203L5.0853 22.0567C4.53644 22.6036 4.22743 23.3462 4.22743 24.1212C4.22743 24.8962 4.53642 25.6387 5.08527 26.1857C5.6341 26.7322 6.37775 27.0388 7.15214 27.0388C7.92652 27.0388 8.66993 26.7324 9.21876 26.1859L9.22502 26.1797L13.8712 21.4703C14.3597 20.976 14.6344 20.3094 14.6349 19.6141L14.6349 19.3289C14.6349 19.2438 14.6519 19.1592 14.6849 19.0805C14.7162 19.0057 14.7616 18.9373 14.8186 18.879L15.4032 18.341C15.4835 18.2673 15.5814 18.2145 15.688 18.188C15.7947 18.1614 15.9064 18.1621 16.0127 18.1898C16.6037 18.3435 17.2252 18.3407 17.8147 18.1813C18.4042 18.022 18.942 17.7117 19.3746 17.2809L23.7856 12.8851C25.2194 11.4565 26.2157 9.65224 26.5969 7.91306C26.9729 6.1977 26.7795 4.34564 25.516 3.09867ZM16.5167 16.2543C16.087 16.1424 15.6361 16.1399 15.2052 16.2471C14.7741 16.3544 14.3767 16.568 14.0496 16.8688L13.4494 17.421C13.4412 17.4285 13.4331 17.4362 13.4252 17.444C13.1751 17.69 12.9763 17.9834 12.8405 18.307C12.7048 18.6306 12.6349 18.978 12.6349 19.3289L12.6349 19.6127C12.6348 19.781 12.5683 19.9433 12.4487 20.0644L7.80484 24.7714C7.63185 24.9423 7.39734 25.0388 7.15214 25.0388C6.90579 25.0388 6.67023 24.9413 6.49701 24.769M16.5167 16.2543C16.7713 16.3205 17.0389 16.3192 17.2928 16.2506C17.5466 16.182 17.7776 16.0486 17.9631 15.864L22.3739 11.4684C23.5568 10.2897 24.3503 8.82156 24.6433 7.48486C24.8241 6.66008 24.8041 5.95771 24.6306 5.4044L6.49701 23.4734C6.32398 23.6458 6.22743 23.8789 6.22743 24.1212C6.22743 24.3634 6.32414 24.5966 6.49701 24.769"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.7925 17.7929C21.183 17.4024 21.8162 17.4024 22.2067 17.7929L26.2067 21.7929C26.5699 22.156 26.9957 22.7882 27.1241 23.5768C27.2626 24.4275 27.0372 25.3766 26.2067 26.2071C25.3762 27.0376 24.4271 27.263 23.5764 27.1245C22.7878 26.9961 22.1556 26.5703 21.7925 26.2071L16.7925 21.2071C16.402 20.8166 16.402 20.1834 16.7925 19.7929C17.183 19.4024 17.8162 19.4024 18.2067 19.7929L23.2067 24.7929C23.3436 24.9297 23.6114 25.1039 23.8978 25.1505C24.1221 25.187 24.423 25.1624 24.7925 24.7929C25.162 24.4234 25.1866 24.1225 25.1501 23.8982C25.1035 23.6118 24.9293 23.344 24.7925 23.2071L20.7925 19.2071C20.402 18.8166 20.402 18.1834 20.7925 17.7929Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgCutlery;
