import clsx from 'clsx';
import type { ReactElement } from 'react';

interface Props {
  className?: string;
}
const Divider = ({ className }: Props): ReactElement => {
  return <div className={clsx(`w-full h-[7px] bg-inactive min-h-[7px]`, className)} />;
};

export default Divider;
