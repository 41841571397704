var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from 'react';
import { createStyles, Checkbox as MantineCheckbox, rem, } from '@mantine/core';
var useStyles = createStyles(function (theme, _a) {
    var gap = _a.gap;
    return ({
        vertical: {
            display: 'flex',
            flexDirection: 'column',
            gap: gap || rem(16),
        },
        horizontal: {
            display: 'flex',
            gap: gap || rem(16),
        },
    });
});
var Checkbox = function (_a) {
    var label = _a.label, labelProps = _a.labelProps, description = _a.description, required = _a.required, error = _a.error, size = _a.size, value = _a.value, onChange = _a.onChange, items = _a.items, _b = _a.direction, direction = _b === void 0 ? 'vertical' : _b, gutter = _a.gutter, checkboxColor = _a.checkboxColor, iconColor = _a.iconColor, textColor = _a.textColor, borderColor = _a.borderColor, id = _a.id, remainingProps = __rest(_a, ["label", "labelProps", "description", "required", "error", "size", "value", "onChange", "items", "direction", "gutter", "checkboxColor", "iconColor", "textColor", "borderColor", "id"]);
    var classes = useStyles({ gap: gutter }).classes;
    var className = useMemo(function () { return (direction === 'vertical' ? classes.vertical : classes.horizontal); }, [direction]);
    return (React.createElement(MantineCheckbox.Group, __assign({ label: label, labelProps: __assign({ mb: 16 }, labelProps), description: description, required: required, error: error, value: value, onChange: onChange, size: size, id: id }, remainingProps),
        React.createElement("div", { className: className }, items.map(function (item) {
            var label = item.label, labelPosition = item.labelPosition, value = item.value, borderRadius = item.borderRadius, error = item.error, dash = item.dash, description = item.description, disabled = item.disabled, rest = __rest(item, ["label", "labelPosition", "value", "borderRadius", "error", "dash", "description", "disabled"]);
            return (React.createElement(MantineCheckbox, __assign({ key: value, radius: borderRadius, value: value, size: size, label: label, labelPosition: labelPosition, error: error, indeterminate: dash, description: description, checked: value.toString().includes(value.toString()), styles: function (theme) { return ({
                    input: {
                        '&:checked': {
                            backgroundColor: checkboxColor,
                            borderColor: borderColor || checkboxColor,
                        },
                        '&:disabled': {
                            backgroundColor: 'lightgrey',
                            borderColor: 'lightgrey',
                        },
                    },
                    label: {
                        color: textColor,
                    },
                    icon: {
                        color: "".concat(iconColor, " !important"),
                    },
                }); }, disabled: disabled, id: item.id }, rest)));
        }))));
};
export default React.memo(Checkbox);
