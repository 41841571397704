var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Indicator as MantineIndicator } from '@mantine/core';
var Indicator = function (_a) {
    var borderRadius = _a.borderRadius, inline = _a.inline, label = _a.label, offset = _a.offset, position = _a.position, processing = _a.processing, size = _a.size, children = _a.children, withBorder = _a.withBorder, color = _a.color, _b = _a.fontWeight, fontWeight = _b === void 0 ? 700 : _b, rest = __rest(_a, ["borderRadius", "inline", "label", "offset", "position", "processing", "size", "children", "withBorder", "color", "fontWeight"]);
    return (React.createElement(MantineIndicator, __assign({ radius: borderRadius, inline: inline, label: label, offset: offset, position: position, processing: processing, size: size, withBorder: withBorder, color: color, styles: function (theme) { return ({
            root: {
                fontWeight: fontWeight,
            },
        }); } }, rest), children));
};
export default React.memo(Indicator);
