import * as React from "react";

function SvgReceipt(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 4v-.5a.5.5 0 00-.5.5H5zm13.714 0h.5a.5.5 0 00-.5-.5V4zm0 16l-.223.447a.5.5 0 00.723-.447h-.5zm-2.285-1.143l.223-.447a.5.5 0 00-.447 0l.224.447zM14.143 20l-.224.447a.5.5 0 00.447 0L14.143 20zm-2.286-1.143l.224-.447a.5.5 0 00-.447 0l.223.447zM9.571 20l-.223.447a.5.5 0 00.447 0L9.571 20zM5 20h-.5a.5.5 0 00.724.447L5 20zm2.286-1.143l.223-.447a.5.5 0 00-.447 0l.224.447zM5 4.5h13.714v-1H5v1zM18.214 4v16h1V4h-1zm.724 15.553l-2.286-1.143-.447.894 2.286 1.143.447-.894zm-2.733-1.143l-2.286 1.143.447.894 2.286-1.143-.447-.894zm-1.838 1.143L12.08 18.41l-.447.894 2.285 1.143.447-.894zm-2.734-1.143l-2.285 1.143.447.894 2.286-1.143-.447-.894zM5.5 20V4h-1v16h1zm4.295-.447L7.509 18.41l-.447.894 2.286 1.143.447-.894zM7.062 18.41l-2.286 1.143.448.894 2.285-1.143-.447-.894zm.795-9.339h2.286v-1H7.857v1zm4.572 0h3.428v-1H12.43v1zM7.857 12.5h2.286v-1H7.857v1zm4.572 0h3.428v-1H12.43v1zm0 3.429h3.428v-1H12.43v1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgReceipt;
