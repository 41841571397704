/* eslint-disable @typescript-eslint/dot-notation */
import UnsupportedDevice from 'pages/unsupported-device';
import { PropsWithChildren, createContext } from 'react';

class ContextState {}

export const DeviceDetectorContext = createContext(new ContextState());

export const DeviceDetectorProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  return (
    <DeviceDetectorContext.Provider value={{}}>
      {!isMobileDevice ? <UnsupportedDevice /> : children}
    </DeviceDetectorContext.Provider>
  );
};
