import { ICouponUsage } from '../submodules/sicpama-shared';
import BaseHttpService from './base-http.service';

export class CouponService extends BaseHttpService {
  public async getAvailableCoupons(storeId: number, customerId: string): Promise<ICouponUsage[]> {
    const response = await this.get<ICouponUsage[]>(
      `stores/${storeId}/customers/${customerId}/coupons`,
    );
    return response;
  }
}

const couponService = new CouponService();

export default couponService;
