import { PropsWithChildren, createContext, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { fingerPrintService, storeTableService } from 'services';
import { SquareLoader } from '../components/loader/square';
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import { isFoodCourtsPath, isPublicStoresPath } from 'utils/routing';
import { useAuthState } from '../stores';
import { ALLOWED_BYPASS_PATH_ROUTE_NAMES } from 'constants/route.constant';

class ContextState {}

export const AuthContext = createContext(new ContextState());

export const AuthProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, tableToken, setLoading, setTableToken, isNavigating, setIsNavigating } =
    useAuthState();

  const [searchParams] = useSearchParams();

  const emptyValue = useMemo(() => ({}), []);

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      setTableToken(token);
      localStorage.setItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN, token);
      console.log('Token_saved_successfully_29');
    }
  }, [searchParams, setTableToken]);

  useEffect(() => {
    const joinTable = async (token: string): Promise<void> => {
      setLoading(true);
      let fingerPrint = localStorage.getItem(LOCAL_STORAGE_KEY.FINGERPRINT) ?? '';

      if (!fingerPrint) {
        const fingerPrintResult = await fingerPrintService.getFingerPrintData();
        fingerPrint = fingerPrintResult.fingerPrint;
      }

      const _sessionId = await storeTableService.joinTable(token, fingerPrint);

      setLoading(false);

      if (_sessionId !== null && !isNavigating) {
        setIsNavigating(true);
        navigate('/auth');
      }
    };

    if (tableToken && !isLoading && !isNavigating) {
      joinTable(tableToken);
    }
  }, [tableToken, isLoading, navigate, isNavigating]);

  useEffect(() => {
    const sessionId = localStorage.getItem(LOCAL_STORAGE_KEY.SID);

    if (!sessionId) {
      console.warn('sessionId_auth_62: ', sessionId);
      console.warn('tableToken_auth_63: ', tableToken);
    }
    if (tableToken && !localStorage.getItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN)) {
      localStorage.setItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN, tableToken);
      console.log('Token_saved_successfully_67');
    }

    if (
      !ALLOWED_BYPASS_PATH_ROUTE_NAMES.includes(location.pathname) &&
      !isFoodCourtsPath(location.pathname) &&
      !isPublicStoresPath(location.pathname)
    ) {
      if (!sessionId && !tableToken && isNavigating) {
        navigate('/report-error');
      }
    }
  }, [location, tableToken, isNavigating]);

  if (isLoading) {
    return <SquareLoader />;
  }

  return <AuthContext.Provider value={emptyValue}>{children}</AuthContext.Provider>;
};
