var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Modal as MantineModal } from '@mantine/core';
var Modal = function (_a) {
    var _b = _a.borderRadius, borderRadius = _b === void 0 ? 'md' : _b, centered = _a.centered, style = _a.style, fullScreen = _a.fullScreen, title = _a.title, _c = _a.backgroundColor, backgroundColor = _c === void 0 ? '#fff' : _c, _d = _a.borderColor, borderColor = _d === void 0 ? '#999999' : _d, opened = _a.opened, onClose = _a.onClose, _e = _a.boxShadow, boxShadow = _e === void 0 ? 'sm' : _e, height = _a.height, size = _a.size, _f = _a.withCloseButton, withCloseButton = _f === void 0 ? false : _f, _g = _a.closeOnClickOutside, closeOnClickOutside = _g === void 0 ? true : _g, children = _a.children, overlayProps = _a.overlayProps, transitionProps = _a.transitionProps, zIndex = _a.zIndex, id = _a.id, rest = __rest(_a, ["borderRadius", "centered", "style", "fullScreen", "title", "backgroundColor", "borderColor", "opened", "onClose", "boxShadow", "height", "size", "withCloseButton", "closeOnClickOutside", "children", "overlayProps", "transitionProps", "zIndex", "id"]);
    return (React.createElement(MantineModal, __assign({ opened: opened, onClose: onClose, title: title, radius: borderRadius, centered: centered, withCloseButton: withCloseButton, closeOnClickOutside: closeOnClickOutside, style: __assign(__assign({}, style), { backgroundColor: backgroundColor, borderColor: borderColor, height: height }), size: size, shadow: boxShadow, fullScreen: fullScreen, overlayProps: overlayProps, transitionProps: transitionProps, zIndex: zIndex, id: id }, rest), children));
};
export default Modal;
