import * as React from "react";

function SvgOpenInNewTab(props) {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x="6" y="6" width="12" height="12" rx="1" fill="#444C54"/>
        <path d="M12.9766 7H16.9297M16.9297 7V10.9531M16.9297 7L11 12.9297" stroke="white"/>
    </svg>
  );
}

export default SvgOpenInNewTab;
