/* eslint-disable @typescript-eslint/no-empty-function */
import { useChannel } from '@ably-labs/react-hooks';
import { Button, Icon, Typography } from '@sicpama/core-components';
import clsx from 'clsx';
import JSConfetti from 'js-confetti';
import { sleep } from 'radash';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import Confetti from 'react-confetti';
import { Wheel } from 'react-custom-roulette';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LOCAL_STORAGE_KEY, ROUTE } from '../../constants';
import { orderService } from '../../services';
import { useOrderStore, useThemeStore } from '../../stores';
import numberLocaleFormat, {
  CURRENCY_TO_LOCALE_PRICE,
  roundNumberTo2DecimalPlaces,
} from '../../utils/numberLocaleFormat';
import { Container } from '../container';
import { SpinTheWheelResDto } from '../../types/order-items';
import { OrderStatus, PaymentOption } from '../../submodules/sicpama-shared';

export interface StyleType {
  backgroundColor?: string;
  textColor?: string;
}
export interface WheelData {
  option: string;
  style?: StyleType;
}

const SpinWheel2 = (): ReactElement => {
  const sessionId = localStorage.getItem(LOCAL_STORAGE_KEY.SID)!;
  const [mustStart, setMustStart] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const navigate = useNavigate();

  const { store } = useThemeStore();

  const { t } = useTranslation();

  const jsConfetti = new JSConfetti();

  const { myOrder, spinTheWheelParticipants, setPaymentOption } = useOrderStore();

  const currency = myOrder.currency || store.currency;

  const draftSpinTheWheelParticipants = useMemo(() => {
    return spinTheWheelParticipants.filter((x) => x.status === OrderStatus.DRAFT);
  }, [spinTheWheelParticipants]);

  const [result, setResult] = useState<SpinTheWheelResDto | null>(null);

  const [data, setData] = useState<WheelData[]>(
    spinTheWheelParticipants.map((x, index) => {
      return {
        option: x.customer.nickName || x.customer.fullName || '...',
        style: {
          backgroundColor: index % 2 === 0 ? 'white' : '',
        },
      };
    }),
  );

  const totalPrice = useMemo(() => {
    return spinTheWheelParticipants.reduce((acc, order) => {
      return +acc + (+order.grandTotal - +order.couponDiscountTotal);
    }, 0);
  }, [spinTheWheelParticipants]);

  useChannel(sessionId, 'start-spin-the-wheel', async (message) => {
    setResult(message.data);
    setTimeout(() => {
      // move to next render cycle, to ensure result is set
      setMustStart(true);
    }, 0);
  });

  const stopSpin = async (): Promise<void> => {
    setMustStart(false);
    setIsFinished(true);
    animateAfterStoppingSpin();
    await sleep(3000);
    if (result?.customerId === myOrder.customerId) {
      navigate(ROUTE.PAYMENT);
    }
    // changeBg();
  };

  const animateAfterStoppingSpin = async (): Promise<void> => {
    setConfetti(true);
    jsConfetti.addConfetti({
      emojis: ['💰', '💰', '💰', '💰', '💵', '💵', '💵'],
      emojiSize: 100,
      confettiNumber: 30,
    });
    await sleep(3000);
    setConfetti(false);
  };

  const onStart = async (): Promise<void> => {
    if (draftSpinTheWheelParticipants.length < 2 || isRunning || isFinished) {
      return;
    }
    setIsRunning(true);
    await orderService.spinTheWheel();
  };

  const payer = useMemo(() => {
    return spinTheWheelParticipants.find((o) => o.customerId === result?.customerId)?.customer;
  }, [result, spinTheWheelParticipants]);

  useEffect(() => {
    setData(
      draftSpinTheWheelParticipants.map((x, index) => {
        const { customer } = x;
        const { nickName, fullName } = customer;
        const option = myOrder?.customer.fullName === fullName ? 'You' : nickName || fullName;
        return {
          option,
          style: {
            backgroundColor: index % 2 === 0 ? 'white' : '#F9EBDE',
          },
        };
      }),
    );
  }, [spinTheWheelParticipants]);

  const onBackToTab = async (): Promise<void> => {
    if (isFinished && myOrder.customerId !== result?.customerId) {
      Swal.fire({
        icon: 'info',
        title: t('pages.search.wait')?.toString(),
        html: t('pages.spin-the-wheel-2.someoneWin', {
          payerName: payer?.nickName ?? payer?.fullName ?? '...',
        })?.toString(),
        confirmButtonColor: '#3fc3ee',
      });
      return;
    }
    await setPaymentOption(myOrder.id, PaymentOption.NOT_SELECTED);
    window.location.replace('/tab');
  };

  return (
    <Container
      customClassName="h-screen w-screen overflow-hidden scrollbar-hide"
      showArrowProgressBar={false}
    >
      <Confetti style={{ display: confetti ? 'block' : 'none' }} />
      <div className="fixed inset-0 flex flex-col items-center">
        <img
          src="/images/spin-background.svg"
          alt="background"
          className="absolute inset-0 w-screen"
        />
        {myOrder?.status === OrderStatus.DRAFT && (
          <div className="self-start">
            <Button
              title=""
              type="white"
              titleColor="#ffffff"
              leftIcon={<Icon name="ArrowLeft" color="black" type="filled" size={32} />}
              onClick={onBackToTab}
            ></Button>
          </div>
        )}

        <div className="relative flex flex-col items-center w-full h-full text-center mt-2 pb-[120px] overflow-y-auto">
          <Typography className="text-[#1A154C] text-[36px] font-bold uppercase leading-none">
            <Trans t={t} i18nKey="pages.spin-the-wheel-2.title" />
          </Typography>
          <Typography className="text-[36px] font-bold text-[#1A154C] uppercase  leading-none">
            <Trans t={t} i18nKey="pages.spin-the-wheel-2.title2" />
          </Typography>
          <div className="h-0 w-0 border-b-[5px] border-b-[#1A154C] border-r-[5px] border-l-[5px] border-t-0 bg-transparent border-transparent" />
          <div className="bg-[#1A154C] py-2 px-6 text-white h-[36px] rounded-full text-[20px] flex items-center">
            <Trans
              t={t}
              i18nKey={`pages.spin-the-wheel-2.${'totalPrice'}`}
              values={{
                formattedPrice: numberLocaleFormat(
                  roundNumberTo2DecimalPlaces(totalPrice),
                  CURRENCY_TO_LOCALE_PRICE[currency ?? 'KRW'],
                ),
              }}
            />
          </div>
          <div className="mt-7 flex flex-col items-center">
            <div className="z-3 relative bg-[#F36526] rounded-full">
              <Wheel
                prizeNumber={spinTheWheelParticipants.findIndex(
                  (o) => o.customerId === result?.customerId,
                )}
                mustStartSpinning={mustStart}
                data={data}
                outerBorderColor={'transparent'}
                radiusLineColor={'#FF3E19'}
                radiusLineWidth={5}
                onStopSpinning={stopSpin}
                spinDuration={0.5}
                perpendicularText={false}
                fontSize={20}
                textColors={['#FD800E']}
                innerRadius={30}
              />
              <div
                className={clsx(
                  'uppercase absolute top-[50%] left-[50%] bg-[#FF5E50] rounded-full w-[90px] h-[90px] text-white flex items-center justify-center text-[20px] font-bold',
                  {
                    'opacity-50 cursor-not-allowed': draftSpinTheWheelParticipants.length < 2,
                  },
                )}
                style={{
                  transform: 'translate(-50%, -50%)',
                }}
                onClick={onStart}
              >
                {t('pages.spin-the-wheel-2.start')}
              </div>
            </div>
            <img src="/images/wheel-bottom.svg" className="mt-[-30px] z-1" />
            <img src="/images/wheel-bottom-2.svg" className="mt-[-90px] z-2" />
          </div>
        </div>
        <div className="absolute text-center bottom-0 left-0 right-0 flex flex-col h-[120px] justify-center items-center px-8 py-6 bg-[#1F1B4D] z-[100]">
          {!mustStart && !isFinished && (
            <>
              <Typography className="text-[#F36526] text-[18px] font-bold uppercase leading-none">
                {draftSpinTheWheelParticipants.length < 2 ? (
                  <Trans t={t} i18nKey="pages.spin-the-wheel-2.conditionToStart" />
                ) : (
                  <Trans t={t} i18nKey="pages.spin-the-wheel-2.touchToStart" />
                )}
              </Typography>
            </>
          )}
          {mustStart && !isFinished && (
            <Typography className="text-[#F36526] text-[18px] font-bold uppercase leading-none">
              <Trans
                t={t}
                i18nKey="pages.spin-the-wheel-2.cannotQuitDuringGame"
                values={{ minNumberOfParticipants: 2 }}
              />
            </Typography>
          )}
          {isFinished &&
            (myOrder.customerId === result?.customerId ? (
              <>
                <Typography className="text-[#F36526] text-[18px] font-bold uppercase leading-none">
                  <Trans t={t} i18nKey="pages.spin-the-wheel-2.congratulation" />
                </Typography>
                <div className="flex justify-center mt-6 gap-4 w-full">
                  <div
                    className="flex items-center justify-center text-white w-full rounded-sm p-2 border border-white"
                    onClick={() => {
                      navigate(ROUTE.PAYMENT);
                    }}
                  >
                    {t('pages.spin-the-wheel-2.pay')}
                  </div>
                </div>
              </>
            ) : (
              <>
                <Typography className="text-[#F36526] text-[18px] font-bold uppercase leading-none">
                  <Trans
                    t={t}
                    i18nKey="pages.spin-the-wheel-2.someoneWin"
                    values={{
                      payerName: payer?.nickName ?? payer?.fullName ?? '...',
                    }}
                  />
                </Typography>
                <div
                  className="mt-6 flex items-center justify-center text-white w-full rounded-sm p-2"
                  style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)' }}
                >
                  {t('pages.spin-the-wheel-2.gameOver')}
                </div>
              </>
            ))}
        </div>
      </div>
    </Container>
  );
};

export default SpinWheel2;
