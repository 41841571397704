import * as React from "react";

function SvgId(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.786 17.429v.5h1v-.5h-1zm5.714 0v.5h1v-.5h-1zm-4.714 0v-.572h-1v.572h1zm4.714-.572v.572h1v-.572h-1zM9.143 14.5a2.357 2.357 0 012.357 2.357h1A3.357 3.357 0 009.143 13.5v1zm-2.357 2.357A2.357 2.357 0 019.143 14.5v-1a3.357 3.357 0 00-3.357 3.357h1zm2.357-9.071A2.214 2.214 0 006.929 10h1c0-.67.543-1.214 1.214-1.214v-1zM11.357 10a2.214 2.214 0 00-2.214-2.214v1c.67 0 1.214.543 1.214 1.214h1zm-2.214 2.214A2.214 2.214 0 0011.357 10h-1c0 .67-.544 1.214-1.214 1.214v1zm0-1c-.67 0-1.214-.543-1.214-1.214h-1c0 1.223.99 2.214 2.214 2.214v-1zm-4-4.714h13.714v-1H5.143v1zm14.357.643v9.143h1V7.143h-1zm-.643 9.786H5.143v1h13.714v-1zM4.5 16.286V7.143h-1v9.143h1zm.643.643a.643.643 0 01-.643-.643h-1c0 .907.736 1.643 1.643 1.643v-1zm14.357-.643a.643.643 0 01-.643.643v1c.908 0 1.643-.736 1.643-1.643h-1zM18.857 6.5c.355 0 .643.288.643.643h1c0-.907-.735-1.643-1.643-1.643v1zm-13.714-1c-.907 0-1.643.736-1.643 1.643h1c0-.355.288-.643.643-.643v-1zm8.571 4.429h3.429v-1h-3.429v1zm0 3.428h3.429v-1h-3.429v1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgId;
