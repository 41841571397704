import type { ReactElement } from 'react';

interface Props {
  text: string;
}
const MultiLinesText = ({ text }: Props): ReactElement => {
  return (
    <>
      {text?.split('\\n')?.map((str) => {
        return <div key={str}>{str}</div>;
      })}
    </>
  );
};

export default MultiLinesText;
