import { ICoupon, ICouponAdvertResDto } from '../submodules/sicpama-shared';
import { StoreResDto } from '../types';
import BaseHttpService from './base-http.service';
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import { fingerPrintService } from './fingerprint.service';

const PATH = 'consumer-stores';

class StoreService extends BaseHttpService {
  async getStoreData(): Promise<StoreResDto> {
    const body = await this.get<StoreResDto>(`${PATH}`);
    return body;
  }

  async getStoreById(storeId: number): Promise<StoreResDto> {
    const body = await this.get<StoreResDto>(`${PATH}/${storeId}`);
    return body;
  }

  async createVirtualTable(storeId: number): Promise<{ storeId: number; token: string }> {
    const requestId = localStorage.getItem(LOCAL_STORAGE_KEY.FINGERPRINT_REQUEST_ID);
    const response = await this.post<{ storeId: number; token: string }>(
      `${PATH}/${storeId}/virtual-tables`,
      {},
      {
        headers: {
          'fingerprint-request-id': requestId,
        },
      },
    );
    localStorage.removeItem(LOCAL_STORAGE_KEY.MY_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN);
    return response;
  }

  async getStoreCouponAdvert(storeId: string, couponId: string): Promise<ICouponAdvertResDto> {
    const response = await this.get<ICouponAdvertResDto>(
      `${PATH}/${storeId}/coupon-advert/${couponId}`,
      {
        headers: {
          'fingerprint-request-id': await this.getFingerPrintRequestId(),
        },
      },
    );
    return response;
  }

  async couponPickup(storeId: string, couponId: string): Promise<{ message: string }> {
    const response = await this.post<{ message: string }>(
      `${PATH}/${storeId}/coupon-pickup/${couponId}`,
      {},
      {
        headers: {
          'fingerprint-request-id': await this.getFingerPrintRequestId(),
        },
      },
    );
    return response;
  }

  async getFingerPrintRequestId(): Promise<string> {
    const fingerprintRequestId =
      localStorage.getItem(LOCAL_STORAGE_KEY.FINGERPRINT_REQUEST_ID) ??
      (await fingerPrintService.getFingerPrintData()).requestId;
    localStorage.setItem(LOCAL_STORAGE_KEY.FINGERPRINT_REQUEST_ID, fingerprintRequestId);
    return fingerprintRequestId;
  }
}

export const storeService = new StoreService();
