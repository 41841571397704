import { create } from 'zustand';
import { LanguageService, storeService } from '../services';

interface CouponAdvertState {
  couponAdvertHtml: string;
  isLoading: boolean;
  error: string | null;
  fetchCouponAdvert: (storeId: string, couponId: string) => Promise<void>;
}

export const useCouponAdvertStore = create<CouponAdvertState>((set) => ({
  couponAdvertHtml: '',
  isLoading: false,
  error: null,
  fetchCouponAdvert: async (storeId: string, couponId: string) => {
    set({ isLoading: true, error: null });
    try {
      const couponAdvert = await storeService.getStoreCouponAdvert(storeId, couponId);
      const html =
        couponAdvert.advert?.location?.MARKETING?.[LanguageService.getLanguage() ?? 'en'] ??
        couponAdvert.advert?.location?.LOGIN_PAGE?.[LanguageService.getLanguage() ?? 'en'] ??
        `<div>NO ADVERT DEFINED</div>`;
      set({ couponAdvertHtml: html, isLoading: false });
    } catch (err: any) {
      set({ error: err.message || 'Failed to fetch coupon advert', isLoading: false });
    }
  },
}));
