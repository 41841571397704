import * as React from 'react';

function SvgCustomer(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="1" y="8.82617" width="6.6087" height="8.17391" stroke="currentColor" strokeWidth="2" />
      <rect x="11.957" y="8.82617" width="6.6087" height="8.17391" stroke="currentColor" strokeWidth="2" />
      <rect x="2.56543" y="1" width="4.26087" height="4.26087" rx="2.13043" stroke="currentColor" strokeWidth="2" />
      <rect x="12.7393" y="1" width="4.26087" height="4.26087" rx="2.13043" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
}

export default SvgCustomer;
