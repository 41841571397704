import { IAdditionalLanguageDto } from '../submodules/sicpama-shared';

type AdditionalLanguageKey = 'name' | 'description';

interface Props {
  key: AdditionalLanguageKey;
  additionalLanguages?: IAdditionalLanguageDto[];
  defaultValue?: string;
}
export const getMenuTransByLanguage = ({
  key,
  additionalLanguages,
  defaultValue,
}: Props): string | undefined => {
  const language = window?.navigator.language;
  if (!additionalLanguages?.length) {
    return defaultValue;
  }

  const fallbackLanguage = additionalLanguages.find((lang) => lang.localeCode === 'en');

  if (!language) {
    return fallbackLanguage?.[key] ?? defaultValue;
  }

  const matchedLanguage = additionalLanguages.find(
    (lang) => language.slice(0, 2) === lang.localeCode,
  );
  if (matchedLanguage === undefined) {
    if (language.startsWith('ko') || language.startsWith('zh')) {
      // in case of korean, chinese fallback directly to default value instead of english
      return defaultValue;
    }
    return fallbackLanguage?.[key] ?? defaultValue;
  }

  return matchedLanguage[key] ?? undefined;
};
