import * as React from 'react';

function SvgCalendar1(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 10H12.5C12.5 9.82671 12.4103 9.66578 12.2629 9.57467C12.1155 9.48357 11.9314 9.47529 11.7764 9.55279L12 10ZM6.92857 4V6.85714H7.92857V4H6.92857ZM6.92857 6.85714V9.71429H7.92857V6.85714H6.92857ZM16.0714 4V6.85714H17.0714V4H16.0714ZM16.0714 6.85714V9.71429H17.0714V6.85714H16.0714ZM5.14286 7.35714H7.42857V6.35714H5.14286V7.35714ZM7.42857 7.35714H16.5714V6.35714H7.42857V7.35714ZM16.5714 7.35714H18.8571V6.35714H16.5714V7.35714ZM19.5 8V19.4286H20.5V8H19.5ZM18.8571 20.0714H5.14286V21.0714H18.8571V20.0714ZM4.5 19.4286V8H3.5V19.4286H4.5ZM5.14286 20.0714C4.78782 20.0714 4.5 19.7836 4.5 19.4286H3.5C3.5 20.3359 4.23553 21.0714 5.14286 21.0714V20.0714ZM19.5 19.4286C19.5 19.7836 19.2122 20.0714 18.8571 20.0714V21.0714C19.7645 21.0714 20.5 20.3359 20.5 19.4286H19.5ZM18.8571 7.35714C19.2122 7.35714 19.5 7.64496 19.5 8H20.5C20.5 7.09267 19.7645 6.35714 18.8571 6.35714V7.35714ZM5.14286 6.35714C4.23553 6.35714 3.5 7.09267 3.5 8H4.5C4.5 7.64496 4.78782 7.35714 5.14286 7.35714V6.35714ZM10 17.5H12V16.5H10V17.5ZM12 17.5H14V16.5H12V17.5ZM12.5 17V10H11.5V17H12.5ZM11.7764 9.55279L9.7764 10.5528L10.2236 11.4472L12.2236 10.4472L11.7764 9.55279Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCalendar1;
