import * as React from "react";

function SvgChatTyping(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.714 16.563l.416-.277a.5.5 0 00-.416-.223v.5zM12 19.99l-.416.277a.5.5 0 00.832 0L12 19.99zm2.286-3.427v-.5a.5.5 0 00-.416.223l.416.277zm-4.988.278l2.286 3.426.832-.555-2.286-3.426-.832.555zm3.118 3.426l2.286-3.426-.832-.555-2.286 3.426.832.555zm1.87-3.204h4.571v-1h-4.571v1zm4.571 0c.908 0 1.643-.734 1.643-1.642h-1a.641.641 0 01-.643.642v1zm1.643-1.642V5.142h-1v10.28h1zm0-10.279c0-.908-.735-1.642-1.643-1.642v1c.356 0 .643.287.643.642h1zM18.857 3.5H5.143v1h13.714v-1zm-13.714 0c-.908 0-1.643.734-1.643 1.642h1c0-.355.287-.642.643-.642v-1zM3.5 5.142v10.28h1V5.141h-1zm0 10.28c0 .907.735 1.641 1.643 1.641v-1a.641.641 0 01-.643-.642h-1zm1.643 1.641h4.571v-1H5.143v1zm6.286-5.706h1.142v-1H11.43v1zm-3.429 0h1.143v-1H8v1zm6.857 0H16v-1h-1.143v1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChatTyping;
