import * as React from 'react';

function SvgSetting(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.64062 18V15.5107C5.66907 15.1973 4.79248 14.6725 4.06505 13.9905L1.98937 15.1889L0.105958 11.9268L2.13049 10.7579C1.9817 10.1969 1.90241 9.60755 1.90241 8.99971C1.90241 8.39863 1.97994 7.81567 2.12555 7.26026L0 6.03307L1.75791 2.98828L3.86465 4.20461C4.62912 3.42704 5.57808 2.83143 6.64062 2.4887V0H10.6406V2.42654C11.7619 2.74977 12.7649 3.35215 13.5695 4.15359L15.3212 3.14221L17.2046 6.40438L15.4046 7.44362C15.5209 7.9435 15.5824 8.46443 15.5824 8.99971C15.5824 9.63268 15.4964 10.2456 15.3355 10.8273L17.3976 12.0179L15.6397 15.0627L13.5557 13.8595C12.7535 14.6541 11.7556 15.2515 10.6406 15.5729V18H6.64062ZM8.74244 12.2398C10.5318 12.2398 11.9824 10.7892 11.9824 8.99977C11.9824 7.21036 10.5318 5.75977 8.74244 5.75977C6.95304 5.75977 5.50244 7.21036 5.50244 8.99977C5.50244 10.7892 6.95304 12.2398 8.74244 12.2398Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSetting;
