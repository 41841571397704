import { useCallback, useState } from 'react';

interface UseModalReturnType {
  opened: boolean;
  openModal: VoidFunction;
  closeModal: VoidFunction;
  toggleModal: VoidFunction;
}
const useModal = (): UseModalReturnType => {
  const [opened, setOpened] = useState(false);
  const openModal = useCallback(() => {
    setOpened(true);
  }, []);
  const closeModal = useCallback(() => {
    setOpened(false);
  }, []);
  const toggleModal = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  return {
    opened,
    openModal,
    closeModal,
    toggleModal,
  };
};

export default useModal;
