import { create } from 'zustand';
import { orderService, stripeService } from '../services';
import { OrderStatus, PaymentMethodType, TossKeyType } from '../submodules/sicpama-shared';
export interface ConsentInfo {
  isMarketingAgreed?: boolean;
  isTermsAgreed?: boolean;
  phoneNumber?: string;
}

interface PaymentProps {
  stripePublicKey: string;
  stripeSecretClient: string;
  stripeAccountId: string;
  isOnClickStripeExpressElement: boolean;
  paymentMethod: PaymentMethodType;
  phoneNumberInputModalOpened: boolean;
  privacyPolicyModalOpened: boolean;
  thirdPartiesConsentModalOpened: boolean;
  refundPolicyModalOpened: boolean;
  termOfServiceModalOpened: boolean;
  customerConsentInfo: ConsentInfo;
  isFocusOnInputPhoneNumber: boolean;
  tossApiKeyType: TossKeyType;
  setStripePublicKey: () => Promise<void>;
  setStripeSecretClient: (amount: number) => Promise<void>;
  setIsOnClickStripeExpressElement: (isOnClickStripeExpressElement: boolean) => void;
  startPaying: (orderId: string, consentInfo?: ConsentInfo) => Promise<void>;
  startPayingWithErrorHandling: (orderId: string, consentInfo?: ConsentInfo) => Promise<void>;
  setPaymentMethod: (paymentMethod: PaymentMethodType) => void;
  setPhoneNumberInputModalOpened: (phoneNumberInputModalOpened: boolean) => void;
  setPrivacyPolicyModalOpened: (privacyPolicyModalOpened: boolean) => void;
  setThirdPartiesConsentModalOpened: (thirdPartiesConsentModalOpened: boolean) => void;
  setRefundPolicyModalOpened: (refundPolicyModalOpened: boolean) => void;
  setTermOfServiceModalOpened: (termOfServiceModalOpened: boolean) => void;
  setCustomerConsentInfo: (customerConsentInfo: ConsentInfo) => void;
  setIsFocusOnInputPhoneNumber: (isFocusOnInputPhoneNumber: boolean) => void;
  setTossApiKeyType: (tossApiKeyType: TossKeyType) => void;
}

export const usePaymentStore = create<PaymentProps>((set) => ({
  stripePublicKey: '',
  stripeSecretClient: '',
  stripeAccountId: '',
  isOnClickStripeExpressElement: false,
  paymentMethod: PaymentMethodType.NOT_SELECTED,
  phoneNumberInputModalOpened: false,
  privacyPolicyModalOpened: false,
  thirdPartiesConsentModalOpened: false,
  refundPolicyModalOpened: false,
  termOfServiceModalOpened: false,
  customerConsentInfo: {},
  isFocusOnInputPhoneNumber: false,
  tossApiKeyType: TossKeyType.WIDGET,
  setStripePublicKey: async () => {
    const result = await stripeService.getPaymentConfig();
    set({ stripePublicKey: result.publicKey, stripeAccountId: result.accountId });
  },
  setStripeSecretClient: async (amount: number) => {
    const result = await stripeService.createPaymentIntent({ amount });
    set({ stripeSecretClient: result.clientSecret });
  },
  setIsOnClickStripeExpressElement: (isOnClickStripeExpressElement: boolean) => {
    set({ isOnClickStripeExpressElement });
  },
  startPaying: async (orderId: string, consentInfo?: ConsentInfo) => {
    console.info('consentInfo: ', {
      ...consentInfo,
      orderId,
      phoneNumber: consentInfo?.phoneNumber ? '#####' : '',
    });
    await orderService.setOrderStatus(orderId, OrderStatus.PAYING, consentInfo);
  },
  startPayingWithErrorHandling: async (orderId: string, consentInfo?: ConsentInfo) => {
    await usePaymentStore.getState().startPaying(orderId, consentInfo);
  },
  setPaymentMethod: (paymentMethod: PaymentMethodType) => {
    set({ paymentMethod });
  },
  setPhoneNumberInputModalOpened: (phoneNumberInputModalOpened: boolean) => {
    set({ phoneNumberInputModalOpened });
  },
  setPrivacyPolicyModalOpened: (privacyPolicyModalOpened: boolean) => {
    set({ privacyPolicyModalOpened });
  },
  setThirdPartiesConsentModalOpened: (thirdPartiesConsentModalOpened: boolean) => {
    set({ thirdPartiesConsentModalOpened });
  },
  setRefundPolicyModalOpened: (refundPolicyModalOpened: boolean) => {
    set({ refundPolicyModalOpened });
  },
  setTermOfServiceModalOpened: (termOfServiceModalOpened: boolean) => {
    set({ termOfServiceModalOpened });
  },
  setCustomerConsentInfo: (customerConsentInfo: ConsentInfo) => {
    set({ customerConsentInfo });
  },
  setIsFocusOnInputPhoneNumber: (isFocusOnInputPhoneNumber: boolean) => {
    set({ isFocusOnInputPhoneNumber });
  },
  setTossApiKeyType: (type: TossKeyType) => {
    set({ tossApiKeyType: type });
  },
}));
