/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { create } from 'zustand';

import { storeService } from '../services/store.service';
import {
  ADVERT_LOCATION,
  PaymentGatewayInfo,
  PaymentGatewayType,
} from '../submodules/sicpama-shared';
import { StoreResDto } from '../types';
import { bulkCryptoPGConfiguration, decrypt } from '../utils/payment';

interface ThemeStoreProps {
  theme: {
    color: string;
  };
  store: StoreResDto;
  getStoreDetails: () => Promise<void>;
  getStoreById: (storeId: number) => Promise<void>;
  getPGInformation: (store: StoreResDto) => PaymentGatewayInfo;
}

const NO_STORE_SET = -1;

export const useThemeStore = create<ThemeStoreProps>((set, get) => ({
  store: {
    id: NO_STORE_SET,
    countryCode: '',
    currency: '',
    name: '',
    displayName: '',
    registrationNumber: null,
    color: null,
    address: null,
    phoneNumber: null,
    openTime: null,
    closeTime: null,
    attributes: [],
    coupon: [],
    paymentGatewayInfo: {} as PaymentGatewayInfo,
  },
  theme: {
    color: '#EB6120',
  },
  getStoreDetails: async () => {
    const store = await storeService.getStoreData();
    let pgInfo = {} as PaymentGatewayInfo;
    try {
      pgInfo = get().getPGInformation(store);
    } catch (error) {
      console.error('Error getting PG information', error);
    }
    set({
      store: { ...store, paymentGatewayInfo: pgInfo },
      theme: { color: store?.color || '#EB6120' },
    });
  },
  getStoreById: async (storeId: number) => {
    const store = await storeService.getStoreById(storeId);
    const pgInfo = get().getPGInformation(store);
    set({
      store: { ...store, paymentGatewayInfo: pgInfo },
      theme: { color: store?.color || '#EB6120' },
    });
  },
  getPGInformation: (store: StoreResDto) => {
    for (const paymentGateway in store.paymentGatewayInfo) {
      const pgInfo = store.paymentGatewayInfo[paymentGateway as PaymentGatewayType];
      store.paymentGatewayInfo[paymentGateway as PaymentGatewayType] = bulkCryptoPGConfiguration({
        pgConfiguration: {
          ...pgInfo,
          merchantId: pgInfo.merchantId,
          billingKey: pgInfo.billingKey,
          secretKey: pgInfo.secretKey,
          publicKey: pgInfo.publicKey,
          authTail: pgInfo.authTail,
          webhookKey: pgInfo.webhookKey,
          widgetClientKey: pgInfo.widgetClientKey,
          tossWidgetVariantKey: pgInfo.tossWidgetVariantKey,
          tossAgreementVariantKey: pgInfo.tossAgreementVariantKey,
        },
        cryptoAction: (text) => {
          return decrypt({
            encryptedText: text,
            secretKey: process.env.REACT_APP_SECRET_KEY ?? '',
            iv: process.env.REACT_APP_SECRET_IV ?? '',
          });
        },
      });
    }

    return store.paymentGatewayInfo;
  },
}));

const getCoupon = () => (store: ThemeStoreProps) => store.store.coupon;
const getCouponByAdvertLocation = (location: ADVERT_LOCATION) => (store: ThemeStoreProps) =>
  store.store.coupon?.filter((coupon) => coupon.advert?.location[location] != null) ?? [];
export const storeThemeSelectors = {
  getCoupon,
  getCouponByAdvertLocation,
};
