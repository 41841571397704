import * as React from "react";

function SvgBag(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.896 10.73l-.497-.054.497.055zm-.889 8l.497.056-.497-.055zm13.432 0l-.497.056.497-.055zm-.889-8l.497-.054-.497.055zM5.4 10.677l-.89 8 .994.11.889-8-.994-.11zm.743 9.824h11.16v-1H6.143v1zm12.793-1.824l-.889-8-.993.11.889 8 .993-.11zm-2.521-9.462H7.032v1h9.383v-1zm1.633 1.462a1.643 1.643 0 00-1.633-1.462v1c.327 0 .602.247.639.572l.994-.11zm-.744 9.824c.979 0 1.74-.851 1.632-1.824l-.994.11a.643.643 0 01-.639.714v1zM4.51 18.676A1.643 1.643 0 006.143 20.5v-1a.643.643 0 01-.639-.714l-.994-.11zm1.883-7.89a.643.643 0 01.639-.572v-1c-.837 0-1.54.63-1.633 1.462l.994.11zM8.795 8v-.571h-1V8h1zm5.857-.571V8h1v-.571h-1zM11.723 4.5a2.929 2.929 0 012.929 2.929h1A3.929 3.929 0 0011.723 3.5v1zM8.795 7.429A2.929 2.929 0 0111.723 4.5v-1a3.929 3.929 0 00-3.928 3.929h1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBag;
