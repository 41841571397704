import { ReactElement } from 'react';

export const OrderHistoryModalSkeletonLoader = (): ReactElement => {
  return (
    <div className="bg-white min-h-screen pb-16">
      {/* Accordion */}
      <div className="p-4 border-b">
        {/* Accordion Header */}
        <div className="flex justify-between items-center mb-4">
          <div className="w-20 h-6 bg-gray-200 rounded"></div>
          <div className="w-24 h-6 bg-gray-200 rounded"></div>
        </div>

        {/* Accordion Content */}
        <div className="space-y-4">
          {/* Order Number */}
          <div className="flex justify-between items-center">
            <div className="w-24 h-4 bg-gray-200 rounded"></div>
            <div className="w-28 h-4 bg-gray-200 rounded"></div>
          </div>

          {/* Menu Items */}
          {[1, 2].map((item) => (
            <div key={item} className="space-y-2">
              <div className="flex justify-between items-center">
                <div className="w-32 h-4 bg-gray-200 rounded"></div>
                <div className="w-8 h-4 bg-gray-200 rounded"></div>
              </div>
              <div className="flex items-center">
                <div className="w-16 h-16 bg-gray-200 rounded-lg mr-4"></div>
                <div className="flex-grow">
                  <div className="w-16 h-4 bg-gray-200 rounded mb-2"></div>
                  <div className="w-12 h-4 bg-gray-200 rounded"></div>
                </div>
                <div className="w-16 h-4 bg-gray-200 rounded"></div>
              </div>
            </div>
          ))}

          {/* Total Calculation */}
          {['Subtotal', 'Service Charge', 'GST', 'Grand Total'].map((item, index) => (
            <div key={item} className="flex justify-between items-center">
              <div className={`w-${index === 3 ? '32' : '24'} h-4 bg-gray-200 rounded`}></div>
              <div
                className={`w-16 h-4 bg-gray-200 rounded ${index === 3 ? 'font-bold' : ''}`}
              ></div>
            </div>
          ))}
        </div>
      </div>

      {/* Payment Options */}
      <div className="p-4">
        <div className="w-64 h-4 bg-gray-200 rounded mb-4"></div>
        <div className="w-full h-10 bg-gray-200 rounded"></div>
      </div>

      {/* Fixed Bottom Button */}
      <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t">
        <div className="w-full h-12 bg-gray-200 rounded"></div>
      </div>
    </div>
  );
};
