import { SNSProviderType } from 'submodules/sicpama-shared';

interface ProviderLoginButtonProps {
  provider: SNSProviderType;
  iconSrc: string;
  buttonText: string;
  color: string;
  customStyle: any;
}

export const snsButtonConfigs = (
  t: any,
  translationPrefix: string,
  isCookiesEnabled: boolean,
  dbProviderConfigs: SNSProviderType[] = [],
): ProviderLoginButtonProps[] => {
  const allConfigs: ProviderLoginButtonProps[] = [
    {
      provider: SNSProviderType.KAKAO,
      iconSrc: '/icons/kakao-icon.svg',
      buttonText: t(`${translationPrefix}.buttons.kakao`),
      color: isCookiesEnabled ? 'bg-[#FEE500]' : 'bg-[#bcbcbc]',
      // color: isCookiesEnabled ? 'bg-white' : 'bg-[#bcbcbc]',
      customStyle: { borderRadius: '100px', border: '1px solid #D8D8D8' },
    },
    {
      provider: SNSProviderType.GOOGLE,
      iconSrc: '/icons/google-icon.svg',
      buttonText: t(`${translationPrefix}.buttons.google`),
      color: isCookiesEnabled ? 'bg-white' : 'bg-[#bcbcbc]',
      customStyle: { borderRadius: '100px', border: '1px solid #D8D8D8' },
    },
    {
      provider: SNSProviderType.FACEBOOK,
      iconSrc: '/icons/facebook-icon.svg',
      buttonText: t(`${translationPrefix}.buttons.facebook`),
      color: isCookiesEnabled ? 'bg-[#1877F2]' : 'bg-[#bcbcbc]',
      // color: isCookiesEnabled ? 'bg-white' : 'bg-[#bcbcbc]',
      customStyle: { borderRadius: '100px', border: '1px solid #D8D8D8' },
    },
    {
      provider: SNSProviderType.TIKTOK,
      iconSrc: '/icons/tiktok.svg',
      buttonText: t(`${translationPrefix}.buttons.tiktok`),
      color: isCookiesEnabled ? 'bg-black' : 'bg-[#bcbcbc]',
      // color: isCookiesEnabled ? 'bg-white' : 'bg-[#bcbcbc]',
      customStyle: { borderRadius: '100px', border: '1px solid #D8D8D8' },
    },
    // {
    //   provider: SNSProviderType.THREADS,
    //   iconSrc: '/icons/threads.svg',
    //   buttonText: t(`${translationPrefix}.buttons.threads`),
    //   color: isCookiesEnabled ? 'bg-white' : 'bg-[#bcbcbc]',
    //   customStyle: { borderRadius: '100px', border: '1px solid #D8D8D8' },
    // },
  ];

  return dbProviderConfigs.length
    ? allConfigs.filter((config) => dbProviderConfigs.includes(config.provider))
    : allConfigs;
};
