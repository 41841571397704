import { create } from 'zustand';

interface AuthState {
  tableToken: string | null;
  isLoading: boolean;
  isNavigating: boolean;
  setLoading: (loading: boolean) => void;
  setTableToken: (token: string | null) => void;
  setIsNavigating: (isNavigating: boolean) => void;
}

export const useAuthState = create<AuthState>((set) => ({
  tableToken: null,
  isLoading: false,
  isNavigating: false,
  setTableToken: (tableToken) => {
    set({ tableToken });
  },
  setLoading: (isLoading) => {
    set({ isLoading });
  },
  setIsNavigating: (isNavigating) => {
    set({ isNavigating });
  },
}));
