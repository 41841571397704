import * as React from "react";

function SvgMicrophoneOn(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 13.1667V5.83333C10 5.22222 10.4 4 12 4C13.6 4 14 5.22222 14 5.83333V13.1667C14 13.7778 13.6 15 12 15C10.4 15 10 13.7778 10 13.1667Z" stroke="#444C54" />
      <path d="M12 17V21" stroke="#444C54" />
      <path d="M8 11V13.1818C8 14.4545 8.8 17 12 17C15.2 17 16 14.4545 16 13.1818V11" stroke="#444C54" />
    </svg>
  );
}

export default SvgMicrophoneOn;
