import { Icon, Input } from '@sicpama/core-components';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowProgressBar from '../arrow-progress-bar';

import './styles.scss';
import { useThemeStore } from '../../stores';
import { useTranslation } from 'react-i18next';

export const PublicContainer = ({
  children,
  containerRef,
  showSearch = false,
  showProgressBar = true,
  customClassName = 'public-container-wrapper',
}: any): JSX.Element => {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    theme: { color: themeColor },
  } = useThemeStore();

  return (
    <div
      className={customClassName}
      ref={(el) => {
        if (containerRef) {
          containerRef.current = el;
        }
      }}
    >
      <div className="flex gap-4 items-center px-4 py-2 w-full bg-white h-[56px] z-10 sticky top-0">
        <div className="px-4 py-2 shadow rounded-3xl">
          <Icon name="Logout" color={themeColor} />
        </div>
        <div
          className="ml-auto"
          onClick={() => {
            navigate(`/stores/${storeId}/search`);
          }}
        >
          <Input
            iconRight={<Icon name="Search" color="black" />}
            placeholder={t('pages.search.placeholder')!}
            borderRadius="24px"
          />
        </div>
      </div>
      {showProgressBar && <ArrowProgressBar offsetTop={showSearch ? 56 : 0} />}
      {children}
    </div>
  );
};
