var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TextInput as MantineTextInput } from '@mantine/core';
import React, { forwardRef } from 'react';
import Icon from '../Icon/Icon';
var TextInput = function (_a, ref) {
    var value = _a.value, defaultValue = _a.defaultValue, disabled = _a.disabled, iconLeft = _a.iconLeft, iconRight = _a.iconRight, label = _a.label, placeholder = _a.placeholder, name = _a.name, required = _a.required, size = _a.size, style = _a.style, styles = _a.styles, sx = _a.sx, borderRadius = _a.borderRadius, maxLength = _a.maxLength, minLength = _a.minLength, autoFocus = _a.autoFocus, _b = _a.onChange, onChange = _b === void 0 ? function () { return true; } : _b, _c = _a.onBlur, onBlur = _c === void 0 ? function () { return true; } : _c, _d = _a.onFocus, onFocus = _d === void 0 ? function () { return true; } : _d, type = _a.type, rest = __rest(_a, ["value", "defaultValue", "disabled", "iconLeft", "iconRight", "label", "placeholder", "name", "required", "size", "style", "styles", "sx", "borderRadius", "maxLength", "minLength", "autoFocus", "onChange", "onBlur", "onFocus", "type"]);
    return (React.createElement(MantineTextInput, __assign({ type: type, value: value, defaultValue: defaultValue, placeholder: placeholder, label: label, required: required, disabled: disabled, icon: iconLeft, rightSection: React.createElement("div", { style: { display: 'flex', alignItems: 'center', gap: 4 } },
            value ? (React.createElement(Icon, { name: "XCircle", type: "outline", onClick: function () { return onChange(''); }, style: { cursor: 'pointer' }, size: 16 })) : null,
            iconRight), rightSectionWidth: value ? (iconRight ? 80 : 50) : iconRight ? 50 : 0, size: size, radius: borderRadius, onChange: onChange, onBlur: onBlur, onFocus: onFocus, maxLength: maxLength, minLength: minLength, ref: ref, autoFocus: autoFocus, name: name }, rest)));
};
export default forwardRef(TextInput);
