import './square.scss';

export const SquareLoader = (): JSX.Element => {
  return (
    <div className="square-loader flex flex-col items-center justify-center absolute inset-0 m-auto">
      <div className="loader-square"></div>
      <div className="loader-square"></div>
      <div className="loader-square"></div>
      <div className="loader-square"></div>
      <div className="loader-square"></div>
      <div className="loader-square"></div>
      <div className="loader-square"></div>
    </div>
  );
};
