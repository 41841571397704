var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from 'react';
import { Avatar as MantineAvatar, Menu as MantineMenu } from '@mantine/core';
var Avatar = function (_a) {
    var items = _a.items, spacing = _a.spacing, _b = _a.size, size = _b === void 0 ? 'lg' : _b, _c = _a.threshold, threshold = _c === void 0 ? items.length : _c, dropdownWidth = _a.dropdownWidth, dropdown = _a.dropdown, openedDropdown = _a.openedDropdown, onDropdownChange = _a.onDropdownChange;
    var remainingCount = useMemo(function () { return items.length - threshold; }, [items, threshold]);
    var slicedItems = useMemo(function () { return items; }, [items]);
    if (remainingCount > 0) {
        slicedItems = items.slice(0, -remainingCount);
    }
    return (React.createElement(MantineMenu, { shadow: "xl", width: dropdownWidth, position: "bottom-start", opened: openedDropdown, onChange: onDropdownChange },
        React.createElement(MantineMenu.Target, null,
            React.createElement(MantineAvatar.Group, { spacing: spacing },
                slicedItems.map(function (item, index) {
                    var alt = item.alt, src = item.src, description = item.description, _a = item.borderRadius, borderRadius = _a === void 0 ? 'xl' : _a, imageProps = item.imageProps, descriptionColor = item.descriptionColor, borderColor = item.borderColor, rest = __rest(item, ["alt", "src", "description", "borderRadius", "imageProps", "descriptionColor", "borderColor"]);
                    return (React.createElement(MantineAvatar, __assign({ key: index, alt: alt, src: src, size: size, radius: borderRadius, imageProps: imageProps, styles: function (theme) { return ({
                            root: {
                                borderColor: borderColor,
                            },
                            placeholder: {
                                color: descriptionColor,
                            },
                        }); } }, rest), description));
                }),
                remainingCount > 0 && (React.createElement(MantineAvatar, { size: "sm", radius: "xl", styles: function (theme) { return ({
                        placeholder: {
                            color: 'grey',
                            backgroundColor: 'transparent',
                        },
                    }); } },
                    "+",
                    remainingCount)))),
        React.createElement(MantineMenu.Dropdown, null, dropdown)));
};
export default React.memo(Avatar);
