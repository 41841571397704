import * as React from "react";

function SvgMessenger(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.783 17.445l.482.131a.5.5 0 00-.164-.517l-.318.386zM6.087 20l-.482-.131a.5.5 0 00.694.584L6.087 20zm2.783-1.304l.188-.464a.5.5 0 00-.4.01l.212.454zm1.987-7.839l.3-.4-.293-.22-.298.213.291.407zm2.286 1.714l-.3.4.316.238.304-.253-.32-.385zM12 3.5c-4.674 0-8.5 3.63-8.5 8.152h1C4.5 7.722 7.836 4.5 12 4.5v-1zm-8.5 8.152c0 2.478 1.158 4.688 2.964 6.179l.637-.772C5.506 15.743 4.5 13.81 4.5 11.652h-1zm2.8 5.662l-.695 2.555.964.262.696-2.555-.965-.262zm0 3.139l2.782-1.305-.425-.905-2.782 1.304.424.906zm2.381-1.294a8.801 8.801 0 003.319.645v-1a7.798 7.798 0 01-2.942-.572l-.377.927zm3.319.645c4.674 0 8.5-3.629 8.5-8.152h-1c0 3.93-3.336 7.152-7.5 7.152v1zm8.5-8.152C20.5 7.13 16.674 3.5 12 3.5v1c4.164 0 7.5 3.223 7.5 7.152h1zm-13.352 2.47l4-2.858-.582-.814-4 2.857.582.814zm3.41-2.865l2.285 1.714.6-.8-2.286-1.714-.6.8zm2.905 1.699l3.429-2.858-.64-.768-3.43 2.857.64.769z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMessenger;
