export const randomId = (length = 6): string => {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};

export const maskingString = (str: string, start: number, end: number): string => {
  if (!str || start < 0 || start >= str.length || end < 0 || end > str.length || start >= end) {
    return str;
  }

  const maskLength = end - start;
  const maskedStr = str.substring(0, start) + '*'.repeat(maskLength) + str.substring(end);
  return maskedStr;
};

export const checkPhoneNumberIsValid = (phoneNumber: string | null | undefined): boolean => {
  if (!phoneNumber) {
    return false;
  }

  // TODO: just only for KR phone number checking, need to enhance for other countries later
  if (phoneNumber.length < 10) {
    return false;
  }

  return true;
};

export const getOnlyNumberPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/[^0-9]/g, '');
};
