import { Avatar } from '@sicpama/core-components';
import clsx from 'clsx';
import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';
import type { ReactElement } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useOrderStore } from '../../stores';

import './styles.scss';
import { OrderStatus } from '../../submodules/sicpama-shared';

const ArrowProgressBar = ({ offsetTop }: { offsetTop?: number }): ReactElement => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { tableOrders } = useOrderStore();
  const customersByTypes = useMemo(() => {
    const draftOrders = tableOrders.filter((o) => o.status === OrderStatus.DRAFT);
    const waitingOrders = tableOrders.filter(
      (o) => o.status === OrderStatus.WAITING || o.status === OrderStatus.PAYING,
    );
    const paidOrders = tableOrders.filter(
      (o) => o.status === OrderStatus.PAID || o.status === OrderStatus.PRINTED,
    );
    return {
      draftCustomers: draftOrders.map((o) => o.customer),
      waitingCustomers: waitingOrders.map((o) => o.customer),
      paidCustomers: paidOrders.map((o) => o.customer),
    };
  }, [tableOrders]);

  return (
    <div
      className="arrow-progress-bar-wrapper"
      style={{ top: offsetTop }}
      id={ANALYTICS_CSS_ID().PROGRESS_BAR}
    >
      <div className="arrow-progress-bar-title">
        <div
          className={clsx(
            'arrow-progress-bar-item !inline-flex items-center gap-1',
            (pathname === '/menus' || pathname === '/tab') && 'selected',
          )}
        >
          <span className="text-xs truncate">{t('progressBar.addMenu')}</span>
          <span className="bg-white rounded-full inline-flex items-center justify-center w-5 h-5 ml-auto mr-1 shrink-0">
            {customersByTypes.draftCustomers.length}
          </span>
        </div>
        <div
          className={clsx(
            'arrow-progress-bar-item !inline-flex items-center gap-1',
            pathname === '/payment' && 'selected',
          )}
        >
          <span className="text-xs truncate">{t('progressBar.payment')}</span>
          <span className="bg-white rounded-full inline-flex items-center justify-center w-5 h-5 ml-auto mr-1 shrink-0">
            {customersByTypes.waitingCustomers.length}
          </span>
        </div>
        <div
          className={clsx(
            'arrow-progress-bar-item !inline-flex items-center gap-1',
            pathname === '/customer-receipt' && 'selected',
          )}
        >
          <span className="text-xs truncate">{t('progressBar.complete')}</span>
          <span className="bg-white rounded-full inline-flex items-center justify-center w-5 h-5 ml-auto mr-4 shrink-0">
            {customersByTypes.paidCustomers.length}
          </span>
        </div>
      </div>
      <div className="arrow-progress-bar-content">
        <div className="arrow-progress-bar-avatar">
          <div className="h-[38px]" id={ANALYTICS_CSS_ID().AVATAR_INSIDE_PROGRESS_BAR}>
            <Avatar
              items={customersByTypes.draftCustomers.map((c) => ({
                alt: 'Avatar for badge',
                src: c.providerProfilePhoto || '/images/blank-avatar.jpg',
                borderColor: 'grey',
                className: 'bounce-up',
              }))}
              size="md"
              threshold={4}
            />
          </div>
          <div
            className={clsx(
              'h-0.5 mt-0.5 w-full',
              pathname === '/menus' || pathname === '/tab' ? 'bg-theme' : 'bg-transparent',
            )}
          ></div>
        </div>
        <div className="arrow-progress-bar-avatar">
          <div className="h-[38px]" id={ANALYTICS_CSS_ID().AVATAR_INSIDE_PROGRESS_BAR}>
            <Avatar
              size="md"
              threshold={4}
              items={customersByTypes.waitingCustomers.map((c) => ({
                alt: 'Avatar for badge',
                src: c.providerProfilePhoto || '/images/blank-avatar.jpg',
                borderColor: 'grey',
              }))}
            />
          </div>
          <div
            className={clsx(
              'h-0.5 mt-0.5 w-full',
              pathname === '/payment' ? 'bg-theme' : 'bg-transparent',
            )}
          ></div>
        </div>
        <div className="arrow-progress-bar-avatar">
          <div className="h-[38px]" id={ANALYTICS_CSS_ID().AVATAR_INSIDE_PROGRESS_BAR}>
            <Avatar
              size="md"
              threshold={4}
              items={customersByTypes.paidCustomers.map((c) => ({
                alt: 'Avatar for badge',
                src: c.providerProfilePhoto || '/images/blank-avatar.jpg',
                borderColor: 'grey',
              }))}
            />
          </div>
          <div
            className={clsx(
              'h-0.5 mt-0.5 w-full',
              pathname === '/customer-receipt' ? 'bg-theme' : 'bg-transparent',
            )}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ArrowProgressBar;
