import * as React from "react";

function SvgUserCircle(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.929 18.286v.5h1v-.5h-1zm9.142 0v.5h1v-.5h-1zm-8.142 0v-.572h-1v.572h1zm2.928-3.5h2.286v-1h-2.286v1zm5.214 2.928v.572h1v-.572h-1zm-2.928-2.928a2.928 2.928 0 012.928 2.928h1a3.928 3.928 0 00-3.928-3.928v1zm-5.214 2.928a2.928 2.928 0 012.928-2.928v-1a3.928 3.928 0 00-3.928 3.928h1zM12 6.93a2.786 2.786 0 00-2.786 2.785h1c0-.986.8-1.785 1.786-1.785v-1zm2.786 2.785A2.786 2.786 0 0012 6.93v1c.986 0 1.786.8 1.786 1.785h1zM12 12.5a2.786 2.786 0 002.786-2.786h-1c0 .986-.8 1.786-1.786 1.786v1zm0-1c-.986 0-1.786-.8-1.786-1.786h-1A2.786 2.786 0 0012 12.5v-1zm0 8A7.5 7.5 0 014.5 12h-1a8.5 8.5 0 008.5 8.5v-1zm7.5-7.5a7.5 7.5 0 01-7.5 7.5v1a8.5 8.5 0 008.5-8.5h-1zM12 4.5a7.5 7.5 0 017.5 7.5h1A8.5 8.5 0 0012 3.5v1zm0-1A8.5 8.5 0 003.5 12h1A7.5 7.5 0 0112 4.5v-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUserCircle;
