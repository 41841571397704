import * as React from "react";

function SvgTwitter(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.976 5.498a.533.533 0 00-.929-.321 2.73 2.73 0 01-1.307.814A3.67 3.67 0 0015.227 5a3.775 3.775 0 00-3.772 3.777v.47C8.265 9 6.558 6.575 6.098 5.346a.533.533 0 00-.977-.052c-1.116 2.237-1.288 4.406-.477 6.253.711 1.618 2.14 2.907 4.167 3.76-.921.653-2.471 1.425-4.278 1.425a.533.533 0 00-.296.977c1.733 1.157 3.548 1.157 5.237 1.157h.052c5.184 0 9.386-4.21 9.386-9.4v-.51c.388-.395.69-.798.874-1.32.207-.581.248-1.256.19-2.139z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTwitter;
