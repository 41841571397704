export const STORE_ATTRIBUTE_NAME = {
  PRICE_INCLUDED_VAT: 'priceIncludedVat',
  SERVICE_CHARGE_PERCENT: 'serviceChargePercent',
  BANNER_IMAGE: 'bannerImage',
  DISCLAIMER_CONTENT: 'disclaimerContent',
  PAYMENT_TYPE: 'paymentType',
  PAYMENT_METHOD: 'paymentMethod',
  POST_PAID_PERIOD: 'postPaidGracePeriod',
  ORDER_INSTRUCTION: 'orderInstruction',
  CANCELLATION_FEE: 'cancellationFee',
  REFUNDABLE: 'refundable',
  REFUNDABLE_PERIOD: 'refundablePeriod',
  NON_CANCELLABLE: 'nonCancellable',
  NON_CANCELLABLE_PERIOD: 'nonCancellablePeriod',
  SICPAMA_COMMISSION_RATE: 'sicpamaCommissionRate',
  TIMEZONE: 'timezone',
  PRICE_NOTE: 'priceNote',
  TABLE_SORTING_CRITERIA: 'tableSortingCriteria',
  IS_DISCOUNTED_FOR_FIRST_LOGIN: 'isDiscountedForFirstLogin',
  PRINT_ONCE_PAID: 'printOncePaid',
  NOTIFICATION_REQUIRED: 'notificationRequired',
  PAYMENT_METHODS_ATTRIBUTE: 'paymentMethods',
  PAYMENT_OPTIONS: 'paymentOptions',
  PAYMENT_GATEWAY: 'paymentGateway',
  MENU_CAROUSEL_HIDDENNESS: 'menuCarouselHiddenness',
  MARKETING_POPUP_ENABLED: 'marketingPopupEnabled',
};
