export const NAVIGATOR_LANGUAGE_TO_APP_LANGUAGE = {
  ko: 'kr',
  'ko-KR': 'kr',
  en: 'en',
  'en-US': 'en',
  'en-GB': 'en',
  'vi-VN': 'en',
};

export const APP_LANGUAGE_TO_PAYMENT_LANGUAGE = {
  KICC: {
    [NAVIGATOR_LANGUAGE_TO_APP_LANGUAGE.ko]: 'KOR',
    [NAVIGATOR_LANGUAGE_TO_APP_LANGUAGE.en]: 'ENG',
  },
};
