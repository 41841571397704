import * as React from "react";

function SvgWhatsapp(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.6 16.8l.447.224.138-.277L6 16.5l-.4.3zm1.6 1.6l.3-.4-.247-.185-.277.138.224.447zM4 20l-.447-.224a.5.5 0 00.67.671L4 20zM8.571 8.571l-.277-.416a.5.5 0 00-.223.416h.5zm6.858 6.858v.5a.5.5 0 00.416-.223l-.416-.277zm-4.853-6.257l.493-.082-.493.082zm.16.956l-.494.082.494-.082zm-.494 1.139l.278.416-.278-.416zm5.349 3.918l-.416-.277.416.277zm-.763-1.761l.082-.493-.082.493zm-.956-.16l-.082.494.082-.494zm-1.139.494l-.416-.278.416.278zM3.5 12c0 1.913.633 3.68 1.7 5.1l.8-.6A7.465 7.465 0 014.5 12h-1zM12 3.5A8.5 8.5 0 003.5 12h1A7.5 7.5 0 0112 4.5v-1zm8.5 8.5A8.5 8.5 0 0012 3.5v1a7.5 7.5 0 017.5 7.5h1zM12 20.5a8.5 8.5 0 008.5-8.5h-1a7.5 7.5 0 01-7.5 7.5v1zm-5.1-1.7a8.465 8.465 0 005.1 1.7v-1A7.465 7.465 0 017.5 18l-.6.8zm-2.676 1.647l3.2-1.6-.448-.894-3.2 1.6.448.894zm.929-3.87l-1.6 3.2.894.447 1.6-3.2-.894-.447zM8.07 8.57v1.143h1V8.571h-1zm6.215 7.358h1.143v-1h-1.143v1zM8.07 9.714a6.214 6.214 0 006.215 6.215v-1A5.214 5.214 0 019.07 9.714h-1zm.778-.727l.243-.162-.554-.832-.244.162.555.832zm1.234.267l.16.956.986-.164-.16-.956-.986.164zm-.118 1.597l-1.1.733.555.832 1.1-.733-.555-.832zm.277-.64c.042.249-.067.5-.277.64l.555.832c.538-.358.815-1 .709-1.637l-.986.164zm-1.15-1.386a.643.643 0 01.991.43l.986-.165a1.643 1.643 0 00-2.531-1.097l.554.832zm6.753 6.88l.162-.243-.832-.554-.163.243.833.555zm-.935-2.774l-.956-.16-.164.986.956.16.164-.986zm-2.593.55l-.733 1.099.832.554.733-1.099-.832-.555zm1.637-.71a1.643 1.643 0 00-1.637.71l.832.554c.14-.21.391-.319.64-.277l.165-.987zm2.053 2.691a1.643 1.643 0 00-1.097-2.531l-.164.986c.46.077.688.603.429.99l.832.555z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgWhatsapp;
