import * as React from "react";

function SvgTravel(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.404 20.83l.004-.005.004-.007 3.873-6.476h4.43c1.007 0 1.785-.844 1.785-1.842 0-.998-.778-1.842-1.785-1.842h-4.43l-3.873-6.476h0l-.002-.003c-.249-.41-.698-.679-1.19-.679-.961 0-1.603.957-1.336 1.859v.002l1.59 5.297H6.689L5.686 9.262a.906.906 0 00-.753-.394h-.505c-.646 0-1.048.632-.894 1.203v.002L4.2 12.5l-.666 2.427v.002c-.154.57.248 1.203.894 1.203h.505a.906.906 0 00.753-.394l1.003-1.396h3.785l-1.59 5.298v.001c-.267.902.375 1.859 1.336 1.859.499 0 .932-.274 1.184-.67z"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgTravel;
