import React, { useRef } from 'react';
import { createStyles, NumberInput, ActionIcon, rem } from '@mantine/core';
import { Icon } from '../..';
var useStyles = createStyles(function (theme, _a) {
    var width = _a.width, borderColor = _a.borderColor, borderRadius = _a.borderRadius, minusBackgroundColor = _a.minusBackgroundColor, plusBackgroundColor = _a.plusBackgroundColor, plusBorderColor = _a.plusBorderColor, minusBorderColor = _a.minusBorderColor, plusMinusDisabledBackgroundColor = _a.plusMinusDisabledBackgroundColor, height = _a.height;
    return ({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: "".concat(rem(6), " ").concat(theme.spacing.xs),
            borderRadius: borderRadius || theme.radius.sm,
            border: "".concat(rem(1), " solid ").concat(theme.colorScheme === 'dark' ? 'transparent' : borderColor),
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
            width: width,
            height: height,
            '&:focus-within': {
                borderColor: theme.colors[theme.primaryColor][6],
            },
        },
        plus: {
            // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            backgroundColor: "".concat(plusBackgroundColor, " !important"),
            color: plusBackgroundColor,
            border: "".concat(rem(1), " solid ").concat(theme.colorScheme === 'dark' ? 'transparent' : plusBorderColor),
            '&:disabled': {
                borderColor: theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3],
                opacity: 0.8,
                backgroundColor: plusMinusDisabledBackgroundColor,
            },
        },
        minus: {
            // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            backgroundColor: "".concat(minusBackgroundColor, " !important"),
            color: minusBackgroundColor,
            border: "".concat(rem(1), " solid ").concat(theme.colorScheme === 'dark' ? 'transparent' : minusBorderColor),
            '&:disabled': {
                borderColor: theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3],
                opacity: 0.8,
                backgroundColor: plusMinusDisabledBackgroundColor,
            },
        },
        input: {
            textAlign: 'center',
            paddingRight: "".concat(theme.spacing.sm, " !important"),
            paddingLeft: "".concat(theme.spacing.sm, " !important"),
            height: rem(28),
            flex: 1,
        },
    });
});
export default function QuantitySelector(_a) {
    var label = _a.label, required = _a.required, disabled = _a.disabled, readOnly = _a.readOnly, quantitySize = _a.quantitySize, _b = _a.iconSize, iconSize = _b === void 0 ? 28 : _b, borderRadius = _a.borderRadius, style = _a.style, styles = _a.styles, sx = _a.sx, _c = _a.min, min = _c === void 0 ? 1 : _c, _d = _a.max, max = _d === void 0 ? 200 : _d, width = _a.width, _e = _a.height, height = _e === void 0 ? 28 : _e, minusColor = _a.minusColor, plusColor = _a.plusColor, minusBackgroundColor = _a.minusBackgroundColor, plusBackgroundColor = _a.plusBackgroundColor, plusBorderColor = _a.plusBorderColor, minusBorderColor = _a.minusBorderColor, _f = _a.value, value = _f === void 0 ? '' : _f, _g = _a.onChange, onChange = _g === void 0 ? function () { return true; } : _g, _h = _a.plusMinusDisabledBackgroundColor, plusMinusDisabledBackgroundColor = _h === void 0 ? '#CBCCCF' : _h, borderColor = _a.borderColor, plusBtnCssId = _a.plusBtnCssId, minusBtnCssId = _a.minusBtnCssId, quantityCssId = _a.quantityCssId;
    var classes = useStyles({
        width: width,
        borderColor: borderColor,
        borderRadius: borderRadius,
        plusBorderColor: plusBorderColor,
        minusBorderColor: minusBorderColor,
        minusBackgroundColor: minusBackgroundColor,
        plusBackgroundColor: plusBackgroundColor,
        plusMinusDisabledBackgroundColor: plusMinusDisabledBackgroundColor,
        height: height,
    }).classes;
    var handlers = useRef(null);
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement(ActionIcon, { size: iconSize, variant: "transparent", onClick: function () { var _a; return (_a = handlers.current) === null || _a === void 0 ? void 0 : _a.decrement(); }, disabled: value === min, className: classes.minus, onMouseDown: function (event) { return event.preventDefault(); }, id: minusBtnCssId },
            React.createElement(Icon, { name: "Minus", type: "outline", color: minusColor })),
        React.createElement(NumberInput, { variant: "unstyled", min: min, max: max, handlersRef: handlers, value: typeof value === 'number' ? value : undefined, onChange: onChange, classNames: { input: classes.input }, label: label, required: required, disabled: disabled, readOnly: readOnly, size: quantitySize, style: style, styles: styles, sx: sx, id: quantityCssId }),
        React.createElement(ActionIcon, { size: iconSize, variant: "transparent", onClick: function () { var _a; return (_a = handlers.current) === null || _a === void 0 ? void 0 : _a.increment(); }, disabled: value === max, className: classes.plus, onMouseDown: function (event) { return event.preventDefault(); }, id: plusBtnCssId },
            React.createElement(Icon, { name: "Plus", type: "outline", color: plusColor }))));
}
