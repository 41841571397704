import * as React from "react";

function SvgPen(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 4v-.5h-.5V4H6zm11.436 0h.5v-.5h-.5V4zM8.734 8.798a.5.5 0 00-.894-.447l.894.447zm-2.733 4.345l-.448-.224-.146.293.21.251.384-.32zM11.719 20l-.384.32a.5.5 0 00.768 0l-.384-.32zm5.718-6.857l.384.32.21-.251-.147-.293-.447.224zM15.596 8.35a.5.5 0 10-.894.447l.894-.447zm-3.378 5.935v-.5h-1v.5h1zm-.5-5.072A5.215 5.215 0 016.5 4h-1a6.215 6.215 0 006.218 6.214v-1zM6 4.5h11.436v-1H6v1zM16.936 4a5.215 5.215 0 01-5.218 5.214v1A6.215 6.215 0 0017.936 4h-1zM7.84 8.35L5.553 12.92l.895.448 2.286-4.569-.894-.447zm-2.223 5.113l5.718 6.857.768-.64-5.718-6.857-.768.64zm6.486 6.857l5.718-6.857-.768-.64-5.718 6.857.768.64zm5.78-7.401l-2.287-4.568-.894.447 2.287 4.569.895-.448zM12.22 20l-.001-5.714h-1V20h1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPen;
