var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { Card as MantineCard } from '@mantine/core';
var Card = function (_a, ref) {
    var _b = _a.borderRadius, borderRadius = _b === void 0 ? 'md' : _b, style = _a.style, styles = _a.styles, sx = _a.sx, _c = _a.backgroundColor, backgroundColor = _c === void 0 ? '#fff' : _c, _d = _a.borderColor, borderColor = _d === void 0 ? '#999999' : _d, _e = _a.withBorder, withBorder = _e === void 0 ? true : _e, _f = _a.boxShadow, boxShadow = _f === void 0 ? 'sm' : _f, height = _a.height, width = _a.width, children = _a.children, className = _a.className, rest = __rest(_a, ["borderRadius", "style", "styles", "sx", "backgroundColor", "borderColor", "withBorder", "boxShadow", "height", "width", "children", "className"]);
    return (React.createElement(MantineCard, __assign({ radius: borderRadius, style: __assign(__assign({}, style), { backgroundColor: backgroundColor, borderColor: borderColor, height: height, width: width }), shadow: boxShadow, withBorder: withBorder, styles: styles, sx: sx, ref: ref, className: className }, rest), children));
};
export default forwardRef(Card);
