import * as React from "react";

function SvgVideoOff(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6465 21.3536L0.646484 3.35359L1.35359 2.64648L19.3536 20.6465L18.6465 21.3536Z"
        fill="#444C54"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.929 15.2219V11.967L18.4166 14.4724C19.0782 14.9476 20 14.4748 20 13.6602V10.2319C20 9.41727 19.0782 8.94445 18.4166 9.41971L14.929 11.9251V8.46552C14.929 7.65614 14.2766 7 13.4718 7H6.70711L14.929 15.2219ZM5.30119 7.0083C4.56976 7.08659 4 7.70914 4 8.46552V15.7931C4 16.6025 4.65241 17.2587 5.45719 17.2587H13.4718C14.0333 17.2587 14.5207 16.9392 14.764 16.4711L5.30119 7.0083Z"
        fill="#444C54"
      />
    </svg>
  );
}

export default SvgVideoOff;
