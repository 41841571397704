import * as React from "react";

function SvgGlobeAmericas(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.571 9.714v-.5a.5.5 0 00-.5.5h.5zm0 1.143l.354.354.146-.147v-.207h-.5zm-1.95.335l-.354.353.353-.353zm4.236 7.094h.5v-.207l-.146-.147-.354.354zm-1.143-1.143h-.5v.207l.147.146.353-.353zm0-1.714h.5v-.207l-.146-.147-.354.354zm-1.143-1.143h-.5v.207l.147.146.353-.353zm0-1.143v-.5a.5.5 0 00-.5.5h.5zM13.786 4v2.286h1V4h-1zm-.643 2.929H12v1h1.143v-1zM9.714 9.214H8.571v1h1.143v-1zm-1.643.5v1.143h1V9.714h-1zm.147.79l-.335.334.707.707.335-.334-.707-.707zm-1.244.334L4.925 8.79l-.707.707 2.049 2.05.707-.708zm3.383-2.267a.643.643 0 01-.643.643v1c.908 0 1.643-.735 1.643-1.643h-1zM12 6.93c-.907 0-1.643.735-1.643 1.642h1c0-.355.288-.642.643-.642v-1zm-4.117 3.91a.643.643 0 01-.909 0l-.707.706a1.643 1.643 0 002.323 0l-.707-.707zm5.903-4.553a.643.643 0 01-.643.643v1c.907 0 1.643-.736 1.643-1.643h-1zM11.357 20v-1.714h-1V20h1zm-.146-2.068l-1.143-1.143-.707.707 1.143 1.143.707-.707zm-.997-.79V15.43h-1v1.714h1zm-.146-2.067l-1.143-1.143-.707.707 1.143 1.143.707-.707zm-.997-.79v-1.142h-1v1.143h1zm-.5-.642h4.572v-1H8.57v1zm5.215.643v.762h1v-.762h-1zm2.024 2.785h3.047v-1H15.81v1zm-.881-.88c0 .486.394.88.88.88v-1a.12.12 0 01.12.12h-1zm-.262-.262c.144 0 .262.117.262.261h1c0-.697-.565-1.261-1.262-1.261v1zm-.881-.881c0 .486.394.88.88.88v-1c.066 0 .12.054.12.12h-1zm-.643-1.405c.355 0 .643.288.643.643h1c0-.908-.736-1.643-1.643-1.643v1zM12 19.5A7.5 7.5 0 014.5 12h-1a8.5 8.5 0 008.5 8.5v-1zm7.5-7.5a7.5 7.5 0 01-7.5 7.5v1a8.5 8.5 0 008.5-8.5h-1zM12 4.5a7.5 7.5 0 017.5 7.5h1A8.5 8.5 0 0012 3.5v1zm0-1A8.5 8.5 0 003.5 12h1A7.5 7.5 0 0112 4.5v-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgGlobeAmericas;
