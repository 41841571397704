export var relationRegexp = /(-id|-ids|_id|_ids|Id|Ids|ID|IDs)(\[\])?$/;
export var removeRelationSuffix = function (key) {
    return key.replace(relationRegexp, "");
};
export var prettyString = function (str) {
    var clean = removeRelationSuffix(str);
    // Convert camelCase to camel Case
    var camelCase = clean.replace(/([a-z])([A-Z])/g, "$1 $2");
    // Convert snake_case to snake case
    var snakeCase = camelCase.replace(/_/g, " ");
    // Convert kebab-case to kebab case
    var kebabCase = snakeCase.replace(/-/g, " ");
    // Capitalize the string
    var capitalized = kebabCase
        .split(" ")
        .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
        .join(" ");
    return capitalized;
};
export default prettyString;
