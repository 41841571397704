import * as React from "react";

function SvgSnapchat(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.385 4c-1.422 0-2.677.507-3.563 1.577-.79.953-1.237 2.893-1.311 4.512l-.09.013-.027.004c-.132.019-.29.042-.453.072-.573.11-1.657.402-1.9 1.573-.114.547.015 1.024.305 1.395.26.33.612.528.871.651.111.052.224.1.337.146-.259.717-.712 1.456-1.214 2.125-.47.627-.346 1.42-.103 1.937.243.518.702.495 1.467.528.434.02.89.064 1.342.148.205.038.446.122.746.248.174.074.348.15.52.23l.461.205c.705.308 1.57.636 2.53.636.96 0 1.825-.328 2.53-.636.172-.075.32-.142.461-.206l.031-.014c.165-.075.32-.145.49-.215.3-.126.54-.209.745-.247.453-.084.908-.13 1.341-.149.766-.033 1.36-.01 1.604-.528.243-.517.367-1.31-.102-1.937-.5-.664-.951-1.398-1.21-2.11.11-.044.242-.096.374-.157.267-.121.638-.322.898-.668.303-.402.389-.91.216-1.458-.344-1.09-1.343-1.381-1.928-1.496a8.48 8.48 0 00-.438-.072l-.015-.003-.07-.01c-.079-1.519-.502-3.44-1.274-4.408C15.086 4.597 13.839 4 12.385 4z"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgSnapchat;
