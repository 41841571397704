/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Types } from 'ably';
import { create } from 'zustand';

interface RealtimeProps {
  channel: Types.RealtimeChannelCallbacks;
  setChannel: (channel: Types.RealtimeChannelCallbacks) => void;
}

export const useRealtimeStore = create<RealtimeProps>((set) => ({
  channel: {} as Types.RealtimeChannelCallbacks,
  setChannel: (channel) => {
    set({ channel });
  },
}));
