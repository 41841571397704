import { UserBasedRecommendMenuStructure } from '../../../submodules/sicpama-shared';

function getPreviousSystem(
  numberOfCustomer: number,
  recommendationSystemList: UserBasedRecommendMenuStructure[],
): UserBasedRecommendMenuStructure {
  if (numberOfCustomer <= 0) {
    return recommendationSystemList[0];
  }
  const foundSystem = recommendationSystemList.find(
    (system) => system.totalUserNumber === numberOfCustomer,
  );
  return foundSystem !== undefined
    ? foundSystem
    : getPreviousSystem(numberOfCustomer - 1, recommendationSystemList);
}

function getNextSystem(
  numberOfCustomer: number,
  recommendationSystemList: UserBasedRecommendMenuStructure[],
): UserBasedRecommendMenuStructure {
  if (numberOfCustomer >= 10) {
    return recommendationSystemList[recommendationSystemList.length - 1];
  }
  const foundSystem = recommendationSystemList.find(
    (system) => system.totalUserNumber === numberOfCustomer,
  );
  return foundSystem !== undefined
    ? foundSystem
    : getNextSystem(numberOfCustomer + 1, recommendationSystemList);
}

export function getRecommendedMenuIds(
  numberOfCustomer: number,
  recommendationSystemList: UserBasedRecommendMenuStructure[],
  menuIds: number[],
): number[] {
  if (recommendationSystemList.length <= 0) {
    return [];
  }
  const previous = getPreviousSystem(numberOfCustomer, recommendationSystemList);
  const next = getNextSystem(numberOfCustomer, recommendationSystemList);
  const recommendedPattern =
    numberOfCustomer - previous.totalUserNumber <= next.totalUserNumber - numberOfCustomer
      ? previous.recommendMenus
      : next.recommendMenus;
  return recommendedPattern
    .sort((a, b) => b.probability - a.probability)
    .map((reco) => reco.menuId)
    .filter((id) => menuIds.includes(id));
}
