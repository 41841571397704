import { create } from 'zustand';
import { menuService } from '../services';
import {
  IBaseMenuResDto,
  ICategoryMenuResDto,
  IMenuDetailsResDto,
  Nullable,
} from '../submodules/sicpama-shared';

interface MenuProps {
  isLoading: boolean;
  baseMenuEntities: Record<string | number, IBaseMenuResDto>;
  menusByCategories: ICategoryMenuResDto[];
  menuEntities: Record<string | number, IMenuDetailsResDto>;
  getMenus: () => Promise<void>;
  getMenuDetail: (id: number) => Promise<Nullable<IMenuDetailsResDto>>;
  getMenusByStoreId: (storeId: number, nameKeyword?: string) => Promise<void>;
}

const categoryToMenuRecord = (
  arr: ICategoryMenuResDto[],
): Record<string | number, IBaseMenuResDto> =>
  arr
    .map((c) => c.menus)
    .flat()
    .reduce<Record<string | number, IBaseMenuResDto>>((acc, v) => {
      acc[v.id] = v;
      return acc;
    }, {});

export const useMenuStore = create<MenuProps>((set) => ({
  isLoading: false,
  baseMenuEntities: {},
  menusByCategories: [],
  menuEntities: {},
  getMenus: async () => {
    set({ isLoading: true });
    const menusByCategories = await menuService.getMenus();
    set((state) => ({
      ...state,
      isLoading: false,
      menusByCategories,
      baseMenuEntities: categoryToMenuRecord(menusByCategories),
    }));
  },
  getMenusByStoreId: async (storeId, nameKeyword) => {
    set({ isLoading: true });
    const menusByCategories = await menuService.getMenusByStoreId(storeId, nameKeyword);
    if (menusByCategories) {
      set((state) => ({
        ...state,
        isLoading: false,
        menusByCategories,
        baseMenuEntities: categoryToMenuRecord(menusByCategories),
      }));
    }
  },
  getMenuDetail: async (id) => {
    const menuDetail = await menuService.getMenuById(id);
    if (menuDetail === null) {
      return menuDetail;
    }
    set((state) => ({
      ...state,
      menuEntities: {
        ...state.menuEntities,
        [id]: menuDetail,
      },
    }));
    return menuDetail;
  },
}));

const getBaseMenuIds = (state: MenuProps): number[] =>
  Object.keys(state.baseMenuEntities).map((id) => parseInt(id));
const getMenuById = (id: number | string) => (state: MenuProps) => state.menuEntities[id];
const getBaseMenuById = (id: number | string) => (state: MenuProps) => state.baseMenuEntities[id];
const getRecommendationSystemList = (id: number | string) => (state: MenuProps) =>
  state.baseMenuEntities[id]?.recommend ?? [];
const getDiscountCarouselIds = (state: MenuProps): number[] =>
  Object.keys(state.baseMenuEntities)
    .filter((id) => state.baseMenuEntities[id].isTodayDiscounted)
    .map((id) => parseInt(id));
const getDefaultCarouselIds = (state: MenuProps): number[] =>
  Object.keys(state.baseMenuEntities)
    .filter((id) => state.baseMenuEntities[id].priority !== null)
    .sort(
      (a, b) =>
        (state.baseMenuEntities[a].priority ?? 0) - (state.baseMenuEntities[b].priority ?? 0),
    )
    .map((id) => parseInt(id));

export const menuSelectors = {
  getBaseMenuIds,
  getMenuById,
  getBaseMenuById,
  getRecommendationSystemList,
  getDefaultCarouselIds,
  getDiscountCarouselIds,
};
