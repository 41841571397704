import styled from '@emotion/styled';
import { Accordion, Modal } from '@sicpama/core-components';
import { ReactElement, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { STORE_ATTRIBUTE_NAME } from 'submodules/sicpama-shared';
import { StoreDetailsResDto } from 'types';
import useModal from '../../hooks/useModal';
import { useOrderStore, useThemeStore } from '../../stores';

const StyledAccordion = styled(Accordion)`
  .mantine-Accordion-control {
    padding: 0 0 8px 0;
    font-size: 14px !important;
  }
  .mantine-Accordion-content {
    padding: 0;
    font-size: 14px !important;
  }
  .mantine-Accordion-label {
    padding: 0;
  }

  .mantine-Accordion-content {
    color: #666;
  }

  .mantine-Accordion-item {
    border-bottom: none;
  }
`;

interface FooterContentProps {
  translationPrefix: string;
  openTC: () => void;
  openPrivacy: () => void;
  mailOrderRegistrationNumber?: string;
  t: any;
  store: StoreDetailsResDto;
}

const FooterContent = ({
  translationPrefix,
  openTC,
  openPrivacy,
  t,
  mailOrderRegistrationNumber,
  store: { countryCode, ownerName, name, registrationNumber, address, phoneNumber },
}: FooterContentProps): ReactElement => {
  return (
    <div className="mt-6 mb-14 flex flex-col gap-4">
      <div className="flex gap-1 text-[#666] text-sm">
        <div className="underline cursor-pointer" onClick={openTC}>
          {t('footer.tc')}
        </div>
        <div className="">{t('common.and')}</div>
        <div className="underline cursor-pointer" onClick={openPrivacy}>
          {t('footer.privacy')}
        </div>
      </div>
      <StyledAccordion
        items={[
          {
            value: '1',
            title: t(`${translationPrefix}.title`),
            content: <Trans i18nKey={`${translationPrefix}.content`} />,
          },
        ]}
      />
      {/* Exclusive only for Korea */}
      {countryCode === 'KR' && (
        <StyledAccordion
          items={[
            {
              value: '1',
              title: `${name} 사업자 정보`,
              content: (
                <>
                  <div>상호명: {name}</div>
                  {mailOrderRegistrationNumber && (
                    <div>통신판매업신고번호: {mailOrderRegistrationNumber}</div>
                  )}
                  {ownerName && <div>대표자명: {ownerName}</div>}
                  {registrationNumber && <div>사업자등록번호: {registrationNumber}</div>}
                  <div>사업장 주소: {address}</div>
                  <div>유선번호: {phoneNumber}</div>
                </>
              ),
            },
          ]}
        />
      )}
      <div className="text-[#666] text-sm">{t('footer.shortDescription')}</div>
    </div>
  );
};
const Footer = (): ReactElement => {
  const { t } = useTranslation();
  const { opened: tcOpened, openModal: openTC, closeModal: closeTC } = useModal();
  const { opened: privacyOpened, openModal: openPrivacy, closeModal: closePrivacy } = useModal();
  const { store } = useThemeStore();

  const { myOrder, otherOrders } = useOrderStore();

  const shouldStayAtTop = useMemo(() => {
    return !myOrder?.orderItems?.length && !otherOrders?.length;
  }, [myOrder, otherOrders]);

  const { countryCode } = store;

  let translationPrefix;

  if (countryCode === 'KR') {
    translationPrefix = 'footer.sicpama-kr-info';
  } else {
    translationPrefix = 'footer.sicpama-sg-info';
  }

  const isSicpamaLogoDisabled = useMemo(() => {
    const sicpamaLogoDisabledAttribute = store?.attributes?.find(
      (x) => x.name === STORE_ATTRIBUTE_NAME.SICPAMA_LOGO_DISABLED,
    );
    return sicpamaLogoDisabledAttribute?.value === 'true';
  }, [store]);

  const mailOrderRegistrationNumber = useMemo(() => {
    const mailOrderRegistrationNumberAttribute = store?.attributes?.find(
      (x) => x.name === STORE_ATTRIBUTE_NAME.MAIL_ORDER_SALES_REGISTRATION_NUMBER,
    );
    return mailOrderRegistrationNumberAttribute?.value || '2022-대구동구-0174';
  }, [store]);

  if (countryCode === 'SG' && isSicpamaLogoDisabled) {
    return (
      <div
        className={`w-full bg-[#f7f7f7] mt-[16px] ${shouldStayAtTop ? '' : 'mb-28'} py-8 px-6`}
      ></div>
    );
  }

  return (
    <div className={`w-full bg-[#f7f7f7] mt-[16px] ${shouldStayAtTop ? '' : 'mb-28'} py-8 px-6`}>
      {countryCode === 'KR' ? (
        <>
          {!isSicpamaLogoDisabled && (
            <img src="/images/sicpama-text-logo.png" className="!w-[120px] h-full" />
          )}
          {FooterContent({
            translationPrefix,
            openTC,
            openPrivacy,
            t,
            store,
            mailOrderRegistrationNumber,
          })}
        </>
      ) : (
        <StyledAccordion
          items={[
            {
              value: '2',
              title: <img src="/images/sicpama-text-logo.png" className="!w-[120px] h-full" />,
              content: FooterContent({ translationPrefix, openTC, openPrivacy, t, store }),
            },
          ]}
        />
      )}

      <Modal
        withCloseButton
        opened={tcOpened}
        onClose={closeTC}
        fullScreen
        title={t('footer.tc')}
        styles={() => ({
          body: {
            height: 'calc(100% - 60px)',
            overflowY: 'auto',
            padding: 0,
          },
        })}
      >
        <iframe src="https://www.sicpama.com/tc" className="w-full h-full" />
      </Modal>
      <Modal
        withCloseButton
        opened={privacyOpened}
        onClose={closePrivacy}
        fullScreen
        title={t('footer.privacy')}
        styles={() => ({
          body: {
            height: 'calc(100% - 60px)',
            overflowY: 'auto',
            padding: 0,
          },
        })}
      >
        <iframe src="https://www.sicpama.com/privacy" className="w-full h-full" />
      </Modal>
    </div>
  );
};

export default Footer;
