import { PaymentRequestDtoType } from 'factories/payment/payment-methods';
import type { ReactElement } from 'react';
import { PaymentGatewayRequestOptions } from 'types';

interface KICCPaymentRequestInputFormProps {
  data: PaymentRequestDtoType;
  options: PaymentGatewayRequestOptions;
}

export const KICCPaymentRequestInputForm = ({
  data,
  options,
}: KICCPaymentRequestInputFormProps): ReactElement => {
  return (
    <form
      name={options.name}
      method={options.method}
      action={options.action}
      acceptCharset={options.charSet}
    >
      {Object.entries(data).map(([key, value]) => {
        return <input key={key} type={options.displayType} name={key} value={value} />;
      })}
    </form>
  );
};
