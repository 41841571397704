import * as React from "react";

function SvgCopyBg(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="#EEF1F4" />
      <path d="M9 9L9 6C9 5.44771 9.44772 5 10 5L18 5C18.5523 5 19 5.44772 19 6L19 14C19 14.5523 18.5523 15 18 15L15 15" stroke="#444C54" />
      <path d="M15 10L15 18C15 18.5523 14.5523 19 14 19L6 19C5.44771 19 5 18.5523 5 18L5 10C5 9.44771 5.44772 9 6 9L14 9C14.5523 9 15 9.44772 15 10Z" fill="#444C54" />
    </svg>
  );
}

export default SvgCopyBg;
