import * as React from 'react';

function SvgTrashBin(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
     <path
        d="M5.14286 3.65695V1.94266C5.14286 1.31148 5.65453 0.799805 6.28571 0.799805H10.8571C11.4883 0.799805 12 1.31148 12 1.94266V3.65695M0 4.22838H17.1429M1.71429 4.22838V15.6569C1.71429 16.2881 2.22596 16.7998 2.85714 16.7998H14.2857C14.9169 16.7998 15.4286 16.2881 15.4286 15.6569V4.22838M8.57143 4.78553V12.7855M5.14286 4.78553V10.4998M12 4.78553V10.4998"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgTrashBin;
