import * as React from "react";

function SvgBulbOn(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.34 10.478l.496.07-.495-.07zm-.013.092l-.495-.07.495.07zm8.987-.092l.495-.071-.495.07zm.014.092l-.495.07.495-.07zm-1.88 7.81v.5a.5.5 0 00.5-.5h-.5zm-5.241 0h-.5a.5.5 0 00.5.5v-.5zm-.976-4.386l.396-.307-.396.306zm7.193 0l-.396-.307.396.306zM9.862 21.5h3.931v-1h-3.93v1zM6.846 10.407l-.014.092.99.142.014-.093-.99-.141zm4.982-4.32a5.033 5.033 0 00-4.982 4.32l.99.141a4.033 4.033 0 013.992-3.462v-1zm4.982 4.32a5.033 5.033 0 00-4.982-4.32v1a4.033 4.033 0 013.992 3.461l.99-.141zm.013.092l-.014-.092-.99.141.014.093.99-.142zm-1.004 3.8a5.023 5.023 0 001.004-3.8l-.99.142a4.022 4.022 0 01-.805 3.046l.791.613zm-1.87 1.986v2.094h1v-2.095h-1zm.5 1.594H9.206v1h5.241v-1zm-4.742.5v-2.095h-1v2.095h1zM6.832 10.5a5.022 5.022 0 001.004 3.8l.79-.612a4.022 4.022 0 01-.804-3.046l-.99-.142zm2.875 5.786c0-1.026-.546-1.907-1.08-2.598l-.791.613c.507.654.87 1.309.87 1.985h1zm5.321-2.598c-.534.691-1.08 1.572-1.08 2.598h1c0-.676.365-1.33.871-1.986l-.79-.612zM11.328 2v2.62h1V2h-1zM2 12.328h2.62v-1H2v1zm17.035 0h2.62v-1h-2.62v1zm-12.75-5.44L4.319 4.922l-.707.707 1.965 1.966.708-.707zm11.793.707l1.965-1.966-.707-.707-1.965 1.966.707.707z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBulbOn;
