import * as React from "react";

function SvgGlobeAfrica(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12a8 8 0 1116 0 8 8 0 01-16 0zm1.067 0a6.935 6.935 0 015.895-6.856l.505.252v.408l-.772.386a.534.534 0 00-.058.92l1.6 1.067c.088.059.191.09.296.09h.534a.533.533 0 00.533-.534V7.2h.533v.533c0 .142.057.277.157.377l.377.378v.624l-.221.221h-.72l-2.02-1.01a.533.533 0 00-.478 0l-.954.477H8.8a.533.533 0 00-.517.404l-.534 2.133a.533.533 0 00.14.507l1.6 1.6c.041.04.088.074.14.1l.771.386v1.27c0 .142.056.277.156.377l.377.377v.846c0 .083.02.165.057.238l.533 1.067c.09.18.275.295.477.295h1.067c.178 0 .345-.09.443-.237l1.067-1.6a.527.527 0 00.033-.058l.534-1.066a.533.533 0 00.056-.239v-.889l1.493-1.991a.533.533 0 00.05-.559l-.147-.294h.737a.533.533 0 00.534-.534V10.4h.88A6.933 6.933 0 115.067 12z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgGlobeAfrica;
