import * as React from "react";

function SvgStethoscope(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 15C18.1046 15 19 14.1046 19 13C19 11.8954 18.1046 11 17 11C15.8954 11 15 11.8954 15 13C15 14.1046 15.8954 15 17 15ZM17 15V16C17 17.0609 16.5786 18.0783 15.8284 18.8284C15.0783 19.5786 14.0609 20 13 20C11.9391 20 10.9217 19.5786 10.1716 18.8284C9.42143 18.0783 9 17.0609 9 16V15M9 15C9.55228 15 10 14.5523 10 14C10 13.4477 9.55228 13 9 13C8.44772 13 8 13.4477 8 14C8 14.5523 8.44772 15 9 15ZM6.5 3.5C5.83333 3.5 4.5 4.3 4.5 7.5C4.5 10.7 6.83333 12.8333 8 13.5M11.5 3.5C12.1667 3.5 13.5 4.3 13.5 7.5C13.5 10.7 11.1667 12.8333 10 13.5"
        stroke="currentColor"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default SvgStethoscope;
