import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import BaseHttpService from './base-http.service';
import { logger } from '../configs';
import { IJoinTableResDto } from '../submodules/sicpama-shared';

const PATH = 'consumer-store-tables';

class StoreTableService extends BaseHttpService {
  async joinTable(token: string, fingerprint?: string): Promise<string | null> {
    const joinTableRes = await this.post<IJoinTableResDto>(
      `${PATH}/join`,
      {},
      {
        headers: {
          'store-table-token': token,
          'finger-print': fingerprint,
        },
      },
    );

    if (joinTableRes.redirectUrl !== undefined) {
      // If there's a redirectUrl, perform a redirect on the client-side
      // 'de9c2c4d-31b9-4371-bdaa' this token value was used in the qr codes that are side the posts on the walls of JangDo,
      // which is an invalid token for Singapore server.
      // Instead of redirecting, send a response indicating the need for redirection
      logger.info('Redirecting to:', { url: joinTableRes.redirectUrl });
      window.location.href = joinTableRes.redirectUrl;
      return null;
    }
    const joinTableData = joinTableRes.data;
    console.info('joinTableData: ', { joinTableData });
    if (!joinTableData) {
      return null;
    }

    logger.info('joinTableData_data: ', {
      joinTableData,
      orderId: joinTableData?.orderId,
    });

    localStorage.setItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID, joinTableData.orderId);
    localStorage.setItem(
      LOCAL_STORAGE_KEY.GUEST_ACCESS_TOKEN,
      joinTableData.guestAccessToken ?? '',
    );
    localStorage.setItem(LOCAL_STORAGE_KEY.MY_ID, joinTableData.customerId);
    localStorage.setItem(LOCAL_STORAGE_KEY.SID, joinTableData.sessionId);
    localStorage.setItem(
      LOCAL_STORAGE_KEY.LINKED_WITH_FINGERPRINT,
      joinTableData.customerLinkedWithFingerPrint.toString(),
    );
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'join_table',
      order_id: joinTableData.orderId,
      token_id: token,
    });
    return joinTableData.sessionId;
  }
}

export const storeTableService = new StoreTableService();
