import { Icon } from '@sicpama/core-components';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { PublicContainer } from 'components';
import { CenterLoader } from 'components/loader';
import { STORE_ATTRIBUTE_NAME } from '../../constants';
import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'utils/debounce';
import Divider from '../../components/divider';
import Footer from '../../components/footer';
import { useMenuStore, useThemeStore } from '../../stores';
import CategoryMenus from './CategoryMenus';
import DiscountCarousel from './DiscountCarousel';
import { ICategoryMenuResDto, Nullable } from '../../submodules/sicpama-shared';

const ReadOnlyMenuList: React.FC = () => {
  const containerRef = useRef(null);
  const { storeId } = useParams();
  const {
    menusByCategories: categories,
    getMenusByStoreId,
    isLoading: isMenuLoading,
  } = useMenuStore();

  const { store } = useThemeStore();
  const { attributes } = store;
  const priceNoteAttribute = attributes?.find((x) => x.name === STORE_ATTRIBUTE_NAME.PRICE_NOTE);
  const priceNoteValue = priceNoteAttribute?.value;
  const [selectedCategory, setSelectedCategory] = useState<Nullable<ICategoryMenuResDto>>(null);
  const categoryBarRef = useRef<HTMLDivElement>(null);
  const categoryListRef = useRef<HTMLDivElement[]>([]);
  const intersectingListRef = useRef<Array<boolean | undefined>>([]);

  useEffect(() => {
    if (categories.length && !selectedCategory) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  const setIntersectingState = useCallback(
    (categoryIndex: number) => (state: boolean) => {
      intersectingListRef.current[categoryIndex] = state;
      const intersectingList = intersectingListRef.current;
      const topIntersectCategoryIndex = intersectingList.reduce(
        (acc, cur, index) => (cur ? (acc > -1 ? Math.min(acc, index) : index) : acc),
        -1,
      );
      setSelectedCategory(categories[topIntersectCategoryIndex]);
      debounceScrollIntoView(topIntersectCategoryIndex);
    },
    [categories],
  );

  const debounceScrollIntoView = useCallback(
    debounce<[number]>((topIntersectCategoryIndex) => {
      const categoryCell = categoryListRef.current[topIntersectCategoryIndex];
      const categoryBar = categoryBarRef.current;
      if (categoryCell && categoryBar) {
        if (
          +categoryCell.offsetLeft + +categoryCell.offsetWidth >
            +categoryBar.scrollLeft + +categoryBar.offsetWidth ||
          +categoryCell.offsetLeft < +categoryBar.scrollLeft - 16
        ) {
          categoryBar.scrollTo(categoryCell.offsetLeft - 16, 0);
        }
      }
    }, 100),
    [],
  );

  const onCategoryTabClicked = useCallback((category: ICategoryMenuResDto) => {
    setSelectedCategory(category);
    const categoryContent = document.querySelector(`#category-tab-${category.categoryId}`);
    const container = containerRef.current;
    if (categoryContent && container) {
      const offsetTop = (categoryContent as HTMLDivElement).offsetTop;
      (container as HTMLDivElement).scrollTo(0, offsetTop - 56);
    }
  }, []);

  useEffect(() => {
    if (storeId) {
      getMenusByStoreId(+storeId);
    }
  }, [storeId]);

  if (isMenuLoading) {
    return <CenterLoader />;
  }

  return (
    <PublicContainer showSearch={true} containerRef={containerRef} showProgressBar={false}>
      <DiscountCarousel />
      <Divider className="!min-h-[8px] !h-[8px] my-2" />
      <div className="px-4 py-1 overflow-hidden sticky top-[56px] z-10 shrink-0 mt-6 bg-white border-b">
        <div
          className="flex overflow-y-hidden overflow-x-auto w-full scrollbar-hide gap-2"
          ref={categoryBarRef}
        >
          {categories.map((category, index) => (
            <div
              key={category.categoryId}
              ref={(el) => {
                if (el !== null) {
                  categoryListRef.current[index] = el;
                }
              }}
              onClick={() => {
                onCategoryTabClicked(category);
              }}
              className={clsx(
                'flex items-center justify-center min-w-[92px] h-full text-sm overflow-hidden shrink-0 px-3 py-2 rounded border border-theme',
                selectedCategory === category
                  ? 'bg-theme font-bold text-[#ffffff]'
                  : 'bg-white text-theme',
              )}
              id={ANALYTICS_CSS_ID(category.categoryId).CATEGORY}
            >
              {category.categoryName}
            </div>
          ))}
        </div>
      </div>
      {priceNoteValue && (
        <div
          className="mx-4 mt-2 gap-2 py-2 px-4 flex items-center bg-[#F7F7F7]"
          id={ANALYTICS_CSS_ID().MENU_PAGE.NOTIFICATION_BANNER}
        >
          <Icon name="InfoCircle" type="filled" size={14} color="#999" />
          <p className="text-xs text-[#4d4d4d]">{priceNoteValue}</p>
        </div>
      )}
      <div className="flex flex-col bg-white p-4 min-h-0 mb-4">
        {categories.map((category, index) => (
          <CategoryMenus
            key={category.categoryId}
            category={category}
            setIntersectingState={setIntersectingState(index)}
          />
        ))}
      </div>
      <Footer />
    </PublicContainer>
  );
};

export default ReadOnlyMenuList;
