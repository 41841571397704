var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Carousel as MantineCarousel } from '@mantine/carousel';
var Carousel = function (_a) {
    var slideSize = _a.slideSize, height = _a.height, align = _a.align, slideGap = _a.slideGap, items = _a.items, _b = _a.dragFree, dragFree = _b === void 0 ? true : _b, maxWidth = _a.maxWidth, _c = _a.withControls, withControls = _c === void 0 ? true : _c, controlSize = _a.controlSize, _d = _a.orientation, orientation = _d === void 0 ? 'horizontal' : _d, loop = _a.loop, _e = _a.withIndicators, withIndicators = _e === void 0 ? false : _e, slidesToScroll = _a.slidesToScroll, breakpoints = _a.breakpoints, nextControlIcon = _a.nextControlIcon, previousControlIcon = _a.previousControlIcon, speed = _a.speed, rest = __rest(_a, ["slideSize", "height", "align", "slideGap", "items", "dragFree", "maxWidth", "withControls", "controlSize", "orientation", "loop", "withIndicators", "slidesToScroll", "breakpoints", "nextControlIcon", "previousControlIcon", "speed"]);
    return (React.createElement(MantineCarousel, __assign({ maw: maxWidth, slideSize: slideSize, height: height, align: align, slideGap: slideGap, dragFree: dragFree, orientation: orientation, withControls: withControls, controlSize: controlSize, loop: loop, withIndicators: withIndicators, slidesToScroll: slidesToScroll, breakpoints: breakpoints, nextControlIcon: nextControlIcon, previousControlIcon: previousControlIcon, speed: speed }, rest), items.map(function (item, index) {
        return React.createElement(MantineCarousel.Slide, { key: index }, item);
    })));
};
export default React.memo(Carousel);
