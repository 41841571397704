var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { Button as MantineButton } from '@mantine/core';
var Button = function (_a, ref) {
    var _b = _a.borderRadius, borderRadius = _b === void 0 ? 'md' : _b, loading = _a.loading, disabled = _a.disabled, fullWidth = _a.fullWidth, target = _a.target, rel = _a.rel, href = _a.href, htmlType = _a.htmlType, leftIcon = _a.leftIcon, rightIcon = _a.rightIcon, title = _a.title, titleColor = _a.titleColor, onClick = _a.onClick, _c = _a.type, type = _c === void 0 ? 'filled' : _c, typeColor = _a.typeColor, _d = _a.size, size = _d === void 0 ? 'xl' : _d, gradient = _a.gradient, backgroundColor = _a.backgroundColor, borderColor = _a.borderColor, height = _a.height, width = _a.width, uppercase = _a.uppercase, fontWeight = _a.fontWeight, fontSize = _a.fontSize, lineHeight = _a.lineHeight, id = _a.id, rest = __rest(_a, ["borderRadius", "loading", "disabled", "fullWidth", "target", "rel", "href", "htmlType", "leftIcon", "rightIcon", "title", "titleColor", "onClick", "type", "typeColor", "size", "gradient", "backgroundColor", "borderColor", "height", "width", "uppercase", "fontWeight", "fontSize", "lineHeight", "id"]);
    var component;
    if (href)
        component = 'a';
    if (size === 'xl') {
        fontWeight = 700;
        fontSize = 18;
        lineHeight = 18;
    }
    return (React.createElement(MantineButton, __assign({ fullWidth: fullWidth, radius: borderRadius, disabled: disabled, loading: loading, component: component, target: target, rel: rel, href: href, leftIcon: leftIcon, rightIcon: rightIcon, onClick: onClick, color: typeColor, variant: type, gradient: gradient, type: htmlType, ref: ref, size: size, uppercase: uppercase, sx: { width: width, height: height, backgroundColor: backgroundColor, color: titleColor, borderColor: borderColor, fontWeight: fontWeight, fontSize: fontSize, lineHeight: lineHeight }, id: id }, rest), title));
};
export default forwardRef(Button);
