import * as React from "react";

function SvgFingerprint(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.995 5.89a5.895 5.895 0 00-5.936.003 5.957 5.957 0 00-2.17 2.195 6.018 6.018 0 00-.79 2.997v.818a17.525 17.525 0 00.706 5.555l.154.522-1.044.308-.154-.522a18.614 18.614 0 01-.751-5.872v-.806a7.107 7.107 0 01.932-3.54A7.045 7.045 0 018.51 4.954a6.984 6.984 0 017.032-.005l.47.274-.547.94-.47-.273zm2.809 1.188l.28.467a7.108 7.108 0 01.967 4.386v.788c-.001.764.198 1.514.576 2.176l.27.473-.945.54-.27-.473a5.465 5.465 0 01-.72-2.718v-.815c0-.02.001-.039.003-.058a6.02 6.02 0 00-.816-3.74l-.28-.467.935-.56zm-5.774 1.29c-.713 0-1.397.286-1.903.796a2.73 2.73 0 00-.79 1.923v.544h-1.09v-.544a3.82 3.82 0 011.107-2.69A3.77 3.77 0 0112.03 7.28a3.77 3.77 0 012.676 1.117 3.82 3.82 0 011.107 2.69v.816c0 1.647.53 3.25 1.511 4.568l.325.437-.873.65-.325-.437a8.74 8.74 0 01-1.727-5.218v-.816a2.73 2.73 0 00-.79-1.923 2.682 2.682 0 00-1.904-.795zm.545 4.35c-.003 2.223.67 4.392 1.927 6.216l.31.448-.897.618-.31-.448a12.021 12.021 0 01-2.12-6.834M9.27 13.746l.063.54c.19 1.642.666 3.237 1.403 4.714l.244.486-.974.487-.244-.487a15.307 15.307 0 01-1.51-5.074l-.063-.54 1.081-.126z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFingerprint;
