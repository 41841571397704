import * as React from "react";

function SvgMicrophoneOff(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 10V13.1667C10 13.7778 10.4 15 12 15C13.01 15 13.5419 14.5129 13.7968 14M10 8V5.83333C10 5.22222 10.4 4 12 4C13.6 4 14 5.22222 14 5.83333V12" stroke="#444C54" />
      <path d="M12 17V21" stroke="#444C54" />
      <path d="M8 11V13.1818C8 14.4545 8.8 17 12 17C13.7009 17 14.7237 16.2809 15.3088 15.416M16 11V13.1818C16 13.4187 15.9723 13.6996 15.9066 14" stroke="#444C54" />
      <path d="M3 3L21 21" stroke="#444C54" />
    </svg>
  );
}

export default SvgMicrophoneOff;
