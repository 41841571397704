import * as React from "react";

function SvgBulbOff(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.062 10.05l-.495-.07.495.07zm-.014.096l.495.07-.495-.07zm9.353-.096l-.495.07.495-.07zm.014.096l.495-.071-.495.07zm-1.956 8.127v.5a.5.5 0 00.5-.5h-.5zm-5.455 0h-.5a.5.5 0 00.5.5v-.5zM7.99 13.709l.395-.306-.395.306zm7.485 0l-.395-.306.395.306zM9.686 21.5h4.091v-1h-4.09v1zM6.567 9.98l-.014.095.99.141.014-.095-.99-.142zm5.165-4.48a5.217 5.217 0 00-5.165 4.48l.99.14a4.217 4.217 0 014.175-3.62v-1zm5.164 4.48a5.217 5.217 0 00-5.164-4.48v1a4.217 4.217 0 014.174 3.62l.99-.14zm.014.095l-.014-.096-.99.142.014.095.99-.141zm-1.04 3.94a5.206 5.206 0 001.04-3.94l-.99.141a4.207 4.207 0 01-.841 3.187l.79.611zm-1.911 2.078v2.18h1v-2.18h-1zm.5 1.68H9.004v1h5.455v-1zm-4.955.5v-2.18h-1v2.18h1zm-2.95-8.198a5.206 5.206 0 001.04 3.94l.79-.613a4.207 4.207 0 01-.84-3.186l-.99-.141zm2.95 6.018c0-1.06-.564-1.973-1.12-2.69l-.79.612c.528.681.91 1.367.91 2.078h1zm5.575-2.69c-.556.717-1.12 1.63-1.12 2.69h1c0-.71.383-1.396.91-2.079l-.79-.611z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBulbOff;
