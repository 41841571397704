import * as React from "react";

function SvgReddit(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.13 4.018a.48.48 0 00-.458.112.654.654 0 00-.205.485v4.327a7.875 7.875 0 00-3.782 1.284 2.145 2.145 0 00-1.528-.634C4.965 9.592 4 10.706 4 12.081c0 .92.433 1.716 1.074 2.144a3.875 3.875 0 00-.007.236c0 1.53.909 2.924 2.156 3.902A7.834 7.834 0 0012 20a7.834 7.834 0 004.777-1.637c1.247-.978 2.156-2.372 2.156-3.902 0-.079-.002-.157-.007-.236.64-.428 1.074-1.224 1.074-2.144 0-1.375-.965-2.489-2.157-2.489-.556 0-1.114.215-1.528.634a7.875 7.875 0 00-3.782-1.284V5.404l3.259.94c.188.778.807 1.348 1.541 1.348.884 0 1.6-.826 1.6-1.846S18.217 4 17.333 4c-.652 0-1.214.45-1.463 1.098l-3.74-1.08zm5.203 2.444c-.292 0-.529-.271-.533-.607v-.019c.005-.335.241-.605.533-.605.295 0 .534.275.534.615 0 .34-.24.616-.534.616zm-8 7.384H10.4v-1.23H9.333v1.23zM12 16.308c-1.053 0-1.724-.21-2.46-.567l-.414 1.134c.85.413 1.662.663 2.874.663 1.212 0 2.025-.25 2.874-.663l-.414-1.134c-.736.357-1.408.567-2.46.567zm2.667-2.462H13.6v-1.23h1.067v1.23z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgReddit;
