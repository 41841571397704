import * as React from "react";

function SvgCompass(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.571 15.429l-.447-.224a.5.5 0 00.671.67l-.224-.446zm2.286-4.572l-.223-.447a.5.5 0 00-.224.223l.447.224zm4.572-2.286l.447.224a.5.5 0 00-.671-.67l.224.446zm-2.286 4.572l.223.447a.5.5 0 00.224-.223l-.447-.224zM12 19.5A7.5 7.5 0 014.5 12h-1a8.5 8.5 0 008.5 8.5v-1zm7.5-7.5a7.5 7.5 0 01-7.5 7.5v1a8.5 8.5 0 008.5-8.5h-1zM12 4.5a7.5 7.5 0 017.5 7.5h1A8.5 8.5 0 0012 3.5v1zm0-1A8.5 8.5 0 003.5 12h1A7.5 7.5 0 0112 4.5v-1zM9.019 15.652l2.285-4.571-.894-.447-2.286 4.571.895.447zm2.062-4.348l4.571-2.285-.447-.895-4.572 2.286.448.894zm3.9-2.956l-2.285 4.571.894.447 2.286-4.571-.895-.447zm-2.062 4.348L8.348 14.98l.447.895 4.572-2.286-.448-.894z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCompass;
