import styled from '@emotion/styled';
import { Checkbox, Radio } from '@sicpama/core-components';

export const StyledRadio = styled(Radio)`
  .mantine-Radio-body {
    width: 100%;
    .mantine-Radio-labelWrapper {
      width: 100%;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .mantine-Checkbox-body {
    width: 100%;
    .mantine-Checkbox-labelWrapper {
      width: 100%;
    }
  }
`;
