(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["@sicpama/core-components"] = factory(require("react"), require("react-dom"));
	else
		root["@sicpama/core-components"] = factory(root["React"], root["ReactDom"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__359__, __WEBPACK_EXTERNAL_MODULE__80__) => {
return 