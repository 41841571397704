import * as React from "react";

function SvgHeart(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 19.714l-.354.354a.5.5 0 00.708 0L12 19.714zm-6.817-6.816l-.353.353.353-.353zm5.715-5.715l-.354.354.354-.354zM12 8.286l-.354.353a.5.5 0 00.708 0L12 8.286zm1.102-1.103l-.353-.353.353.353zm-.748 12.178l-6.817-6.817-.707.707 6.816 6.817.708-.707zm6.109-6.817l-6.817 6.817.708.707 6.816-6.817-.707-.707zm-7.919-5.007l1.102 1.102.708-.707L11.25 6.83l-.707.707zm1.81 1.102l1.102-1.102-.707-.707-1.103 1.102.708.707zM15.959 5.5a4.54 4.54 0 00-3.21 1.33l.707.707A3.54 3.54 0 0115.959 6.5v-1zm3.541 4.54a3.54 3.54 0 01-1.037 2.504l.707.707a4.54 4.54 0 001.33-3.21h-1zm1 0a4.54 4.54 0 00-4.54-4.54v1a3.54 3.54 0 013.54 3.54h1zM8.04 6.5c.94 0 1.84.373 2.504 1.037l.707-.707a4.54 4.54 0 00-3.21-1.33v1zM4.5 10.04A3.54 3.54 0 018.04 6.5v-1a4.54 4.54 0 00-4.54 4.54h1zm1.037 2.504A3.54 3.54 0 014.5 10.041h-1a4.54 4.54 0 001.33 3.21l.707-.707z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHeart;
