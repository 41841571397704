/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable no-lone-blocks */
import { Badge, QuantitySelector } from '@sicpama/core-components';
import Divider from 'components/divider';
import Loader from 'components/loader';
import MultiLinesText from 'components/multi-lines-text';
import { StyledCheckbox, StyledRadio } from 'pages/menu/styles';
import type { ReactElement } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sheet } from 'react-modal-sheet';
import { menuService } from 'services';
import { useThemeStore } from 'stores/theme';
import {
  CURRENCY_TO_LOCALE_PRICE,
  formattedDiscountPrice,
  isDiscounted,
  localePrice,
  numberLocaleFormat,
} from 'utils/numberLocaleFormat';
import set from 'utils/set';
import {
  IBaseMenuOptionChoiceResDto,
  IMenuDetailsResDto,
  IMenuOrderItemResDto,
  Nullable,
} from '../../submodules/sicpama-shared';

interface MenuDetailSheetProps {
  isOpen: boolean;
  onClose: VoidFunction;
  menuId: Nullable<number>;
  orderItemId?: string;
  currentDetail?: IMenuOrderItemResDto; // provide if we are editing an order
  onAddLessThanMinQuantityCallback?: VoidFunction;
}
const MenuDetailSheet = ({
  currentDetail,
  isOpen,
  onClose,
  menuId,
  orderItemId,
  onAddLessThanMinQuantityCallback,
}: MenuDetailSheetProps): ReactElement => {
  const {
    theme: { color: themeColor },
  } = useThemeStore();

  // editingDetail is using to store the state of this menu sheet while we are editing
  const [editingDetail, setEditingDetail] = useState<Nullable<Record<string, any>>>();

  // menuConfig should be the detail of this menu that we get from server, including all the options
  const [menuConfig, setMenuConfig] = useState<Nullable<IMenuDetailsResDto>>(null);

  // loading is used to show the loading indicator while fetching the menu config
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      if (menuId) {
        (async () => {
          setLoading(true);

          // find menu config from server
          const menuConfig = await menuService.getMenuById(menuId);
          setMenuConfig(menuConfig);

          // If editing existing order (change option in Tab screen),
          // we need to set the editingDetail state base on provided `currentDetail`
          if (currentDetail) {
            setEditingDetail({
              quantity: currentDetail.quantity ?? 1,
              options: currentDetail.menuOptions.reduce((acc, cur) => {
                return {
                  ...acc,
                  [cur.id]:
                    cur.maxChoices > 1
                      ? cur.choices.map((choice) => choice.id)
                      : cur.choices[0]?.id,
                };
              }, {}),
            });
          } else {
            // if we are adding a new menu (from menu home page), we need to set the editingDetail state base on the menu config
            setEditingDetail({
              quantity: 1,
              options: menuConfig?.menuOptions.reduce((acc, cur) => {
                return {
                  ...acc,
                  [cur.id]:
                    cur.maxChoices > 1
                      ? cur.choices.map((choice) => choice.id)
                      : cur.choices[0]?.id,
                };
              }, {}),
            });
          }
        })();
      }
      setLoading(false);
      return;
    }
    setMenuConfig(null);
    setEditingDetail({});
  }, [menuId, isOpen, currentDetail]);

  const renderMenuOptionChoices = (
    choices: IBaseMenuOptionChoiceResDto[],
    currency = 'KRW',
  ): any => {
    return choices.map((choice) => ({
      value: `${choice.id}`,
      label: (
        <div className="flex items-center justify-between w-full">
          <p className="break-all w-3/4">{choice.name}</p>
          <p className="w-1/4 text-right">{`${numberLocaleFormat(
            +choice.price,
            CURRENCY_TO_LOCALE_PRICE[currency],
          )}`}</p>
        </div>
      ),
    }));
  };

  const onDetailChange = (key: string, value: string | string[] | number): void => {
    setEditingDetail((prev = {}) => {
      const _prev = JSON.parse(JSON.stringify(prev));
      set(_prev, key, value);
      return _prev;
    });
  };

  return (
    <Sheet isOpen={isOpen} onClose={onClose} snapPoints={[500]}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          {loading || !menuConfig ? (
            <div className="flex items-center justify-center w-full h-full ">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-col w-full h-full">
              <div className="flex flex-col overflow-x-hidden overflow-y-scroll">
                <div className="flex flex-col gap-2 px-4 pb-1">
                  <Badge
                    className="w-fit"
                    title={menuConfig.category.name}
                    borderColor={themeColor}
                    backgroundColor="white"
                    titleColor={themeColor}
                  />
                  <div className="mt-2 text-lg font-bold text-black">
                    <MultiLinesText text={menuConfig.name} />
                  </div>
                  <div className="text-sm font-bold text-helper">
                    <MultiLinesText
                      text={menuConfig.description ?? menuConfig.category.description ?? ''}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-lg font-bold text-black">
                      <span
                        className={`${
                          menuConfig.isTodayDiscounted ? 'line-through text-[#868686]' : ''
                        }`}
                      >
                        {localePrice(menuConfig.currency, menuConfig.price)}
                      </span>{' '}
                      {isDiscounted(menuConfig) && (
                        <span className="text-theme">{formattedDiscountPrice(menuConfig)}</span>
                      )}
                    </p>
                    <div className="p-0.5 border rounded w-[127px]">
                      <QuantitySelector
                        value={(editingDetail?.quantity as number) ?? 1}
                        onChange={(value: any) => {
                          onDetailChange('quantity', value);
                        }}
                        min={1}
                        readOnly
                        plusBackgroundColor={themeColor}
                        plusColor="white"
                      />
                    </div>
                  </div>
                </div>
                {menuConfig.photo ? (
                  <img src={menuConfig.photo} alt="menu" className="w-full mt-2 mb-3" />
                ) : null}
                {menuConfig.menuOptions.map((menuOption) => {
                  return (
                    <Fragment key={menuOption.id}>
                      <Divider />
                      <div className="flex flex-col w-full gap-4 p-4 shrink-0">
                        <div className="flex items-center justify-between">
                          <p className="text-lg font-bold text-black">{menuOption.name}</p>

                          <Badge
                            className="w-fit"
                            title={
                              menuOption.minChoices > 0
                                ? t('common.required')
                                : t('common.optional')
                            }
                            backgroundColor={menuOption.minChoices > 0 ? themeColor : '#dadada'}
                            titleColor={menuOption.minChoices > 0 ? 'white' : 'grey'}
                          />
                        </div>
                        {menuOption.maxChoices > 1 ? (
                          <StyledCheckbox
                            value={(editingDetail?.options?.[`${menuOption.id}`] as string[]) ?? []}
                            onChange={(value) => {
                              onDetailChange(`options.${menuOption.id}`, value);
                            }}
                            items={renderMenuOptionChoices(
                              menuOption.choices,
                              menuConfig?.currency,
                            )}
                          />
                        ) : (
                          <StyledRadio
                            value={`${
                              (editingDetail?.options?.[`${menuOption.id}`] as string) ??
                              menuOption.choices[0]?.id
                            }`}
                            onChange={(value) => {
                              onDetailChange(`options.${menuOption.id}`, value);
                            }}
                            items={renderMenuOptionChoices(
                              menuOption.choices,
                              menuConfig?.currency,
                            )}
                            radioColor={themeColor}
                          />
                        )}
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  );
};

export default MenuDetailSheet;
