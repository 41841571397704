import * as React from "react";

function SvgReddit(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 5l.121-.485A.5.5 0 0011.5 5h.5zm6.357 9.143c0 .918-.65 1.928-1.84 2.736-1.175.798-2.79 1.335-4.517 1.335v1c1.924 0 3.736-.596 5.078-1.508 1.326-.9 2.28-2.176 2.28-3.563h-1zM12 18.214c-1.726 0-3.342-.537-4.516-1.335-1.19-.808-1.841-1.818-1.841-2.736h-1c0 1.387.952 2.662 2.279 3.563 1.342.912 3.154 1.508 5.078 1.508v-1zm-6.357-4.071c0-.918.65-1.928 1.84-2.737 1.175-.797 2.79-1.335 4.517-1.335v-1c-1.924 0-3.736.596-5.078 1.508-1.327.901-2.28 2.177-2.28 3.564h1zM12 10.07c1.726 0 3.342.538 4.516 1.335 1.19.809 1.841 1.819 1.841 2.737h1c0-1.387-.953-2.663-2.279-3.564-1.342-.912-3.154-1.508-5.078-1.508v1zm2.663 5.325c-.795.335-1.525.533-2.663.533v1c1.288 0 2.15-.231 3.051-.611l-.388-.922zM12 15.93c-1.138 0-1.868-.198-2.663-.533l-.388.922c.902.38 1.763.61 3.051.61v-1zm.5-6.358V5h-1v4.571h1zm-.621-4.086l4.571 1.143.243-.97-4.572-1.143-.242.97zm-3.976 5.642c-.315-.942-1.236-1.434-2.164-1.434v1c.607 0 1.068.312 1.215.751l.949-.317zM5.739 9.693a2.239 2.239 0 00-2.239 2.24h1c0-.685.555-1.24 1.24-1.24v-1zM3.5 11.933c0 1.132.842 2.056 1.927 2.208l.139-.99A1.233 1.233 0 014.5 11.932h-1zm13.546-.489c.147-.44.608-.751 1.215-.751v-1c-.928 0-1.849.492-2.164 1.434l.949.317zm1.215-.751c.684 0 1.239.555 1.239 1.24h1a2.239 2.239 0 00-2.24-2.24v1zm1.239 1.24c0 .62-.462 1.133-1.066 1.218l.139.99a2.233 2.233 0 001.927-2.209h-1zm-1.786-5.147a.643.643 0 01-.643-.643h-1c0 .907.736 1.643 1.643 1.643v-1zm.643-.643a.643.643 0 01-.643.643v1c.908 0 1.643-.736 1.643-1.643h-1zm-.643-.643c.355 0 .643.288.643.643h1c0-.907-.735-1.643-1.643-1.643v1zm0-1c-.907 0-1.643.736-1.643 1.643h1c0-.355.288-.643.643-.643v-1zm-8.571 9h1.143v-1H9.143v1zm4.571 0h1.143v-1h-1.143v1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgReddit;
