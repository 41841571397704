import * as React from "react";

function SvgInbox(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.143 4.5h13.714v-1H5.143v1zm14.357.643v13.714h1V5.143h-1zM18.857 19.5H5.143v1h13.714v-1zM4.5 18.857V5.143h-1v13.714h1zm.643.643a.643.643 0 01-.643-.643h-1c0 .908.736 1.643 1.643 1.643v-1zm14.357-.643a.643.643 0 01-.643.643v1c.908 0 1.643-.735 1.643-1.643h-1zM18.857 4.5c.355 0 .643.288.643.643h1c0-.907-.735-1.643-1.643-1.643v1zm-13.714-1c-.907 0-1.643.736-1.643 1.643h1c0-.355.288-.643.643-.643v-1zM12 17.071c.093 0 .186-.003.278-.01l-.07-.997a2.986 2.986 0 01-.208.007v1zm-.924-.109c.297.072.606.11.924.11v-1a2.94 2.94 0 01-.69-.082l-.234.972zm.234-.972c-1.122-.27-2.199-1.037-3.416-1.823C6.73 13.416 5.43 12.643 4 12.643v1c1.096 0 2.164.598 3.352 1.364 1.134.733 2.392 1.634 3.724 1.955l.234-.972zm.968 1.072c1.563-.11 3.048-1.047 4.385-1.856 1.401-.847 2.653-1.563 3.908-1.563v-1c-1.584 0-3.08.894-4.425 1.708-1.41.852-2.668 1.624-3.938 1.713l.07.998z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgInbox;
