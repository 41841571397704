var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { Badge as MantineBadge } from '@mantine/core';
var Badge = function (_a, ref) {
    var type = _a.type, _b = _a.borderRadius, borderRadius = _b === void 0 ? 44 : _b, title = _a.title, titleColor = _a.titleColor, onClick = _a.onClick, backgroundColor = _a.backgroundColor, borderColor = _a.borderColor, height = _a.height, maxWidth = _a.maxWidth, leftIcon = _a.leftIcon, rightIcon = _a.rightIcon, size = _a.size, fullWidth = _a.fullWidth, fontSize = _a.fontSize, fontWeight = _a.fontWeight, lineHeight = _a.lineHeight, _c = _a.textTransform, textTransform = _c === void 0 ? 'none' : _c, rest = __rest(_a, ["type", "borderRadius", "title", "titleColor", "onClick", "backgroundColor", "borderColor", "height", "maxWidth", "leftIcon", "rightIcon", "size", "fullWidth", "fontSize", "fontWeight", "lineHeight", "textTransform"]);
    return (React.createElement(MantineBadge, __assign({ radius: borderRadius, leftSection: leftIcon, rightSection: rightIcon, variant: type, onClick: onClick, color: titleColor, ref: ref, size: size, fullWidth: fullWidth, styles: function (theme) { return ({
            root: {
                color: titleColor,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                maxWidth: maxWidth,
                height: height,
                fontSize: fontSize,
                fontWeight: fontWeight,
                lineHeight: lineHeight,
                textTransform: textTransform,
            },
        }); } }, rest), title));
};
export default forwardRef(Badge);
