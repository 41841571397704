import * as React from "react";

function SvgCovid(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 5.103V4M11.172 4h1.656M12 18.897V20M12.828 20h-1.655M18.897 12H20M20 11.172v1.655M5.103 12H4M4 12.828v-1.656M8.552 6.027L8 5.072M7.283 5.486l1.434-.828M15.448 17.973l.552.956M16.716 18.515l-1.433.827M17.973 8.552L18.927 8M18.514 7.283l.828 1.434M6.028 15.449L5.072 16M5.486 16.717l-.828-1.433M6.028 8.552L5.072 8M4.658 8.717l.828-1.434M17.973 15.448l.955.552M19.342 15.283l-.828 1.434M15.448 6.028L16 5.072M15.283 4.658l1.434.828M8.552 17.973L8 18.928M8.717 19.342l-1.434-.828"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.897a6.897 6.897 0 100-13.794 6.897 6.897 0 000 13.794zm-.276-6.07a1.103 1.103 0 100-2.206 1.103 1.103 0 000 2.207zm1.104-4.965a1.103 1.103 0 11-2.207 0 1.103 1.103 0 012.207 0zm-.552 9.38a1.655 1.655 0 100-3.311 1.655 1.655 0 000 3.31zM9.11 9.281a.909.909 0 11-.909 1.574.909.909 0 01.909-1.574zm7.51 4.737a.993.993 0 10-1.719-.993.993.993 0 001.72.993zm-8.338-.734a.746.746 0 11.747 1.292.746.746 0 01-.747-1.292zm8.257-3.906a1.38 1.38 0 10-2.389 1.38 1.38 1.38 0 002.39-1.38z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCovid;
