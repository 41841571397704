import { FoodCourtResDto } from '../types/food-court';
import BaseHttpService from './base-http.service';

const PATH = 'food-courts';
class FoodCourtService extends BaseHttpService {
  async getFoodCourtData(id: string): Promise<FoodCourtResDto> {
    const body = await this.get<FoodCourtResDto>(`${PATH}/${id}`);
    return body;
  }
}

export const foodCourtService = new FoodCourtService();
