import * as React from "react";

function SvgSnapchat(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.933 4C10.6 4 9.421 4.507 8.59 5.577c-.742.953-1.162 2.893-1.232 4.512l-.084.013-.025.004a9.51 9.51 0 00-.426.072c-.537.11-1.555.402-1.783 1.573-.107.547.013 1.024.286 1.395.244.33.575.528.818.651.104.052.21.1.316.146-.243.717-.669 1.456-1.14 2.125-.441.627-.325 1.42-.096 1.937.228.518.658.495 1.377.528.408.02.835.064 1.26.148.192.038.419.122.7.248.164.074.327.15.489.23.131.063.271.13.432.205.662.308 1.475.636 2.376.636.9 0 1.713-.328 2.375-.636.161-.075.3-.142.433-.206l.03-.014c.154-.075.3-.145.458-.215.282-.126.508-.209.7-.247.426-.084.853-.13 1.26-.149.72-.033 1.277-.01 1.506-.528.228-.517.345-1.31-.097-1.937-.468-.664-.892-1.398-1.136-2.11.105-.044.228-.096.352-.157.25-.121.6-.322.843-.668.284-.402.365-.91.203-1.458-.323-1.09-1.261-1.381-1.81-1.496a7.527 7.527 0 00-.412-.072l-.014-.003-.065-.01c-.074-1.519-.472-3.44-1.197-4.408C14.47 4.597 13.3 4 11.933 4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSnapchat;
