import * as React from "react";

function SvgGridLayout(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.714 4H5.143C4.512 4 4 4.512 4 5.143v4.571c0 .631.512 1.143 1.143 1.143h4.571c.631 0 1.143-.512 1.143-1.143V5.143c0-.631-.511-1.143-1.143-1.143zM18.857 4h-4.571c-.631 0-1.143.512-1.143 1.143v4.571c0 .631.511 1.143 1.143 1.143h4.571c.631 0 1.143-.512 1.143-1.143V5.143C20 4.512 19.488 4 18.857 4zM18.857 13.143h-4.571c-.631 0-1.143.511-1.143 1.143v4.571c0 .631.511 1.143 1.143 1.143h4.571c.631 0 1.143-.512 1.143-1.143v-4.571c0-.631-.512-1.143-1.143-1.143zM9.714 13.143H5.143c-.631 0-1.143.511-1.143 1.143v4.571C4 19.488 4.512 20 5.143 20h4.571c.631 0 1.143-.512 1.143-1.143v-4.571c0-.631-.511-1.143-1.143-1.143z"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgGridLayout;
