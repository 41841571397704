var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Center, createStyles, Group, rem, UnstyledButton, Text } from '@mantine/core';
import React from 'react';
import { Icon } from '../..';
var useStyles = createStyles(function (theme) { return ({
    th: {
        padding: '0 !important',
    },
    control: {
        position: 'relative',
        width: '100%',
        padding: "".concat(theme.spacing.xs, " ").concat(theme.spacing.md),
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },
    icon: {
        width: rem(21),
        height: rem(21),
        borderRadius: rem(21),
        position: 'absolute',
        top: '50%',
        right: rem(4),
        transform: 'translateY(-50%)',
    },
}); });
export var HeaderCell = function (_a) {
    var children = _a.children, sorted = _a.sorted, sortDirection = _a.sortDirection, onSort = _a.onSort, sortable = _a.sortable, style = _a.style, textAlign = _a.textAlign;
    var classes = useStyles().classes;
    var SortIcon = sorted ? (sortDirection === 'desc' ? (React.createElement(Icon, { name: "SortUp", type: "outline" })) : (React.createElement(Icon, { name: "SortDown", type: "outline" }))) : (React.createElement(Icon, { name: "CaretVerticalSmall", type: "outline" }));
    return (React.createElement("th", { className: classes.th, style: __assign({}, style) },
        React.createElement(UnstyledButton, { onClick: onSort, className: classes.control },
            React.createElement(Group, { position: textAlign !== null && textAlign !== void 0 ? textAlign : 'center' },
                React.createElement(Text, { fw: 500, fz: "sm" }, children),
                sortable && React.createElement(Center, { className: classes.icon }, SortIcon)))));
};
export default HeaderCell;
