/* eslint-disable @typescript-eslint/return-await */
import { notifications } from '@sicpama/core-components/lib/notifications';
import axios, { AxiosError } from 'axios';
import { logger } from 'configs';
import { LOCAL_STORAGE_KEY } from '../constants';
import { fingerPrintService, storeTableService } from './index';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL ?? ''}/v1`,
  timeout: 30000,
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    const sid = localStorage.getItem(LOCAL_STORAGE_KEY.SID)!;
    const guestAccessToken = localStorage.getItem(LOCAL_STORAGE_KEY.GUEST_ACCESS_TOKEN)!;
    if (sid) {
      config.headers.sid = sid;
      config.headers['guest-access-token'] = guestAccessToken;
    }
    return config;
  },
  async (error) => Promise.reject(error),
);

export default class BaseHttpService {
  private readonly BASE_URL = process.env.REACT_APP_API_URL;

  private readonly router: unknown;

  constructor(router?: unknown) {
    this.router = router;
  }

  async get<T>(endpoint: string, options = {}): Promise<T> {
    return instance
      .get(`/${endpoint}`, options)
      .then((result) => {
        return result.data;
      })
      .catch((error) => this.handleHttpError(error));
  }

  async post<T>(endpoint: string, data = {}, options = {}): Promise<T> {
    return instance
      .post(`/${endpoint}`, data, options)
      .then((result) => {
        return result.data;
      })
      .catch((error) => this.handleHttpError(error));
  }

  async put<T>(endpoint: string, data = {}, options = {}): Promise<T> {
    return instance
      .put(`/${endpoint}`, data, options)
      .then((result) => {
        return result.data;
      })
      .catch((error) => this.handleHttpError(error));
  }

  async delete<T>(endpoint: string, options = {}): Promise<T> {
    return instance.delete(`/${endpoint}`, options).catch((error) => this.handleHttpError(error));
  }

  async patch<T>(endpoint: string, data = {}, options = {}): Promise<T> {
    return instance
      .patch(`/${endpoint}`, data, options)
      .catch((error) => this.handleHttpError(error));
  }

  handleHttpError(error: AxiosError<Record<string, any>, unknown>): any {
    logger.error('handleHttpError', {
      error: error?.response,
    });

    const tableToken = localStorage.getItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN);

    // Add this to capture on Posthog
    console.error(JSON.stringify(error));
    console.info(`tableToken: ${tableToken}`);
    console.info(`sessionId: ${localStorage.getItem(LOCAL_STORAGE_KEY.SID)}`);
    console.info(`orderId: ${localStorage.getItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID)}`);

    console.error(JSON.stringify(error?.response));
    console.error(JSON.stringify(error?.response?.data));

    const statusCode = error?.response?.status;
    const displayMessage = error.response?.data?.errorMessage ?? 'unknown error';

    if (!tableToken) {
      window.location.href = `${window.location.origin}/report-error`;
    }

    if (statusCode === 403) {
      // Temporarily comment this code before finding a better solution
      // if (displayMessage.includes('cleared')) {
      //   dinerTableReset();
      // }

      // retry to join table when there is table token in localStorage
      if (tableToken) {
        return fingerPrintService
          .getFingerPrintData()
          .then((res) => {
            const { fingerPrint } = res;
            localStorage.setItem(LOCAL_STORAGE_KEY.FINGERPRINT, fingerPrint);
            storeTableService.joinTable(tableToken, fingerPrint).then((sessionId) => {
              if (sessionId !== null) {
                localStorage.setItem(LOCAL_STORAGE_KEY.SID, sessionId);
                window.location.replace('/auth');
              } else {
                logger.error('Session ID is null, cannot set cookie');
              }
            });
          })
          .catch((err) => {
            logger.error('handleHttpError_403', {
              err,
            });
            window.location.replace(`/?token=${tableToken}`);
          });
      } else {
        window.location.href = `${window.location.origin}/report-error`;
      }
    }
    if (statusCode === 422 && !localStorage.getItem(LOCAL_STORAGE_KEY.ALREADY_CLOSED)) {
      window.location.href = `${window.location.origin}/closed`;
      localStorage.setItem(LOCAL_STORAGE_KEY.ALREADY_CLOSED, 'true');
    }

    if (statusCode !== 422) {
      notifications.show({
        title: 'Sorry For This Inconvenience!',
        message: displayMessage,
        radius: 'md',
        color: 'red',
        autoClose: process.env.REACT_APP_AUTO_CLOSE_ERROR_MS
          ? +process.env.REACT_APP_AUTO_CLOSE_ERROR_MS
          : 9000,
      });
    }

    if (statusCode !== 401) {
      throw new Error(displayMessage);
    } else {
      window.location.href = `${window.location.origin}/auth`;
    }
  }
}
