import * as React from "react";

function SvgMicrophoneOn(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0068 4.37865C10.4065 3.86981 11.0546 3.5 12 3.5C12.9454 3.5 13.5935 3.86981 13.9932 4.37865C14.3752 4.86498 14.5 5.43843 14.5 5.83333V13.1667C14.5 13.5616 14.3752 14.135 13.9932 14.6213C13.5935 15.1302 12.9454 15.5 12 15.5C11.0546 15.5 10.4065 15.1302 10.0068 14.6213C9.6248 14.135 9.5 13.5616 9.5 13.1667V5.83333C9.5 5.43843 9.6248 4.86498 10.0068 4.37865ZM8.5 11V13.1818C8.5 13.7333 8.67761 14.5756 9.19918 15.2671C9.70259 15.9345 10.5534 16.5 12 16.5C13.4466 16.5 14.2974 15.9345 14.8008 15.2671C15.3224 14.5756 15.5 13.7333 15.5 13.1818V11H16.5V13.1818C16.5 13.903 16.2776 14.9698 15.5992 15.8693C14.9711 16.7019 13.9751 17.3588 12.5 17.4799V21H11.5V17.4799C10.0249 17.3588 9.02889 16.7019 8.40082 15.8693C7.72239 14.9698 7.5 13.903 7.5 13.1818V11H8.5Z"
        fill="#444C54"
      />
    </svg>
  );
}

export default SvgMicrophoneOn;
