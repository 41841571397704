import * as React from "react";

function SvgOpenInNewTab(props) {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M18 12V17.25C18 17.6642 17.6642 18 17.25 18H6.75C6.33579 18 6 17.6642 6 17.25V6.75C6 6.33579 6.33579 6 6.75 6H12M13.5 6H18M18 6V10.5M18 6L11.25 12.75" stroke="currentColor"/>
    </svg>
  );
}

export default SvgOpenInNewTab;
