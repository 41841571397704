import * as React from "react";

function SvgAddressBook(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.143 16.571h-.5v.5h.5v-.5zm5.714 0v.5h.5v-.5h-.5zm-5.214 0V16h-1v.571h1zM15.357 16v.571h1V16h-1zm.5.071h-5.714v1h5.714v-1zM13 13.643A2.357 2.357 0 0115.357 16h1A3.357 3.357 0 0013 12.643v1zM10.643 16A2.357 2.357 0 0113 13.643v-1A3.357 3.357 0 009.643 16h1zM13 6.929a2.214 2.214 0 00-2.214 2.214h1c0-.67.543-1.214 1.214-1.214v-1zm2.214 2.214A2.214 2.214 0 0013 6.929v1c.67 0 1.214.543 1.214 1.214h1zM13 11.357a2.214 2.214 0 002.214-2.214h-1c0 .67-.543 1.214-1.214 1.214v1zm0-1c-.67 0-1.214-.544-1.214-1.214h-1c0 1.223.991 2.214 2.214 2.214v-1zM7.857 4.5h10.286v-1H7.857v1zm10.929.643v13.714h1V5.143h-1zM18.143 19.5H7.857v1h10.286v-1zm-10.929-.643V5.143h-1v13.714h1zm.643.643a.643.643 0 01-.643-.643h-1c0 .908.736 1.643 1.643 1.643v-1zm10.929-.643a.643.643 0 01-.643.643v1c.907 0 1.643-.735 1.643-1.643h-1zM18.143 4.5c.355 0 .643.288.643.643h1c0-.907-.736-1.643-1.643-1.643v1zm-10.286-1c-.907 0-1.643.736-1.643 1.643h1c0-.355.288-.643.643-.643v-1zm.572 4.571H5v1h3.429v-1zm0 6.858H5v1h3.429v-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAddressBook;
