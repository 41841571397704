var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Text } from '@mantine/core';
var Typography = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 14 : _b, inline = _a.inline, strikethrough = _a.strikethrough, underline = _a.underline, weight = _a.weight, _c = _a.align, align = _c === void 0 ? 'inherit' : _c, children = _a.children, color = _a.color, variant = _a.variant, href = _a.href, target = _a.target, span = _a.span, lineClamp = _a.lineClamp, italic = _a.italic, truncate = _a.truncate, transform = _a.transform, rest = __rest(_a, ["size", "inline", "strikethrough", "underline", "weight", "align", "children", "color", "variant", "href", "target", "span", "lineClamp", "italic", "truncate", "transform"]);
    var component;
    if (href)
        component = 'a';
    return (React.createElement(Text, __assign({ align: align, size: size, color: color, inline: inline, strikethrough: strikethrough, underline: underline, variant: variant, weight: weight, component: component, href: href, target: target, span: span, lineClamp: lineClamp, italic: italic, truncate: truncate, transform: transform }, rest), children));
};
export default Typography;
