import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const options = {
  // order and from where user language should be detected
  // order: ['querystring', 'localStorage', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  order: ['querystring', 'navigator'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  caches: [],
};
const { language } = navigator;
let selectedLocale = 'en';

// if (language.startsWith('en')) {
//   selectedLocale = 'en';
// }

if (language.startsWith('ko')) {
  selectedLocale = 'kr';
}

if (language.startsWith('kr')) {
  selectedLocale = 'kr';
}

if (language.startsWith('zh-TW')) {
  selectedLocale = 'ch';
}

if (language.startsWith('zh')) {
  selectedLocale = 'ch';
}

if (language.startsWith('zh-CN')) {
  selectedLocale = 'ch';
}

if (language.startsWith('zh-HK')) {
  selectedLocale = 'ch';
}
// const apiKey = process.env.REACT_APP_I18N_KEY ?? '';
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;
i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    // lng: 'kr', // <--- turn off for detection to work
    detection: options,
    supportedLngs: ['en', 'kr'],
    backend: {
      // loadPath,
      loadPath: `/locales/${selectedLocale}/{{ns}}.json`,
    },
    // defaultNS: 'default',
    defaultNS: 'common',
    fallbackLng: ['en_US', 'en', 'ko', 'kr', 'ko-KR', 'ko_KR'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
