import { ReactElement } from 'react';
import { Avatar, Badge } from '@sicpama/core-components';
import { useNavigate } from 'react-router-dom';
import { useOrderStore } from 'stores';
import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';

const MyTopLeftAvatarProfile = (): ReactElement => {
  const { myInfo } = useOrderStore();
  const navigate = useNavigate();

  const isGuestLogin = myInfo?.sicpamaId === null;

  return (
    <div className="my-avatar-container" id={ANALYTICS_CSS_ID().MENU_PAGE.PROFILE_AVATAR}>
      <Badge
        title={myInfo?.nickName || myInfo?.fullName || '.....'}
        height={44}
        leftIcon={
          <div className="-ml-2">
            <Avatar
              items={[
                {
                  src: myInfo?.providerProfilePhoto || '/images/blank-avatar.jpg',
                },
              ]}
              size={44}
            />
          </div>
        }
        backgroundColor="white"
        borderColor={isGuestLogin ? 'red' : 'rgb(206, 212, 218)'}
        titleColor="#868686"
        onClick={() => {
          navigate('/auth');
        }}
      />
    </div>
  );
};

export default MyTopLeftAvatarProfile;
