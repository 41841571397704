import couponService from 'services/coupon.service';
import { create } from 'zustand';
import { ICouponUsage } from '../submodules/sicpama-shared';

interface CouponStore {
  displayAvailableCoupons: boolean;
  availableIds: string[];
  entities: Record<string, ICouponUsage>;
  getAvailableCoupons: (storeId: number, customerId: string) => Promise<ICouponUsage[]>;
  setDisplayAvailableCoupon: (display: boolean) => void;
}

export const useCouponStore = create<CouponStore>((set, get) => ({
  displayAvailableCoupons: false,
  availableIds: [],
  entities: {},
  getAvailableCoupons: async (storeId: number, customerId: string) => {
    const shouldDisplayCoupon = get().availableIds.length <= 0;
    const customerCoupons = await couponService.getAvailableCoupons(storeId, customerId);
    const ids = customerCoupons.map((coupon) => coupon.id);
    const normalizedCoupon: Record<string, ICouponUsage> = customerCoupons.reduce(
      (a, b) => ({
        ...a,
        [b.id]: b,
      }),
      {},
    );
    set({
      availableIds: ids,
      entities: normalizedCoupon,
    });
    if (shouldDisplayCoupon && customerCoupons.length > 0) {
      get().setDisplayAvailableCoupon(true);
    }
    return customerCoupons;
  },
  setDisplayAvailableCoupon: (display: boolean) => {
    set((state) => ({
      ...state,
      displayAvailableCoupons: display,
    }));
  },
}));

const baseSelector = (state: CouponStore): CouponStore => state;
const displayAvailableCoupons = (state: CouponStore): boolean =>
  baseSelector(state).displayAvailableCoupons;
const getAvailableIds = (state: CouponStore): string[] => baseSelector(state).availableIds;
const getCouponById =
  (id: string) =>
  (state: CouponStore): ICouponUsage =>
    baseSelector(state).entities[id];

export const couponSelectors = {
  getAvailableIds,
  getCouponById,
  displayAvailableCoupons,
};
