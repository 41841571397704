interface SpacerProps {
  size?: number;
  column?: boolean;
}

export const Spacer = ({ size = 10, column = false }: SpacerProps): JSX.Element => (
  <div
    style={{
      height: size,
      width: size,
      display: column ? 'inline-block' : 'inherit',
    }}
  />
);
