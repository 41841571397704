import * as React from 'react';

function SvgSales(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="1" y="11.5" width="4" height="5.5" stroke="currentColor" strokeWidth="2" />
      <rect x="8.5" y="7.75" width="4" height="9.25" stroke="currentColor" strokeWidth="2" />
      <rect x="16" y="1" width="4" height="16" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
}

export default SvgSales;
