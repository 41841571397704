import React, { ReactElement } from 'react';

export const MenuSkeletonLoader = (): ReactElement => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header */}
      <div className="flex justify-between items-center p-4 bg-white shadow-sm">
        <div className="w-24 h-6 bg-gray-200 rounded"></div>
        <div className="flex space-x-4">
          <div className="w-6 h-6 bg-gray-200 rounded-full"></div>
          <div className="w-6 h-6 bg-gray-200 rounded-full"></div>
          <div className="w-6 h-6 bg-gray-200 rounded-full"></div>
        </div>
      </div>

      {/* Background Image Placeholder */}
      <div className="h-40 bg-gray-300 animate-pulse"></div>

      {/* Store Info */}
      <div className="bg-white -mt-10 mx-4 p-4 rounded-lg shadow">
        <div className="flex items-center space-x-4">
          <div className="w-16 h-16 bg-gray-200 rounded-full"></div>
          <div>
            <div className="w-32 h-6 bg-gray-200 rounded mb-2"></div>
            <div className="w-24 h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>

      {/* Category Carousel */}
      <div className="flex space-x-4 p-4 overflow-x-auto">
        {[...Array(5)].map((_, i) => (
          <div key={i} className="flex-shrink-0 w-20 h-8 bg-gray-200 rounded"></div>
        ))}
      </div>

      {/* Menu Items */}
      <div className="p-4">
        <div className="w-32 h-6 bg-gray-200 rounded mb-4"></div>
        {[...Array(3)].map((_, i) => (
          <div key={i} className="flex items-center space-x-4 mb-4">
            <div className="w-20 h-20 bg-gray-200 rounded"></div>
            <div className="flex-1">
              <div className="w-3/4 h-4 bg-gray-200 rounded mb-2"></div>
              <div className="w-1/2 h-4 bg-gray-200 rounded"></div>
            </div>
            <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
          </div>
        ))}
      </div>

      {/* Basket */}
      <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t">
        <div className="w-full h-12 bg-gray-200 rounded"></div>
      </div>
    </div>
  );
};
