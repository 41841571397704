/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const ANALYTICS_CSS_ID = (id?: string | number) => ({
  // menus
  LOGIN: 'login_btn',
  SEARCH_MENU: {
    INPUT: 'search_menu_input',
    BUTTON: 'search_menu_btn',
  },
  CATEGORY: `btn_category_${id}`,
  EVENT_MENU: `btn_carousel_menu_${id}`,
  MENU: `btn_to_menu_detail_page_${id}`,
  QUICK_ADD_MENU: `quick_add_menu_${id}`,

  // tab
  PAY_MINE: 'pay_mine',
  ON_ME: 'pay_its_on_me',
  SPIN_WHEEL: 'pay_spin_wheel',
  SPLIT_EVENLY: 'pay_split_evenly',
  NOT_SELECTED: 'not_selected',
  SHARABLE_TAB: 'sharable_tab',
  TAB_CHG_AMT: 'tab_chg_amt',
  TAB_OTHER_TG: `tab_other_tg_${id}`,
  SELECT_DS_BTN: 'btn_to_menu_page_from_tab',
  GO_TO_PAY_BT: 'btn_to_payment_page',

  // payment screen
  KAKAO_PAY: 'btn_to_kakao_pay',
  NAVER_PAY: 'btn_to_naver_pay',
  SAM_PAY: 'btn_to_sam_pay',
  CARD_PAY: 'btn_to_normal_pay',
  FINAL_PAY_BTN: 'btn_to_make_payments',
  PAY_IN_STORE: 'pay_in_store',
  PAYPAL: 'btn_to_paypal',

  PROGRESS_BAR: 'banner_progress',
  AVATAR_INSIDE_PROGRESS_BAR: 'banner_profile_in_progress',

  // done screen
  ADD_ORDER_BT: 'add_order_bt',

  MENU_PAGE: {
    FOOD_COURT_HOME_PAGE_BTN: 'btn_to_landing_page',
    PROFILE_AVATAR: 'banner_profile',
    SEARCH_INPUT: 'btn_to_search',
    CAROUSEL_MENU_ITEM: `btn_carousel_menu_${id}`,
    NOTIFICATION_BANNER: 'banner_notification_banner',
    GO_TO_TAB_PAGE_BTN: 'btn_to_tab_page_from_menu',
  },
  MENU_DETAIL_PAGE: {
    GO_BACK_TO_MENU_PAGE: 'btn_to_menu_page_from_detail',
    MENU_BANNER_IMAGE: 'banner_menu_image',
    PLUS_QUANTITY_BTN: 'btn_to_add_menu_items',
    MINUS_QUANTITY_BTN: 'btn_to_reduce_menu_items',
    QUANTITY_NUMBER: 'banner_number_of_menu_item',
    SELECT_MENU_OPTION_BTN: 'btn_to_select_menu_option',
    IS_REQUIRED_OR_OPTIONAL_MENU_OPTION: 'banner_display_necessary',
    ADD_MENU_TO_CART_BTN: 'btn_to_put_menu',
  },

  TAB_PAGE: {
    BANNER_TAB_PROFILE: 'banner_tab_profile',
    BANNER_TAB_OTHER_PROFILE: 'banner_tab_other_profile',
    CHANGE_MENU_OPTION_BTN: 'btn_to_change_menu_option',
    DELETE_MENU_BTN: 'btn_to_delete_menu_item',
    PLUS_QUANTITY_BTN: 'btn_to_add_menu_items_in_tab',
    MINUS_QUANTITY_BTN: 'btn_to_reduce_menu_items_in_tab',
    EXPAND_OTHER_MENU_ITEMS: 'btn_to_display_other_menu_items',
    BANNER_MY_COUPON: 'banner_my_coupon',
    SELECT_PAYMENT_OPTION: 'banner_to_guide_sorting_bills',
  },

  PAYMENT_PAGE: {
    RETURN_TAB_BTN_TOP: 'btn_to_tab_page_from_payment_top',
    RETURN_TAB_BTN_BOTTOM: 'btn_to_tab_page_from_payment_bottom',
    BANNER_PAYMENT_INFORMATION: 'banner_payment_information',
    INPUT_MOBILE_NUMBER: 'ipt_mobile_number',
    AGREE_T_AND_C_BTN: 'btn_agree_t&c',
  },
});
