var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { MultiSelect as MantineMultiSelect } from '@mantine/core';
import React, { forwardRef } from 'react';
var MultiSelect = function (_a, ref) {
    var data = _a.data, value = _a.value, onChange = _a.onChange, onCreate = _a.onCreate, onSearchChange = _a.onSearchChange, label = _a.label, placeholder = _a.placeholder, searchable = _a.searchable, creatable = _a.creatable, clearable = _a.clearable, searchValue = _a.searchValue, notFoundComponent = _a.notFoundComponent, dropdownComponent = _a.dropdownComponent, dropdownPosition = _a.dropdownPosition, leftIcon = _a.leftIcon, rightIcon = _a.rightIcon, withAsterisk = _a.withAsterisk, error = _a.error, readOnly = _a.readOnly, disabled = _a.disabled, size = _a.size, borderRadius = _a.borderRadius, defaultOpened = _a.defaultOpened, styles = _a.styles, style = _a.style, sx = _a.sx, renderCreateLabel = _a.renderCreateLabel, rest = __rest(_a, ["data", "value", "onChange", "onCreate", "onSearchChange", "label", "placeholder", "searchable", "creatable", "clearable", "searchValue", "notFoundComponent", "dropdownComponent", "dropdownPosition", "leftIcon", "rightIcon", "withAsterisk", "error", "readOnly", "disabled", "size", "borderRadius", "defaultOpened", "styles", "style", "sx", "renderCreateLabel"]);
    return (React.createElement(MantineMultiSelect, __assign({ data: data, value: value, placeholder: placeholder, searchable: searchable, creatable: creatable, clearable: clearable, onChange: onChange, label: label, searchValue: searchValue, onSearchChange: onSearchChange, onCreate: onCreate, getCreateLabel: renderCreateLabel, nothingFound: notFoundComponent, dropdownComponent: dropdownComponent, dropdownPosition: dropdownPosition, withAsterisk: withAsterisk, error: error, icon: leftIcon, rightSection: rightIcon, readOnly: readOnly, disabled: disabled, size: size, radius: borderRadius, initiallyOpened: defaultOpened, styles: styles, sx: sx, ref: ref, style: style }, rest)));
};
export default forwardRef(MultiSelect);
