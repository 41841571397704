import * as React from "react";

function SvgCog(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.428 12c0-.882.704-1.6 1.57-1.6.868 0 1.572.718 1.572 1.6 0 .883-.704 1.6-1.571 1.6s-1.57-.717-1.57-1.6z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.94 4h4.117l.175.892v.004l.287 1.424c.41.184.789.412 1.14.67l1.434-.49.85-.268.444.776 1.16 1.978.453.793-.676.6-1.075.933c.034.209.065.445.065.688 0 .243-.031.48-.065.688l1.08.937.671.596-.456.798-1.157 1.973-.444.778-.861-.274-1.423-.485c-.351.258-.73.485-1.14.669l-.287 1.424v.004l-.175.892H9.94l-.173-.895-.288-1.425a6.27 6.27 0 01-1.14-.67l-1.433.49-.852.27-.442-.778-1.16-1.979L4 14.223l.674-.6 1.076-.934A4.347 4.347 0 015.684 12c0-.242.032-.48.066-.688l-1.082-.938L4 9.78l.455-.798 1.157-1.973.442-.776.862.272 1.423.485c.351-.258.73-.485 1.14-.67l.288-1.425L9.94 4zm2.059 5.333c-1.445 0-2.618 1.194-2.618 2.667 0 1.473 1.173 2.667 2.618 2.667 1.445 0 2.618-1.194 2.618-2.667 0-1.473-1.173-2.667-2.618-2.667z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCog;
