import React from 'react';
import { Tabs as MantineTabs } from '@mantine/core';
var Tab = MantineTabs.Tab, List = MantineTabs.List, Panel = MantineTabs.Panel;
var Tabs = function (_a) {
    var value = _a.value, defaultValue = _a.defaultValue, keepMounted = _a.keepMounted, arrowKeyLoop = _a.arrowKeyLoop, onTabChange = _a.onTabChange, _b = _a.orientation, orientation = _b === void 0 ? 'horizontal' : _b, placement = _a.placement, borderRadius = _a.borderRadius, items = _a.items, inverted = _a.inverted, style = _a.style;
    return (React.createElement(MantineTabs, { value: value, defaultValue: defaultValue, onTabChange: onTabChange, style: style, keepMounted: keepMounted, loop: arrowKeyLoop, orientation: orientation, placement: placement, radius: borderRadius, inverted: inverted },
        React.createElement(List, null, items.map(function (item) { return (React.createElement(Tab, { key: item.key, value: item.value, color: item.color, icon: item.leftIcon, rightSection: item.rightIcon }, item.title)); })),
        items.map(function (item) { return (React.createElement(Panel, { key: item.key, value: item.value, pt: item.paddingTop || 'xl' }, item.children)); })));
};
export default React.memo(Tabs);
