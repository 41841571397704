import { logger } from 'configs';
import { UpdateCustomerInformationReqDto } from '../types';
import { LOCAL_STORAGE_KEY } from '../constants';
import BaseHttpService from './base-http.service';

import { storeTableService } from './store-table.serivce';
import { fingerPrintService } from './fingerprint.service';

const PATH = 'customers';

class CustomerService extends BaseHttpService {
  async postLogin(): Promise<void> {
    const orderId = localStorage.getItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID);
    const token = localStorage.getItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN);
    let fingerPrint = localStorage.getItem(LOCAL_STORAGE_KEY.FINGERPRINT);

    if (!fingerPrint) {
      const fingerPrintResult = await fingerPrintService.getFingerPrintData();
      fingerPrint = fingerPrintResult.fingerPrint;
    }

    const sessionId = localStorage.getItem(LOCAL_STORAGE_KEY.SID);

    logger.info('postLogin_data: ', {
      orderId,
      tableToken: token,
      sessionId,
      fingerPrint,
    });

    if (!orderId && token) {
      await storeTableService.joinTable(token, fingerPrint);
    }

    await this.post(
      `${PATH}/post-login`,
      {},
      {
        headers: {
          'finger-print': fingerPrint,
          'order-id': orderId,
          sid: sessionId,
          'store-table-token': token,
        },
      },
    );
  }

  async updateInformation(params: UpdateCustomerInformationReqDto): Promise<void> {
    await this.put(`${PATH}/information`, params);
  }
}

export const customerService = new CustomerService();
