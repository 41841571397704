import styled from '@emotion/styled';
import { Typography } from '@sicpama/core-components';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import { ReactElement, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuthState } from '../../stores';

const translationPrefix = 'pages.report-error';
const StyledPage = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  --webkit-min-height: 100dvh;
  --webkit-height: 100dvh;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const ReportError = (): ReactElement => {
  const { t } = useTranslation();
  const { tableToken } = useAuthState();
  const navigate = useNavigate();

  const localStorageToken = localStorage.getItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN);

  console.info('localStorageToken: ', localStorageToken);
  console.info('zustandToken: ', tableToken);

  if (localStorageToken) {
    console.info('Redirecting to home page with token from local storage');
    navigate(`/?token=${localStorageToken}`);
  }

  useEffect(() => {
    // If token is still in browser and occasionally the app redirect to error-page, begin the flow again
    if (tableToken) {
      console.info('Redirecting to home page with token from zustand');
      navigate(`/?token=${tableToken}`);
    }
  }, [tableToken, navigate]);

  return (
    <StyledPage>
      <img src="/images/error.png" alt="Error" width={176} height={197} />
      <div></div>
      <Typography size={'xl'} weight={700}>
        <Trans i18nKey={`${translationPrefix}.part1`} t={t} />
      </Typography>
      <Trans i18nKey={`${translationPrefix}.part2`} t={t} />
    </StyledPage>
  );
};

export default ReportError;
