import CryptoJS from 'crypto-js';
import { COUNTRY_CODE, COUNTRY_PAYMENT_GATEWAY, STORE_ATTRIBUTE_NAME } from '../constants';
import { useThemeStore } from '../stores';
import { logger } from '../configs';
import { PaymentGatewayType, PGConfiguration, PGContractType } from '../submodules/sicpama-shared';

export const aes256Encrypt = (key: string, text: string): string => {
  const cipher = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return cipher.toString();
};

export const aes256Decrypt = (key: string, encrypted: string): string => {
  const cipher = CryptoJS.AES.decrypt(encrypted, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return cipher.toString(CryptoJS.enc.Utf8);
};

export const getPaymentGatewayType = (countryCode: string): PaymentGatewayType => {
  const koreanPaymentGatewayEnv = process.env.REACT_APP_KOREAN_PAYMENT_GATEWAY;

  const { store } = useThemeStore();

  const paymentGateway = store.attributes?.find(
    (attribute) => attribute.name === STORE_ATTRIBUTE_NAME.PAYMENT_GATEWAY,
  )?.value;

  // IF paymentGateway is configured at BE -> use it
  if (
    paymentGateway &&
    [
      PaymentGatewayType.KICC.toString(),
      PaymentGatewayType.ONE_PG.toString(),
      PaymentGatewayType.ADYEN.toString(),
      PaymentGatewayType.STRIPE.toString(),
      PaymentGatewayType.TOSS.toString(),
    ].includes(paymentGateway)
  ) {
    return paymentGateway as PaymentGatewayType;
  }

  // IF paymentGateway is not configured at BE -> use koreanPaymentGatewayEnv
  if (
    countryCode === COUNTRY_CODE.KR &&
    koreanPaymentGatewayEnv &&
    [PaymentGatewayType.KICC.toString(), PaymentGatewayType.ONE_PG.toString()].includes(
      koreanPaymentGatewayEnv,
    )
  ) {
    return koreanPaymentGatewayEnv as PaymentGatewayType;
  }

  // IF koreanPaymentGatewayEnv is not configured at FE -> use COUNTRY_PAYMENT_GATEWAY[countryCode]
  return COUNTRY_PAYMENT_GATEWAY[countryCode];
};

export const encrypt = (input: {
  text: string;
  secretKey: string;
  iv: string;
  algorithm?: string;
}): string => {
  const { text, secretKey, iv } = input;
  if (!secretKey || !iv) {
    logger.error('Secret key or iv is not provided');
  }

  if (!text) {
    return '';
  }

  const keyWordArray = CryptoJS.enc.Utf8.parse(secretKey);
  const ivWordArray = CryptoJS.enc.Utf8.parse(iv);
  const cipher = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), keyWordArray, {
    iv: ivWordArray,
    mode: CryptoJS.mode.CBC,
  });
  return cipher.toString();
};

export const decrypt = (input: {
  encryptedText: string;
  secretKey: string;
  iv: string;
  algorithm?: string;
}): string => {
  const { encryptedText, secretKey, iv } = input;

  if (!secretKey || !iv) {
    logger.error('Secret key or iv is not provided');
  }

  if (!encryptedText) {
    return '';
  }

  const ivWordArray = CryptoJS.enc.Utf8.parse(iv);
  const keyWordArray = CryptoJS.enc.Utf8.parse(secretKey);
  const decrypted = CryptoJS.AES.decrypt(encryptedText, keyWordArray, {
    iv: ivWordArray,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const result = decrypted.toString(CryptoJS.enc.Utf8);

  return result;
};

export const bulkCryptoPGConfiguration = (input: {
  pgConfiguration: PGConfiguration;
  cryptoAction: (text: string) => string;
}): PGConfiguration => {
  const { pgConfiguration, cryptoAction } = input;
  const result: PGConfiguration = {
    contractType: PGContractType.MANAGED_BY_SICPAMA,
    merchantId: '',
    secretKey: '',
  };

  for (const key in pgConfiguration) {
    if (key === 'contractType') {
      result[key] = pgConfiguration[key];
    } else if (isPGConfigurationKey(key)) {
      result[key] = cryptoAction(pgConfiguration[key] ?? '');
    }
  }

  return result;
};

export type PGConfigurationKeys = keyof {
  merchantId: string;
  billingKey?: string;
  secretKey: string;
  authTail?: string;
  webhookKey?: string;
  idempotencyKey?: string;
  publicKey?: string;
  widgetSecretKey?: string;
  widgetClientKey?: string;
  tossWidgetVariantKey?: string;
  tossAgreementVariantKey?: string;
};

const pgConfigurationKeys = [
  'merchantId',
  'billingKey',
  'secretKey',
  'authTail',
  'webhookKey',
  'idempotencyKey',
  'publicKey',
  'widgetSecretKey',
  'widgetClientKey',
  'tossWidgetVariantKey',
  'tossAgreementVariantKey',
];

export const isPGConfigurationKey = (key: string): key is PGConfigurationKeys => {
  return pgConfigurationKeys.includes(key);
};
