import * as React from "react";

function SvgCamera(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.13333 5H11.4667V6.07692L6.13333 6.07692V5Z" fill="#444C54" />
      <path d="M12.5333 13.0769C12.5333 12.1848 13.2497 11.4615 14.1333 11.4615C15.017 11.4615 15.7333 12.1848 15.7333 13.0769C15.7333 13.9691 15.017 14.6923 14.1333 14.6923C13.2497 14.6923 12.5333 13.9691 12.5333 13.0769Z" fill="#444C54" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4 17.3846C4 18.2768 4.71635 19 5.6 19L18.4 19C19.2837 19 20 18.2768 20 17.3846V8.76923C20 7.87708 19.2837 7.15385 18.4 7.15385H5.6C4.71635 7.15385 4 7.87708 4 8.76923V17.3846ZM14.1333 10.3846C12.6606 10.3846 11.4667 11.59 11.4667 13.0769C11.4667 14.5638 12.6606 15.7692 14.1333 15.7692C15.6061 15.7692 16.8 14.5638 16.8 13.0769C16.8 11.59 15.6061 10.3846 14.1333 10.3846Z" fill="#444C54" />
    </svg>
  );
}

export default SvgCamera;
