import * as React from "react";

function SvgHome1(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.8 3.8l.325-.38a.5.5 0 00-.65 0l.325.38zm-8 6.857l-.325-.38-.175.15v.23h.5zM9.514 19.8v.5a.5.5 0 00.5-.5h-.5zm4.572 0h-.5a.5.5 0 00.5.5v-.5zm5.714-9.143h.5v-.23l-.175-.15-.325.38zM4.943 20.3h4.571v-1H4.943v1zm15.182-10.023l-8-6.857-.65.76 8 6.857.65-.76zm-8.65-6.857l-8 6.857.65.76 8-6.857-.65-.76zm-1.46 16.38v-3.429h-1V19.8h1zm3.57-3.429V19.8h1v-3.429h-1zm.5 3.929h4.572v-1h-4.571v1zm6.215-1.643v-8h-1v8h1zm-17-8v8h1v-8h-1zm8.5 3.929c.986 0 1.786.8 1.786 1.785h1a2.786 2.786 0 00-2.786-2.785v1zm0-1a2.786 2.786 0 00-2.786 2.785h1c0-.986.8-1.785 1.786-1.785v-1zm6.857 6.714c.907 0 1.643-.736 1.643-1.643h-1a.643.643 0 01-.643.643v1zm-13.714-1a.643.643 0 01-.643-.643h-1c0 .907.736 1.643 1.643 1.643v-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHome1;
