export const ROUTE = {
  PAYMENT: '/payment',
  COMPLETE_ORDER: '/customer-receipt',
  TAB: '/tab',
  SEARCH: '/search',
  HOME: '/menus',
  UNSUPPORTED_DEVICE: '/unsupported-device',
  COUPON_PICKUP: '/coupon-pickup',
  LOGIN: '/auth',
  SPIN_THE_WHEEL: '/spin-the-wheel',
};

export const ALLOWED_BYPASS_PATH_ROUTE_NAMES = [
  '/feedback',
  '/report-error',
  '/payments/receipt',
  '/closed',
  ROUTE.COUPON_PICKUP,
];
