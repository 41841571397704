import * as React from "react";

function SvgCar(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.667 10.662h.5v-.138l-.072-.12-.428.258zM3 10.662l-.429-.257-.071.119v.138H3zm2.835-4.721l-.43-.257.43.257zm12.997 0l.429-.257-.429.257zM7 17.333v-.5h-.5v.5H7zm10.667 0h.5v-.5h-.5v.5zm3.5-6.67v8.004h1v-8.005h-1zm-17.738.257l2.834-4.722-.857-.514-2.835 4.72.858.516zM9.264 4.5h6.139v-1H9.264v1zm9.14 1.698l2.834 4.722.857-.515-2.834-4.721-.858.514zM5.666 19.5H4.333v1h1.334v-1zm.833-2.167v1.334h1v-1.334h-1zM20.333 19.5H19v1h1.333v-1zm-2.166-.833v-1.334h-1v1.334h1zm-.5-1.834H7v1h10.667v-1zM3.5 18.667v-8.005h-1v8.005h1zM19 19.5a.833.833 0 01-.833-.833h-1c0 1.012.82 1.833 1.833 1.833v-1zm-13.333 1c1.012 0 1.833-.82 1.833-1.833h-1c0 .46-.373.833-.833.833v1zm-1.334-1a.833.833 0 01-.833-.833h-1c0 1.012.82 1.833 1.833 1.833v-1zm11.07-15a3.5 3.5 0 013 1.698l.858-.514A4.5 4.5 0 0015.403 3.5v1zm-9.14 1.698A3.5 3.5 0 019.264 4.5v-1a4.5 4.5 0 00-3.858 2.184l.857.514zm14.904 12.469c0 .46-.373.833-.834.833v1c1.013 0 1.834-.82 1.834-1.833h-1zM5 15.167h4v-1H5v1zm14.667-1h-4v1h4v-1zm-13.334-3h12v-1h-12v1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCar;
