import * as React from "react";

function SvgYoutube(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.27 17.686l-.105.489.105-.49zm13.46 0l.105.489-.105-.49zm0-11.973l.105-.489-.105.489zm-13.46 0l.104.489-.104-.489zm5.587 3.7l.277-.415a.5.5 0 00-.777.416h.5zm0 4.572h-.5a.5.5 0 00.777.416l-.277-.416zm3.429-2.286l.277.416a.5.5 0 000-.832l-.277.416zM3.5 7.283v8.832h1V7.283h-1zm17 8.832V7.283h-1v8.832h1zm-15.335 2.06c4.505.965 9.165.965 13.67 0l-.21-.978a31.622 31.622 0 01-13.25 0l-.21.978zm13.67-12.95a32.622 32.622 0 00-13.67 0l.21.977a31.622 31.622 0 0113.25 0l.21-.978zM20.5 7.282c0-.993-.694-1.85-1.665-2.059l-.21.978c.51.11.875.56.875 1.081h1zm-1 8.832c0 .522-.364.973-.874 1.082l.21.978a2.106 2.106 0 001.664-2.06h-1zm-16 0c0 .993.694 1.852 1.665 2.06l.21-.978c-.51-.11-.875-.56-.875-1.082h-1zm1-8.832c0-.521.364-.972.874-1.081l-.21-.978A2.106 2.106 0 003.5 7.284h1zm5.857 2.13v4.572h1V9.414h-1zm.777 4.988l3.43-2.286-.556-.832-3.428 2.286.554.832zm3.43-3.118l-3.43-2.285-.554.832 3.428 2.285.555-.832z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgYoutube;
