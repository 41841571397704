export var getPropByPath = function (object, path, defaultValue) {
    if (object === null || object === undefined)
        return defaultValue;
    if (typeof object === 'object') {
        var _path = Array.isArray(path) ? path : path.split('.');
        if (_path.length) {
            return getPropByPath(object[_path.shift()], _path, defaultValue);
        }
    }
    return object;
};
export default getPropByPath;
