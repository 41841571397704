import * as React from "react";

function SvgDribbble(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.313 4.898A8.009 8.009 0 004.1 10.741h.463c2.993-.07 5.338-.329 7.253-.915-.832-1.475-1.847-2.923-3.065-4.394L8.75 5.43l-.436-.532zM4.002 11.808a7.973 7.973 0 002.228 5.734l.173-.345a.53.53 0 01.031-.055c1.768-2.69 3.77-4.29 6.007-5.009.152-.049.305-.093.459-.134-.178-.409-.37-.814-.573-1.216-2.096.675-4.615.953-7.747 1.024h-.578z"
        fill="currentColor"
      />
      <path
        d="M7.052 18.286A7.966 7.966 0 0012 20c.952 0 1.866-.166 2.713-.472v-.768c-.26-2.064-.718-3.958-1.406-5.764a8.058 8.058 0 00-.54.153c-1.944.624-3.76 2.03-5.424 4.552l-.291.585zM15.78 19.053a8.013 8.013 0 004.054-5.425l-.602-.272c-1.735-.51-3.35-.72-4.857-.55.681 1.836 1.138 3.762 1.4 5.854a.525.525 0 01.005.066v.327zM19.983 12.525a7.969 7.969 0 00-1.925-5.75l-.317.351c-1.247 1.519-2.673 2.57-4.405 3.288.226.452.437.908.632 1.37 1.784-.256 3.658-.016 5.602.56a.52.52 0 01.068.025l.345.156zM17.308 6.014A7.97 7.97 0 0012 4c-.936 0-1.835.16-2.67.456l.243.297c1.3 1.571 2.381 3.124 3.262 4.713 1.646-.665 2.95-1.637 4.09-3.026a.65.65 0 01.016-.02l.367-.406z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDribbble;
