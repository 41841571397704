import { Avatar, Icon, Typography } from '@sicpama/core-components';
import clsx from 'clsx';
import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';
import type { ReactElement } from 'react';
import { useMemo } from 'react';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from 'utils/numberLocaleFormat';
import { useOrderStore, useThemeStore } from '../../stores';
import { useTranslation } from 'react-i18next';
import MultiLinesText from 'components/multi-lines-text';
import { IBaseMenuResDto, OrderStatus } from '../../submodules/sicpama-shared';

const translationPrefix = 'menu';

interface Props {
  menu: IBaseMenuResDto;
  onMenuClick: () => void;
  addToTab: (menuId: number, menuName: string) => Promise<void>;
  isLoading?: boolean;
}

const MenuRow = ({ menu, onMenuClick, addToTab, isLoading }: Props): ReactElement => {
  const { tableOrders } = useOrderStore();
  const { t } = useTranslation();

  const {
    theme: { color: themeColor },
  } = useThemeStore();

  const orderedBy = useMemo(() => {
    const ordersWithThisMenu = tableOrders
      .filter((item) => item.status === OrderStatus.DRAFT || item.status === OrderStatus.WAITING)
      .filter((order) => {
        const orderItem = order.orderItems?.find((item) => item.menu.id === menu.id);
        return !!orderItem;
      });
    const orderByCustomerIds = [...new Set(ordersWithThisMenu.map((order) => order.customer.id))];
    return orderByCustomerIds.map((id) => {
      const order = ordersWithThisMenu.find((order) => order.customer.id === id);
      return order?.customer;
    });
  }, [tableOrders, menu]);

  return (
    <div
      className="flex items-center"
      onClick={onMenuClick}
      id={`${ANALYTICS_CSS_ID(menu?.id).MENU}`}
    >
      <div className="flex items-center">
        <div className="relative text-center text-white shrink-0">
          <img
            src={menu.photo ?? 'images/no-image.png'}
            className={clsx(
              'w-[120px] h-[120px] object-cover shrink-0 rounded-lg border',
              !menu.photo ? 'border border-[#E9EBF0]' : '',
            )}
            style={menu.isAvailable ? {} : { filter: 'brightness(40%)' }}
          />
          {!menu.isAvailable && (
            <div className="absolute left-6 top-1/3">
              <Typography color="white" size={18}>
                {t(`${translationPrefix}.soldOut`)}
              </Typography>
            </div>
          )}
        </div>

        <div className="ml-6 flex flex-col text-[#28292B]">
          {!menu.isAvailable && (
            <div
              className="flex items-center justify-start"
              style={{ color: '#db5358', fontSize: '14px' }}
            >
              <Icon size={32} name="ExclamationCircle" color={themeColor} />
              {t(`${translationPrefix}.soldOut`)}
            </div>
          )}
          <div className="font-bold">
            <MultiLinesText text={menu.name} />
          </div>
          <div>
            <span className={`${menu.isTodayDiscounted ? 'line-through text-[#868686]' : ''}`}>
              {numberLocaleFormat(+menu.price, CURRENCY_TO_LOCALE_PRICE[menu.currency])}
            </span>{' '}
            {menu.isTodayDiscounted && menu.discountPrice && (
              <span className="font-bold text-theme">
                {numberLocaleFormat(+menu.discountPrice, CURRENCY_TO_LOCALE_PRICE[menu.currency])}
              </span>
            )}
          </div>
          <div className="">
            <Avatar
              items={orderedBy.map((customer) => ({
                src: customer?.providerProfilePhoto || '/images/blank-avatar.jpg',
              }))}
              size={30}
              threshold={4}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuRow;
