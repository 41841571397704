import * as React from "react";

function SvgDiscord(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.714 12.1c0-.57.409-.95.826-.95.425 0 .835.387.826.94v.01c0 .57-.409.949-.826.949-.407 0-.826-.383-.826-.95zM13.714 12.1c0-.302.092-.512.207-.64.111-.122.277-.207.517-.207.418 0 .826.379.826.949s-.408.949-.826.949c-.17 0-.337-.083-.48-.271a1.316 1.316 0 01-.244-.78z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.053 16.808c.296.116.622.232.98.34a55.424 55.424 0 01-1.604 1.566C4.4 18.62 4 16.14 4 16.14c0-4.41 1.973-9.7 1.973-9.7C7.945 4.958 9.822 5 9.822 5l.174 1.169c-.91.242-1.841.641-2.841 1.187l.547 1.003c1.65-.9 2.992-1.32 4.3-1.32 1.309 0 2.65.418 4.295 1.32l.55-1.003c-1.05-.575-2.023-.987-2.975-1.224L14.178 5s1.877-.041 3.85 1.439c0 0 1.972 5.29 1.972 9.7 0 0-.401 2.48-3.429 2.575 0 0-.91-.897-1.512-1.544.316-.097.636-.213.96-.349a8.4 8.4 0 001.679-.862l-.629-.955a7.263 7.263 0 01-1.46.75l-.007.003-.005.002-.01.004a8.44 8.44 0 01-1.861.553l-.01.001a9.025 9.025 0 01-3.335-.013 10.938 10.938 0 01-1.912-.56c-.538-.21-.998-.433-1.443-.744l-.655.936c.544.38 1.093.642 1.681.872zm1.487-6.8c-1.144 0-1.969.99-1.969 2.092 0 1.105.842 2.092 1.969 2.092 1.143 0 1.966-.988 1.969-2.087.016-1.116-.834-2.097-1.969-2.097zm4.898.102c-.54 0-1.022.206-1.364.584-.337.373-.503.872-.503 1.406 0 .532.167 1.06.476 1.47.313.413.794.724 1.391.724 1.144 0 1.969-.991 1.969-2.092s-.825-2.092-1.969-2.092z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDiscord;
