import { PaymentRequestDtoType } from 'factories/payment/payment-methods';
import { useEffect, type ReactElement } from 'react';
import { PaymentGatewayRequestOptions } from 'types';

interface OnePGPaymentRequestInputFormProps {
  data: PaymentRequestDtoType | Record<string, unknown>;
  options: PaymentGatewayRequestOptions;
  isOnSubmit: boolean;
}

export const OnePGPaymentRequestInputForm = ({
  data,
  options,
  isOnSubmit,
}: OnePGPaymentRequestInputFormProps): ReactElement => {
  useEffect(() => {
    if (isOnSubmit) {
      document.forms.namedItem(options.name)?.submit();
    }
  }, [isOnSubmit]);

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    window.open(options.action, options.name, 'status=0,title=0,height=600,width=800,scrollbars=1');
  };

  return (
    <form
      name={options.name}
      method={options.method}
      action={options.action}
      acceptCharset={options.charSet}
      onSubmit={(e) => {
        onSubmitHandler(e);
      }}
    >
      {Object.entries(data).map(([key, value]) => {
        return <input key={key} type={options.displayType} name={key} value={value} />;
      })}
    </form>
  );
};
