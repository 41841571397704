import { Carousel } from '@sicpama/core-components';
import { useCallback } from 'react';
import MenuItemCarousel from './MenuItemCarousel';

interface MenuCarouselProps {
  readonly onClick: (id: number) => void;
  readonly itemsIds: number[];
}

export default function MenuCarousel({ onClick, itemsIds }: MenuCarouselProps): JSX.Element {
  const handleClick = useCallback(
    (id: number) => () => {
      onClick(id);
    },
    [onClick],
  );

  const renderItem = useCallback(
    (id: number) => <MenuItemCarousel id={id} onClick={handleClick(id)} />,
    [handleClick],
  );

  if (!itemsIds.length) return <></>;

  return (
    <div className="pl-4 w-full py-4">
      <Carousel
        items={itemsIds.map((item) => renderItem(item))}
        slideSize="60%"
        slideGap="16px"
        align="start"
        withControls={false}
      />
    </div>
  );
}
