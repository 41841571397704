/* eslint-disable no-useless-escape */
export const FOOD_COURTS_PATH_REGEX = /^\/food-courts\/[a-f0-9\-]+$/;

export const isFoodCourtsPath = (path: string): boolean =>
  FOOD_COURTS_PATH_REGEX.test(window.location.pathname);

export const STORES_PATH_REGEX = /^\/stores\/[a-f0-9\-]+(\/search)?$/;

export const isPublicStoresPath = (path: string): boolean =>
  STORES_PATH_REGEX.test(window.location.pathname);
