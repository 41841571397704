import * as React from "react";

function SvgWand(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.215 13.496l.354-.353-.707-.707-.354.353.707.707zm2.577 1.933v.5h1v-.5h-1zm1-1.143v-.5h-1v.5h1zm-1-9.143v.5h1v-.5h-1zm1-1.143v-.5h-1V4h1zM8.574 9.214h-.5v1h.5v-1zm1.144 1h.5v-1h-.5v1zm9.149-1h-.5v1h.5v-1zm1.143 1h.5v-1h-.5v1zm-2.64-4.282l-.354.354.707.707.354-.354-.707-.707zm1.85-.435l.354-.354-.707-.707-.354.353.707.708zm-9.148-.708l-.354-.353-.707.707.354.354.707-.708zm.436 1.85l.354.354.707-.707-.354-.354-.707.707zm7.569 6.15l-.354-.353-.707.707.354.353.707-.707zm.436 1.85l.354.354.707-.707-.354-.354-.707.707zm-14.16 5.715l6.862-6.857-.707-.708-6.861 6.857.706.708zm10.44-4.925v-1.143h-1v1.143h1zm0-10.286V4h-1v1.143h1zm-6.219 5.071h1.144v-1H8.574v1zm10.293 0h1.143v-1h-1.143v1zm-.79-3.575l1.143-1.142-.707-.708-1.143 1.143.707.707zM9.365 5.497l1.143 1.142.707-.707-1.143-1.143-.707.708zm8.005 8l1.143 1.142.707-.707-1.143-1.143-.707.707z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgWand;
