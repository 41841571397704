import * as React from 'react';

function SvgCalendar2(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 17L9.60957 16.6877C9.4895 16.8377 9.46609 17.0434 9.54935 17.2166C9.63261 17.3898 9.8078 17.5 10 17.5V17ZM13.6756 12.4056L14.066 12.7179L13.6756 12.4056ZM11.2071 10.2929L11.5607 10.6464L11.2071 10.2929ZM6.92857 4V6.85714H7.92857V4H6.92857ZM6.92857 6.85714V9.71429H7.92857V6.85714H6.92857ZM16.0714 4V6.85714H17.0714V4H16.0714ZM16.0714 6.85714V9.71429H17.0714V6.85714H16.0714ZM5.14286 7.35714H7.42857V6.35714H5.14286V7.35714ZM7.42857 7.35714H16.5714V6.35714H7.42857V7.35714ZM16.5714 7.35714H18.8571V6.35714H16.5714V7.35714ZM19.5 8V19.4286H20.5V8H19.5ZM18.8571 20.0714H5.14286V21.0714H18.8571V20.0714ZM4.5 19.4286V8H3.5V19.4286H4.5ZM5.14286 20.0714C4.78782 20.0714 4.5 19.7836 4.5 19.4286H3.5C3.5 20.3359 4.23553 21.0714 5.14286 21.0714V20.0714ZM19.5 19.4286C19.5 19.7836 19.2122 20.0714 18.8571 20.0714V21.0714C19.7645 21.0714 20.5 20.3359 20.5 19.4286H19.5ZM18.8571 7.35714C19.2122 7.35714 19.5 7.64496 19.5 8H20.5C20.5 7.09267 19.7645 6.35714 18.8571 6.35714V7.35714ZM5.14286 6.35714C4.23553 6.35714 3.5 7.09267 3.5 8H4.5C4.5 7.64496 4.78782 7.35714 5.14286 7.35714V6.35714ZM10.3904 17.3123L14.066 12.7179L13.2851 12.0932L9.60957 16.6877L10.3904 17.3123ZM12.5194 9.5H11.9142V10.5H12.5194V9.5ZM10.8536 9.93934L10.1464 10.6464L10.8536 11.3536L11.5607 10.6464L10.8536 9.93934ZM11.9142 9.5C11.5164 9.5 11.1349 9.65804 10.8536 9.93934L11.5607 10.6464C11.6544 10.5527 11.7816 10.5 11.9142 10.5V9.5ZM14.5 11.4806C14.5 10.3868 13.6132 9.5 12.5194 9.5V10.5C13.061 10.5 13.5 10.939 13.5 11.4806H14.5ZM14.066 12.7179C14.3469 12.3667 14.5 11.9304 14.5 11.4806H13.5C13.5 11.7033 13.4242 11.9193 13.2851 12.0932L14.066 12.7179ZM10 17.5H14.5V16.5H10V17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCalendar2;
