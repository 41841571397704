import * as React from "react";

function SvgCopyBg(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#EEF1F4" />
      <path d="M9 9L9 6C9 5.44771 9.44772 5 10 5L18 5C18.5523 5 19 5.44772 19 6L19 14C19 14.5523 18.5523 15 18 15L15 15M15 18L15 10C15 9.44772 14.5523 9 14 9L6 9C5.44772 9 5 9.44771 5 10L5 18C5 18.5523 5.44771 19 6 19L14 19C14.5523 19 15 18.5523 15 18Z" stroke="#444C54" />
    </svg>
  );
}

export default SvgCopyBg;
