var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Burger as MantineBurger } from '@mantine/core';
var Burger = function (_a) {
    var opened = _a.opened, onClick = _a.onClick, color = _a.color, size = _a.size, transitionDuration = _a.transitionDuration, tooltip = _a.tooltip, rest = __rest(_a, ["opened", "onClick", "color", "size", "transitionDuration", "tooltip"]);
    return (React.createElement(MantineBurger, __assign({ opened: opened, onClick: onClick, color: color, size: size, transitionDuration: transitionDuration, title: tooltip }, rest)));
};
export default Burger;
