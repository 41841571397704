var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Checkbox, createStyles, Pagination, rem, ScrollArea, Table as MantineTable, } from '@mantine/core';
import getPropByPath from '../../utils/getPropByPath';
import prettyString from '../../utils/prettyString';
import HeaderCell from './HeaderCell';
var useStyles = createStyles(function (theme) { return ({
    rowSelected: {
        backgroundColor: theme.colorScheme === 'dark'
            ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
            : theme.colors[theme.primaryColor][0],
    },
}); });
var Table = function (_a) {
    var _b = _a.idKey, idKey = _b === void 0 ? 'id' : _b, columns = _a.columns, data = _a.data, needPagination = _a.needPagination, paginateOptions = _a.paginateOptions, _c = _a.allowRowSelection, allowRowSelection = _c === void 0 ? false : _c, onSort = _a.onSort, onRowSelection = _a.onRowSelection, _selectedRows = _a.selectedRows, _d = _a.headerBackgroundColor, headerBackgroundColor = _d === void 0 ? '#F7F7F7' : _d, _e = _a.headerColor, headerColor = _e === void 0 ? 'rgba(33, 33, 33, 0.4)' : _e, _f = _a.textAlign, textAlign = _f === void 0 ? 'center' : _f, _g = _a.noDataText, noDataText = _g === void 0 ? 'No data available' : _g, rest = __rest(_a, ["idKey", "columns", "data", "needPagination", "paginateOptions", "allowRowSelection", "onSort", "onRowSelection", "selectedRows", "headerBackgroundColor", "headerColor", "textAlign", "noDataText"]);
    var _h = useStyles(), classes = _h.classes, cx = _h.cx;
    // sorting
    var _j = useState(null), sortBy = _j[0], setSortBy = _j[1];
    var _k = useState('asc'), sortDirection = _k[0], setSortDirection = _k[1];
    // pagination props
    var _l = paginateOptions || {}, _m = _l.position, position = _m === void 0 ? 'end' : _m, _o = _l.totalPage, totalPage = _o === void 0 ? 1 : _o, page = _l.page, onChange = _l.onChange, style = _l.style, paginateRest = __rest(_l, ["position", "totalPage", "page", "onChange", "style"]);
    var setSorting = function (field) {
        if (sortBy === field) {
            setSortDirection(function (current) { return (current === 'asc' ? 'desc' : 'asc'); });
        }
        else {
            setSortDirection('asc');
        }
        setSortBy(field);
    };
    // selection row
    var _p = React.useState(_selectedRows !== null && _selectedRows !== void 0 ? _selectedRows : []), selectedRows = _p[0], setSelectedRows = _p[1];
    var toggleRow = function (id) {
        return setSelectedRows(function (current) { return (current.includes(id) ? current.filter(function (item) { return item !== id; }) : __spreadArray(__spreadArray([], current, true), [id], false)); });
    };
    var toggleAll = function () {
        return setSelectedRows(function (current) { return (current.length === data.length ? [] : data.map(function (item) { return item.id; })); });
    };
    useEffect(function () {
        onRowSelection === null || onRowSelection === void 0 ? void 0 : onRowSelection(selectedRows);
    }, [selectedRows, onRowSelection]);
    useEffect(function () {
        setSelectedRows(_selectedRows !== null && _selectedRows !== void 0 ? _selectedRows : []);
    }, [_selectedRows]);
    useEffect(function () {
        if (sortBy && sortDirection && onSort) {
            onSort({ sortBy: sortBy, sortDirection: sortDirection });
        }
    }, [sortBy, sortDirection, onSort]);
    var rows = data.map(function (element, index) {
        var _a;
        var selected = selectedRows.includes(element[idKey]);
        return (React.createElement("tr", { key: element[idKey], style: { textAlign: textAlign }, className: cx((_a = {}, _a[classes.rowSelected] = selected, _a)) },
            allowRowSelection && onRowSelection && (React.createElement("td", null,
                React.createElement(Checkbox, { checked: selectedRows.includes(element[idKey]), onChange: function () { return toggleRow(element[idKey]); }, transitionDuration: 0 }))),
            columns.map(function (column) { return (React.createElement("td", { key: column.key }, column.render
                ? column.render(element, index)
                : column.accessor
                    ? getPropByPath(element, column.accessor)
                    : element[column.key])); })));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(ScrollArea.Autosize, { mah: needPagination ? 'calc(100% - 60px)' : '100%', h: !rows.length ? '0px' : needPagination ? 'calc(100% - 60px)' : '100%' },
            React.createElement(MantineTable, __assign({}, rest),
                React.createElement("colgroup", null,
                    allowRowSelection && onRowSelection && React.createElement("col", { style: { width: rem(40) } }),
                    columns.map(function (column) { return (React.createElement("col", { key: column.key, style: { width: column.width || 'auto' } })); })),
                React.createElement("thead", { style: { position: 'sticky', top: 0, zIndex: 10 } },
                    React.createElement("tr", { style: { background: headerBackgroundColor } },
                        allowRowSelection && onRowSelection && (React.createElement("th", null,
                            React.createElement(Checkbox, { onChange: toggleAll, checked: selectedRows.length === data.length, indeterminate: selectedRows.length > 0 && selectedRows.length !== data.length, transitionDuration: 0 }))),
                        columns.map(function (column) {
                            var _a, _b;
                            return (React.createElement(HeaderCell, { key: column.key, sortDirection: sortDirection, sorted: sortBy === column.key, onSort: function () { return setSorting(column.key); }, sortable: column.sortable, textAlign: (_a = column.textAlign) !== null && _a !== void 0 ? _a : textAlign, style: { color: headerColor, textAlign: (_b = column.textAlign) !== null && _b !== void 0 ? _b : textAlign } }, column.headerRender
                                ? column.headerRender()
                                : column.header
                                    ? column.header
                                    : prettyString(column.key)));
                        }))),
                !!rows.length && React.createElement("tbody", null, rows))),
        !rows.length && (React.createElement("div", { style: {
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '2rem',
                fontWeight: 'bold',
            } }, noDataText)),
        needPagination && paginateOptions && !!rows.length && (React.createElement("div", { style: __assign({ marginTop: 20, justifyContent: position, display: 'flex', alignItems: 'center', width: '100%' }, style) },
            React.createElement(Pagination, __assign({ total: totalPage, onChange: onChange, value: page }, paginateRest))))));
};
export default Table;
