import * as React from 'react';

function SvgXCircle(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 8.99995C1 4.58168 4.58172 0.999954 9 0.999954C13.4183 0.999954 17 4.58168 17 8.99995C17 13.4182 13.4183 17 9 17C4.58172 17 1 13.4182 1 8.99995Z"
        fill="transparent"
      />
      <path
        d="M5.57143 5.57138L12.4286 12.4285M5.57143 12.4285L12.4286 5.57138M9 17C4.58172 17 1 13.4182 1 8.99995C1 4.58168 4.58172 0.999954 9 0.999954C13.4183 0.999954 17 4.58168 17 8.99995C17 13.4182 13.4183 17 9 17Z"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgXCircle;
