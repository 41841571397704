import styled from '@emotion/styled';
import { Button, Typography } from '@sicpama/core-components';
import { ReactElement } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import './styles.scss';

const translationPrefix = 'pages.unsupported-device';
const StyledPage = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  --webkit-min-height: 100dvh;
  --webkit-height: 100dvh;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const UnsupportedDevice = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <StyledPage>
      <img src="/images/error.png" alt="Error" width={176} height={197} />
      <div className="mt-4"></div>
      <Typography size={'xl'} weight={700}>
        <Trans i18nKey={`${translationPrefix}.part1`} t={t} />
      </Typography>
      <Trans i18nKey={`${translationPrefix}.part2`} t={t} />
      <Button
        onClick={() => {
          window.location.href = 'https://sicpama.com/';
        }}
        title={t(`${translationPrefix}.button`)}
        titleColor="white"
        backgroundColor="#7A7A7A!important"
        className="!my-2 w-[288px] h-[56px] text-base"
      />
    </StyledPage>
  );
};
export default UnsupportedDevice;
