import * as React from "react";

function SvgBodyache(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.988 6.048a.5.5 0 01.544.452c.052.572-.072 1.423-.596 2.192a3.565 3.565 0 01-1.283 1.139.499.499 0 01-.587.73c-.44-.146-.865-.23-1.324-.109-.452.12-1.02.46-1.69 1.325-.314.59-.373 1.133-.373 1.762 0 .147.004.302.007.465.013.535.027 1.154-.078 1.826-.197 1.26-.078 2.315-.005 2.648a.5.5 0 01-.977.214c-.1-.454-.222-1.634-.006-3.017.09-.575.079-1.071.067-1.587-.004-.18-.008-.36-.008-.549 0-.716.068-1.462.514-2.276l.018-.034.024-.03c.767-1 1.514-1.52 2.253-1.714.047-.012.093-.023.14-.033a.498.498 0 01.233-.097c1.217-.17 1.884-.691 2.249-1.226.377-.553.46-1.166.426-1.538a.5.5 0 01.452-.543zM7.06 15.506a.5.5 0 01.615.35c.094.344.204.898.236 1.473.032.555-.002 1.226-.28 1.732a.5.5 0 01-.877-.48c.136-.249.188-.68.16-1.195a6.53 6.53 0 00-.204-1.265.5.5 0 01.35-.615zM13.962 6.047a.5.5 0 00-.534.463c-.04.548.052 1.378.461 2.138.261.485.65.937 1.203 1.263a.499.499 0 00.661.637c.288-.121.814-.223 1.384-.092.544.125 1.154.47 1.653 1.277.255.604.303 1.166.303 1.806 0 .15-.003.309-.006.474-.01.533-.021 1.139.06 1.8.158 1.274.064 2.342.004 2.687a.5.5 0 10.985.17c.077-.442.173-1.608.004-2.979-.072-.585-.063-1.093-.054-1.612.003-.178.007-.356.007-.54 0-.706-.053-1.435-.399-2.234l-.013-.032-.018-.029c-.642-1.06-1.487-1.575-2.301-1.763a3.43 3.43 0 00-.78-.087.5.5 0 00-.118-.036c-.895-.159-1.403-.643-1.694-1.184a3.088 3.088 0 01-.345-1.592.5.5 0 00-.463-.535zm2.52 9.453a.5.5 0 00-.594.383c-.073.338-.16.886-.184 1.452-.025.548.002 1.196.214 1.685a.5.5 0 10.918-.397c-.116-.267-.157-.72-.133-1.243.022-.504.1-.995.162-1.286a.5.5 0 00-.383-.594z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.835 2.49l1.612 3.225a.5.5 0 11-.894.447l-.326-.652-.596 2.86-1.04-1.374-.347 1.039a.5.5 0 11-.949-.317l.946-2.838.86 1.134.734-3.524zM11.449 14.141a.5.5 0 01.5.5v3.877a.5.5 0 11-1 0V14.64a.5.5 0 01.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBodyache;
