import { create } from 'zustand';
import { getThirdPartyAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-auth-react/recipe/thirdpartypasswordless';
import { customerService } from 'services';

interface AuthProps {
  isLoading: boolean;
  goToProviderIdSignInUrl: (providerId: string) => Promise<void>;
  postLogin: () => Promise<void>;
}
export const useAuthStore = create<AuthProps>((set) => ({
  isLoading: false,
  goToProviderIdSignInUrl: async (providerId) => {
    try {
      set(() => ({ isLoading: true }));
      const authUrl = await getThirdPartyAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId: providerId,
        frontendRedirectURI: `${window.location.origin}/auth/callback/${providerId}`,
      });
      set(() => ({ isLoading: false }));
      window.location.assign(authUrl);
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  },
  postLogin: async () => {
    set(() => ({ isLoading: true }));
    await customerService.postLogin();
    set(() => ({ isLoading: false }));
  },
}));

interface SignInToolTipProps {
  isAlreadyReadSignInToolTip: boolean;
  setAlreadyReadSignInToolTip: (isAlreadyReadSignInToolTip: boolean) => void;
}

export const useSignInToolTipStore = create<SignInToolTipProps>((set) => ({
  isAlreadyReadSignInToolTip: false,
  setAlreadyReadSignInToolTip: (isAlreadyReadSignInToolTip: boolean) => {
    set({ isAlreadyReadSignInToolTip });
  },
}));
