import * as React from "react";

function SvgDesklamp(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 12.843l-.395-.307a.5.5 0 00.041.661L5 12.843zm5.964-8.349l-.354.354.354-.354zm2.385 2.386l.354.353.353-.353-.353-.354-.354.354zm-2.385 2.385l-.354.354.354.353.353-.353-.353-.354zM8.578 6.88l-.353.353.353-.353zm3.741 1.03l.354.353-.354-.353zm-.325.325l-.354-.354.354.354zm.325 6.831l-.353-.353.353.353zm6.832-6.831l-.354-.354.354.354zm0-.325l-.354.353.354-.353zm-7.157 7.156l.354-.353-.354.353zM5.596 20.5h13.12v-1H5.597v1zm6.914-.854L5.354 12.49l-.708.707 7.157 7.157.707-.708zM5.395 13.15l4.174-5.367-.789-.614-4.175 5.367.79.614zm5.215-8.302l2.386 2.385.707-.707-2.386-2.385-.707.707zm2.386 1.678L10.61 8.912l.707.707 2.386-2.386-.707-.707zm-1.679 2.386L8.932 6.526l-.707.707L10.61 9.62l.707-.707zM8.932 4.848a1.187 1.187 0 011.678 0l.707-.707a2.187 2.187 0 00-3.092 0l.707.707zm-.707-.707a2.187 2.187 0 000 3.092l.707-.707a1.187 1.187 0 010-1.678l-.707-.707zm3.74 3.415l-.325.325.707.708.326-.326-.707-.707zm.708 7.864l6.831-6.831-.707-.708-6.831 6.832.707.707zm6.831-6.831a.73.73 0 000-1.033l-.707.707a.27.27 0 010-.382l.707.708zm-7.864 6.83a.73.73 0 001.033 0l-.707-.706a.27.27 0 01.382 0l-.708.707zm0-7.538a5.33 5.33 0 000 7.539l.707-.707a4.33 4.33 0 010-6.124l-.707-.708zm1.033.382a4.33 4.33 0 016.124 0l.707-.707a5.33 5.33 0 00-7.538 0l.707.707z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDesklamp;
