import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

class FingerPrintService {
  async getFingerPrintData(): Promise<{ requestId: string; fingerPrint: string }> {
    const fpSubDomain = process.env.REACT_APP_FINGERPRINT_SUB_DOMAIN;
    const fpApiKey = process.env.REACT_APP_FINGERPRINT_API_KEY;
    if (!fpSubDomain || !fpApiKey) {
      throw new Error('FingerprintJS API Key or SubDomain is not set');
    }
    const fingerPrintResponse = await FingerprintJS.load({
      apiKey: fpApiKey,
      region: 'ap',
      endpoint: fpSubDomain,
      // If multiple endpoints are given, the agent will try them one by one until it finds a working one.
      scriptUrlPattern: [
        `${process.env.REACT_APP_FINGERPRINT_LOAD_SCRIPT_URL}`,
        FingerprintJS.defaultScriptUrlPattern, // Use the default endpoint as fallback
      ],
    });
    const fpGetter = await fingerPrintResponse.get();
    const fingerPrint = fpGetter.visitorId;
    const requestId = fpGetter.requestId;
    localStorage.setItem(LOCAL_STORAGE_KEY.FINGERPRINT, fingerPrint);
    localStorage.setItem(LOCAL_STORAGE_KEY.FINGERPRINT_REQUEST_ID, requestId);
    return { requestId, fingerPrint };
  }
}

export const fingerPrintService = new FingerPrintService();
