import * as React from "react";

function SvgEyeClosed(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.464 8.814l.004.008a2.599 2.599 0 00.08.18 9.37 9.37 0 001.449 2.164C8.06 12.347 9.686 13.5 12 13.5s3.939-1.152 5.003-2.335a9.37 9.37 0 001.449-2.163 4.967 4.967 0 00.08-.18l.004-.007v-.001a.5.5 0 01.928.372L19 9l.464.186v.002l-.002.004-.006.014a3.334 3.334 0 01-.1.222 10.366 10.366 0 01-1.61 2.406 9.2 9.2 0 01-.598.607l1.706 1.705a.5.5 0 01-.708.707l-1.774-1.774a7.304 7.304 0 01-3.872 1.405V16.5a.5.5 0 01-1 0v-2.016a7.304 7.304 0 01-3.872-1.405l-1.774 1.774a.5.5 0 11-.708-.707l1.706-1.705a9.234 9.234 0 01-.599-.607 10.368 10.368 0 01-1.61-2.406 6.041 6.041 0 01-.099-.222l-.005-.014-.002-.004v-.001l-.001-.001L5 9l-.464.186a.5.5 0 01.928-.372"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgEyeClosed;
