import * as React from 'react';

function SvgUserRectangle(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="icon_customer">
        <g id="color_fill">
          <path
            id="Vector"
            d="M18.4492 14.4908C18.4492 13.4751 18.0443 12.5009 17.3235 11.7827C16.6027 11.0645 15.6251 10.661 14.6057 10.661C13.5864 10.661 12.6087 11.0645 11.888 11.7827C11.1672 12.501 10.7622 13.4751 10.7622 14.4908C10.7622 15.5065 11.1672 16.4807 11.888 17.1989C12.6087 17.9171 13.5864 18.3206 14.6057 18.3206C15.6251 18.3206 16.6027 17.9171 17.3235 17.1989C18.0443 16.4807 18.4492 15.5065 18.4492 14.4908ZM14.6057 12.5759C15.1154 12.5759 15.6042 12.7776 15.9646 13.1368C16.325 13.4959 16.5275 13.9829 16.5275 14.4908C16.5275 14.9987 16.325 15.4857 15.9646 15.8448C15.6042 16.204 15.1154 16.4057 14.6057 16.4057C14.096 16.4057 13.6072 16.204 13.2468 15.8448C12.8864 15.4857 12.684 14.9987 12.684 14.4908C12.684 13.9829 12.8864 13.4959 13.2468 13.1368C13.6072 12.7776 14.096 12.5759 14.6057 12.5759V12.5759Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M19.0391 20.2361C19.6752 20.2361 20.3651 20.6919 20.4228 21.4942C20.467 22.0974 20.4131 23.2597 19.5387 24.2765C18.6528 25.3106 17.1 25.9808 14.6095 25.9808C12.1189 25.9808 10.5661 25.3106 9.67824 24.2765C8.80577 23.2597 8.75196 22.0974 8.79424 21.4942C8.85189 20.6899 9.54372 20.2361 10.1798 20.2361L19.0391 20.2361V20.2361ZM18.0801 23.0319C18.2936 22.7809 18.4332 22.4761 18.4837 22.151L10.7352 22.151C10.7858 22.476 10.9254 22.7809 11.1388 23.0319C11.5308 23.4857 12.4417 24.0659 14.6095 24.0659C16.7772 24.0659 17.6881 23.4857 18.0801 23.0319V23.0319Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M9.00712 4.9149L7.88289 4.9149C7.11837 4.9149 6.38516 5.21753 5.84457 5.7562C5.30397 6.29487 5.00027 7.02546 5.00027 7.78726L5.00027 26.9363C5.00027 27.6981 5.30397 28.4287 5.84457 28.9673C6.38517 29.506 7.11837 29.8086 7.88289 29.8086L21.3351 29.8086C22.0997 29.8086 22.8329 29.506 23.3735 28.9673C23.9141 28.4287 24.2178 27.6981 24.2178 26.9363L24.2178 7.78726C24.2178 7.02546 23.9141 6.29487 23.3735 5.7562C22.8329 5.21753 22.0997 4.9149 21.3351 4.9149L20.2109 4.9149C20.0121 4.35464 19.6438 3.86961 19.1568 3.52665C18.6698 3.18369 18.088 2.99969 17.4916 3L11.7264 3C11.13 2.99969 10.5482 3.18369 10.0612 3.52665C9.57419 3.86961 9.20591 4.35465 9.00712 4.9149V4.9149ZM20.2109 6.82981L21.3351 6.82981C21.59 6.82981 21.8344 6.93068 22.0146 7.11024C22.1948 7.28979 22.296 7.53332 22.296 7.78726L22.296 26.9363C22.296 27.1902 22.1948 27.4337 22.0146 27.6133C21.8344 27.7929 21.59 27.8937 21.3351 27.8937L7.88289 27.8937C7.62805 27.8937 7.38365 27.7929 7.20345 27.6133C7.02325 27.4337 6.92202 27.1902 6.92202 26.9363L6.92202 7.78726C6.92202 7.53333 7.02325 7.28979 7.20345 7.11024C7.38365 6.93068 7.62805 6.82981 7.88289 6.82981L9.00712 6.82981C9.20591 7.39007 9.57419 7.8751 10.0612 8.21806C10.5482 8.56102 11.13 8.74502 11.7264 8.74471L17.4916 8.74471C18.088 8.74502 18.6698 8.56102 19.1568 8.21806C19.6438 7.8751 20.0121 7.39006 20.2109 6.82981ZM18.4525 5.87235C18.4525 6.12629 18.3513 6.36982 18.1711 6.54937C17.9909 6.72893 17.7465 6.82981 17.4916 6.82981L11.7264 6.82981C11.4716 6.82981 11.2272 6.72893 11.047 6.54938C10.8668 6.36982 10.7655 6.12629 10.7655 5.87236C10.7655 5.61842 10.8668 5.37489 11.047 5.19534C11.2272 5.01578 11.4716 4.9149 11.7264 4.9149L17.4916 4.9149C17.7465 4.9149 17.9909 5.01578 18.1711 5.19533C18.3513 5.37489 18.4525 5.61842 18.4525 5.87235Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgUserRectangle;
