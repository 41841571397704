import * as React from 'react';

function SvgStore(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.03077 5.5H19.7692L21.8 11H19.7692H13.6769V19H4.03077V11H2L4.03077 5.5Z" fill="currentColor" />
      <path
        d="M3.01538 0H2.01538V2H3.01538V0ZM20.7846 2H21.7846V0H20.7846V2ZM4.03077 5.5V4.5H3.33401L3.09267 5.15363L4.03077 5.5ZM19.7692 5.5L20.7073 5.15363L20.466 4.5H19.7692V5.5ZM21.8 11V12H23.2352L22.7381 10.6536L21.8 11ZM2 11L1.0619 10.6536L0.564781 12H2V11ZM4.03077 19H3.03077V20H4.03077V19ZM13.6769 19V20H14.6769V19H13.6769ZM18.7692 19V20H20.7692V19H18.7692ZM3.01538 2H20.7846V0H3.01538V2ZM4.03077 6.5H19.7692V4.5H4.03077V6.5ZM18.8311 5.84637L20.8619 11.3464L22.7381 10.6536L20.7073 5.15363L18.8311 5.84637ZM2.9381 11.3464L4.96887 5.84637L3.09267 5.15363L1.0619 10.6536L2.9381 11.3464ZM4.03077 10H2V12H4.03077V10ZM3.03077 11V19H5.03077V11H3.03077ZM4.03077 20H13.6769V18H4.03077V20ZM13.6769 10H4.03077V12H13.6769V10ZM14.6769 19V11H12.6769V19H14.6769ZM21.8 10H19.7692V12H21.8V10ZM19.7692 10H13.6769V12H19.7692V10ZM18.7692 11V19H20.7692V11H18.7692Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgStore;
