import { ReactElement } from 'react';

export const BasketSkeletonLoader = (): ReactElement => {
  return (
    <div className="bg-white p-4 h-screen">
      {/* Header */}
      <div className="flex items-center mb-6">
        <div className="w-6 h-6 bg-gray-200 rounded-full mr-4"></div>
        <div className="w-20 h-6 bg-gray-200 rounded"></div>
      </div>

      {/* Grand Total */}
      <div className="mb-6">
        <div className="w-24 h-4 bg-gray-200 rounded mb-2"></div>
        <div className="w-32 h-8 bg-gray-200 rounded"></div>
      </div>

      {/* Table Info */}
      <div className="mb-6">
        <div className="w-48 h-4 bg-gray-200 rounded mb-4"></div>
        <div className="flex">
          <div className="w-12 h-12 bg-gray-200 rounded-full mr-4"></div>
          <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
        </div>
      </div>

      {/* My Basket */}
      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        <div className="flex justify-between items-center mb-4">
          <div className="w-24 h-6 bg-gray-200 rounded"></div>
          <div className="w-20 h-6 bg-gray-200 rounded"></div>
        </div>
        <div className="h-px bg-gray-300 mb-4"></div>
        <div className="flex mb-4">
          <div className="w-16 h-16 bg-gray-200 rounded mr-4"></div>
          <div className="flex-grow">
            <div className="w-3/4 h-4 bg-gray-200 rounded mb-2"></div>
            <div className="w-1/2 h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="w-20 h-6 bg-gray-200 rounded"></div>
          <div className="w-24 h-8 bg-gray-200 rounded"></div>
        </div>
      </div>

      {/* Add Items */}
      <div className="w-full h-10 bg-gray-200 rounded mb-6"></div>

      {/* Payment Options */}
      <div className="mb-4">
        <div className="w-64 h-4 bg-gray-200 rounded mb-2"></div>
        <div className="w-full h-12 bg-gray-200 rounded mb-2"></div>
        <div className="w-full h-8 bg-gray-200 rounded"></div>
      </div>

      {/* Checkout Button */}
      <div className="w-full h-12 bg-gray-200 rounded"></div>
    </div>
  );
};
