import * as React from "react";

function SvgSpotify(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 12a7.5 7.5 0 01-7.5 7.5v1a8.5 8.5 0 008.5-8.5h-1zM12 19.5A7.5 7.5 0 014.5 12h-1a8.5 8.5 0 008.5 8.5v-1zM4.5 12A7.5 7.5 0 0112 4.5v-1A8.5 8.5 0 003.5 12h1zM12 4.5a7.5 7.5 0 017.5 7.5h1A8.5 8.5 0 0012 3.5v1zM7.597 14.92a12.009 12.009 0 013.9-.262c1.375.135 2.689.523 3.694 1.23l.575-.819c-1.19-.837-2.688-1.26-4.17-1.406a13.012 13.012 0 00-4.236.286l.237.972zm-.38-2.428c3.948-.698 6.716-.343 9.013 1.305l.583-.812c-2.604-1.87-5.688-2.2-9.77-1.477l.175.984zm-.268-2.455c1.557-.55 3.453-.778 5.315-.626 1.863.153 3.654.683 5.02 1.611l.562-.827c-1.546-1.05-3.516-1.618-5.5-1.78-1.987-.163-4.026.077-5.73.679l.333.943z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSpotify;
