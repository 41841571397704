import * as React from "react";

function SvgFilterSmall(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="filter-small_svg__a" fill="#fff">
        <path d="M9.853 16.114a.41.41 0 00.41.412h3.381a.41.41 0 00.41-.412v-2.526h-4.2v2.526zM16.697 7.3H7.21a.412.412 0 00-.354.619l2.852 4.845h4.494l2.852-4.845a.414.414 0 00-.357-.619z" />
      </mask>
      <path
        d="M14.054 13.588h1v-1h-1v1zm-4.2 0v-1h-1v1h1zm-2.998-5.67l-.866.5.004.008.862-.507zm2.852 4.846l-.862.507.29.493h.572v-1zm4.494 0v1h.572l.29-.493-.862-.507zm2.852-4.845l.862.507.004-.007.003-.006-.869-.494zm-8.2 8.195a1.41 1.41 0 001.409 1.412v-2c.332 0 .59.27.59.588h-2zm1.409 1.412h3.381v-2h-3.381v2zm3.381 0a1.41 1.41 0 001.41-1.412h-2a.59.59 0 01.59-.588v2zm1.41-1.412v-2.526h-2v2.526h2zm-1-3.526h-4.2v2h4.2v-2zm-5.2 1v2.526h2v-2.526h-2zM16.696 6.3H7.21v2h9.487v-2zm-9.487 0c-1.094 0-1.76 1.185-1.22 2.119l1.732-1a.588.588 0 01-.512.881v-2zM5.994 8.426l2.852 4.845 1.723-1.014L7.718 7.41 5.994 8.426zm3.714 5.338h4.494v-2H9.708v2zm5.356-.493l2.852-4.845-1.724-1.015-2.851 4.846 1.723 1.014zm2.86-4.858c.528-.93-.132-2.113-1.227-2.113v2a.586.586 0 01-.512-.875l1.739.988z"
        fill="currentColor"
        mask="url(#filter-small_svg__a)"
      />
    </svg>
  );
}

export default SvgFilterSmall;
