import { Modal, Typography, CloseButton } from '@sicpama/core-components';
import { PublicContainer, Spacer } from 'components';
import Divider from 'components/divider';
import RecommendationMenusCarousel from 'pages/menu-v2/RecommendationMenusCarousel';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CouponHeader from './CouponHeader';
import {
  storeThemeSelectors,
  useAuthStore,
  useMenuStore,
  useOrderStore,
  useThemeStore,
} from '../../../stores';
import { snsButtonConfigs } from '../utils';

import '../../login/styles.scss';
import ProviderLoginButton from './ProviderLoginButton';
import { ADVERT_LOCATION, SNSProviderType, STORE_ATTRIBUTE_NAME } from 'submodules/sicpama-shared';
import { ROUTE } from '../../../constants';

const translationPrefix = 'pages.login';

function DefaultHeader(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { store } = useThemeStore();

  const onNavigate = (): void => {
    const from = searchParams.get('from');
    if (from) {
      navigate(-1);
    } else {
      navigate(ROUTE.HOME);
    }
  };

  const isSicpamaLogoDisabled = useMemo(() => {
    const sicpamaLogoDisabledAttribute = store?.attributes?.find(
      (x) => x.name === STORE_ATTRIBUTE_NAME.SICPAMA_LOGO_DISABLED,
    );
    return sicpamaLogoDisabledAttribute?.value === 'true';
  }, [store]);

  return (
    <div className="flex flex-col items-center">
      <CloseButton className="absolute top-4 right-4" size="lg" onClick={onNavigate} />
      {!isSicpamaLogoDisabled && store?.id > 0 && (
        <img src="/images/sicpama.svg" className="w-auto h-auto" alt="Login Logo" />
      )}
      <Typography
        className="mt-6 mb-[58px] text-[28px] font-medium leading-[28px] text-[#2A297A] text-center"
        weight={700}
      >
        <Trans
          i18nKey="pages.login.newTitle"
          components={{
            orange: <span style={{ color: '#FF7F1D', fontWeight: 700 }} />,
            blue: <span style={{ color: '#70B4FF', fontWeight: 700 }} />,
          }}
        />
      </Typography>
    </div>
  );
}

const LoginModalV2 = (): JSX.Element => {
  const navigate = useNavigate();
  const { isLoading, goToProviderIdSignInUrl } = useAuthStore();
  const { myInfo } = useOrderStore();
  const { t } = useTranslation();
  const { getMenus } = useMenuStore();
  const { store } = useThemeStore();

  // TODO: update later to get latest used login providers
  const [lastLoginProviders] = useState([]);
  const isCookiesEnabled = navigator.cookieEnabled;

  const coupons = useThemeStore(
    storeThemeSelectors.getCouponByAdvertLocation(ADVERT_LOCATION.LOGIN_PAGE),
  );

  const loginOptions = useMemo(() => {
    const loginOptionsAttribute = store?.attributes?.find(
      (x) => x.name === STORE_ATTRIBUTE_NAME.LOGIN_OPTIONS,
    );
    return JSON.parse(loginOptionsAttribute?.value ?? '[]');
  }, [store]);

  const isCouponAvailable = coupons.length > 0;

  const isKoreanBrowser = navigator.language.includes('ko') || navigator.language.includes('kr');

  const SNS_BUTTONS = useMemo(() => {
    if (store?.id < 1) {
      return [
        {
          provider: SNSProviderType.KAKAO,
          iconSrc: '/icons/kakao-icon.svg',
          buttonText: t(`${translationPrefix}.buttons.kakao`),
          color: isCookiesEnabled ? 'bg-[#FEE500]' : 'bg-[#bcbcbc]',
          // color: isCookiesEnabled ? 'bg-white' : 'bg-[#bcbcbc]',
          customStyle: { borderRadius: '100px', border: '1px solid #D8D8D8' },
        },
        {
          provider: SNSProviderType.GOOGLE,
          iconSrc: '/icons/google-icon.svg',
          buttonText: t(`${translationPrefix}.buttons.google`),
          color: isCookiesEnabled ? 'bg-white' : 'bg-[#bcbcbc]',
          customStyle: { borderRadius: '100px', border: '1px solid #D8D8D8' },
        },
      ];
    }
    const snsButtons = snsButtonConfigs(t, translationPrefix, isCookiesEnabled, loginOptions);
    if (!isKoreanBrowser) {
      const kakaoConfig = snsButtons.shift();
      snsButtons.push(kakaoConfig!);
    }
    return snsButtons;
  }, [t, isCookiesEnabled, isKoreanBrowser, loginOptions, store]);

  const onProviderClick = (provider?: SNSProviderType): void => {
    if (isLoading) {
      return;
    }

    if (!provider) {
      navigate('/menus');
      return;
    }
    goToProviderIdSignInUrl(provider);
  };

  useEffect(() => {
    getMenus();
  }, []);

  useEffect(() => {
    if (myInfo?.sicpamaId) {
      navigate('/menus');
    }
  }, [myInfo]);

  return (
    <Modal
      className="my-login-modal"
      opened={true}
      onClose={() => {
        navigate('/menus');
      }}
      fullScreen
      title=""
      styles={() => ({
        body: {
          height: '100%',
          overflowY: 'auto',
          padding: 0,
        },
        title: {
          visibility: 'hidden',
        },
      })}
    >
      <PublicContainer
        showSearch={true}
        showProgressBar={false}
        customClassName="relative h-full w-full"
      >
        <div className="absolute z-[100] inset-0 opacity-70 bg-black" />
        <RecommendationMenusCarousel />
        <Divider className="!min-h-[8px] !h-[8px] my-2" />
      </PublicContainer>
      <div className="absolute z-[1000] bottom-0 left-0 right-0 h-auto max-h-[80%] bg-white rounded-t-lg overflow-y-auto">
        <div className="relative h-full w-full flex flex-col items-center p-8">
          {isCouponAvailable ? <CouponHeader /> : <DefaultHeader />}
          {!lastLoginProviders.length && (
            <img
              src="/images/login_guide_en.svg"
              className="w-[139px] h-auto z-10"
              alt="Login Guide"
            />
          )}
          <div className="w-full h-1/4">
            {SNS_BUTTONS.map((config, index) => (
              <ProviderLoginButton
                key={config.provider}
                onClick={() => {
                  if (!isCookiesEnabled) return;
                  onProviderClick(config.provider);
                }}
                iconSrc={config.iconSrc}
                buttonText={config.buttonText}
                color={config.color}
                customStyle={
                  index === 0 ? config.customStyle : { ...config.customStyle, marginTop: '12px' }
                }
                textColor={
                  [SNSProviderType.FACEBOOK, SNSProviderType.TIKTOK].includes(config.provider)
                    ? 'text-white'
                    : 'text-black'
                }
              />
            ))}
            {!isCookiesEnabled && (
              <Typography variant="text" color="red" weight="bold" size="lg" className="mt-4">
                {t(`common.enableCookies`)}
              </Typography>
            )}

            <ProviderLoginButton
              onClick={() => {
                onProviderClick();
              }}
              buttonText={t(`${translationPrefix}.buttons.guest`)}
              color="bg-[#F0F0F0]"
              customStyle={{ borderRadius: '100px', border: '1px solid #F0F0F0' }}
            />
            <Spacer size={12} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModalV2;
