import * as React from 'react';

function SvgOperation(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="1" y="1" width="16" height="6" stroke="currentColor" strokeWidth="2" />
      <rect x="11" y="11" width="6" height="6" stroke="currentColor" strokeWidth="2" />
      <rect x="1" y="11" width="6" height="6" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
}

export default SvgOperation;
