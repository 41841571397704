import * as React from "react";

function SvgCamera(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.5 6L6.5 6M19 17L19 9C19 8.44772 18.5523 8 18 8L6 8C5.44772 8 5 8.44772 5 9L5 17C5 17.5523 5.44772 18 6 18L18 18C18.5523 18 19 17.5523 19 17ZM14 15C12.8954 15 12 14.1046 12 13C12 11.8954 12.8954 11 14 11C15.1046 11 16 11.8954 16 13C16 14.1046 15.1046 15 14 15Z" stroke="#444C54" />
    </svg>
  );
}

export default SvgCamera;
