var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Slider as MantineSlider } from '@mantine/core';
var Slider = function (_a) {
    var label = _a.label, disabled = _a.disabled, color = _a.color, labelAlwaysOn = _a.labelAlwaysOn, showLabelOnHover = _a.showLabelOnHover, thumbChildren = _a.thumbChildren, thumbSize = _a.thumbSize, scale = _a.scale, records = _a.records, name = _a.name, onChange = _a.onChange, onChangeEnd = _a.onChangeEnd, value = _a.value, borderRadius = _a.borderRadius, size = _a.size, min = _a.min, max = _a.max, step = _a.step, rest = __rest(_a, ["label", "disabled", "color", "labelAlwaysOn", "showLabelOnHover", "thumbChildren", "thumbSize", "scale", "records", "name", "onChange", "onChangeEnd", "value", "borderRadius", "size", "min", "max", "step"]);
    return (React.createElement(MantineSlider, __assign({ label: label, labelAlwaysOn: labelAlwaysOn, showLabelOnHover: showLabelOnHover, thumbChildren: thumbChildren, thumbSize: thumbSize, scale: scale, marks: records, name: name, onChange: onChange, onChangeEnd: onChangeEnd, value: value, radius: borderRadius, size: size, min: min, max: max, step: step, disabled: disabled, styles: function (theme) { return ({
            bar: {
                backgroundColor: color,
            },
            thumb: {
                borderColor: color,
            },
        }); } }, rest)));
};
export default React.memo(Slider);
