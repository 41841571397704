import * as React from "react";

function SvgSortHighToLow(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.035 4.552h.5a.5.5 0 00-.5-.5v.5zm1.103 12.138l.474.158-.474-.158zm.532-1.596l-.475-.158.475.158zM9.31 20l-.353.354a.5.5 0 00.707 0L9.31 20zm6.07-14.948h1.655v-1h-1.656v1zm1.155-.5v6.62h1v-6.62h-1zm-1.156 7.12h3.31v-1h-3.31v1zm2.054 1.207h-.95v1h.95v-1zm1.18 3.969l.531-1.595-.949-.317-.531 1.596.948.316zm-2.13.342h1.655v-1h-1.655v1zm1.026 2.968l1.103-3.31-.948-.316-1.104 3.31.949.316zm-3.181-5.124c0 1.19.965 2.156 2.155 2.156v-1a1.155 1.155 0 01-1.155-1.156h-1zm2.155-2.155c-1.19 0-2.155.965-2.155 2.155h1c0-.638.517-1.155 1.155-1.155v-1zm.95 1c.549 0 .936.537.762 1.057l.949.316a1.803 1.803 0 00-1.71-2.373v1zm-7.77 6.475l3.311-3.31-.707-.708-3.31 3.31.707.708zm0-.708l-3.31-3.31-.707.707 3.31 3.31.708-.707zM8.81 4v16h1V4h-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSortHighToLow;
