import * as React from "react";

function SvgEye(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 11.714l-.464-.185a.5.5 0 000 .371L4 11.714zm16 0l.464.186a.5.5 0 000-.371l-.464.185zm-8 5.215c-2.671 0-4.546-1.33-5.771-2.692a10.772 10.772 0 01-1.667-2.49 5.998 5.998 0 01-.094-.208l-.003-.01h-.001c0-.001 0-.001 0 0L4 11.713l-.464.186v.003l.003.004a2.575 2.575 0 00.03.07 10.303 10.303 0 00.443.876c.316.56.8 1.305 1.473 2.053 1.347 1.496 3.472 3.023 6.515 3.023v-1zm-8-5.215l.464.186V11.9a.719.719 0 01.022-.05 9.317 9.317 0 01.396-.781c.29-.513.734-1.195 1.347-1.876C7.454 7.83 9.329 6.5 12 6.5v-1c-3.043 0-5.168 1.527-6.515 3.023a11.778 11.778 0 00-1.828 2.732 6.913 6.913 0 00-.118.266l-.002.005a.04.04 0 01-.001.002l.464.186zM12 6.5c2.671 0 4.546 1.33 5.771 2.692a10.785 10.785 0 011.667 2.49 6.167 6.167 0 01.094.208l.003.009.001.002.464-.187a44.665 44.665 0 00.463-.188c0-.001 0-.003-.002-.005l-.006-.015a10.368 10.368 0 00-.466-.93 11.775 11.775 0 00-1.474-2.053C17.168 7.027 15.043 5.5 12 5.5v1zm8 5.214a81.781 81.781 0 01-.464-.186v.002a.82.82 0 00-.022.05 9.33 9.33 0 01-.396.78c-.29.513-.734 1.196-1.347 1.877-1.225 1.361-3.1 2.692-5.771 2.692v1c3.043 0 5.168-1.527 6.515-3.023a11.775 11.775 0 001.828-2.733 6.765 6.765 0 00.112-.25l.006-.016.002-.004V11.9h.001L20 11.713zM12 13.5c-.986 0-1.786-.8-1.786-1.786h-1A2.786 2.786 0 0012 14.5v-1zm1.786-1.786c0 .986-.8 1.786-1.786 1.786v1a2.786 2.786 0 002.786-2.786h-1zM12 9.93c.986 0 1.786.8 1.786 1.785h1A2.786 2.786 0 0012 8.93v1zm0-1a2.786 2.786 0 00-2.786 2.785h1c0-.986.8-1.785 1.786-1.785v-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgEye;
