import { PaymentGatewayType, PaymentMethodType } from '../submodules/sicpama-shared';
import { COUNTRY_CODE } from './locale.constant';

export const CREDIT_CARD_TYPE = {
  CREDIT_CARD: 'credit_card',
  CHECK_CARD: 'check_card',
  GIFT: 'gift',
};

export const INPUT_FORM_ELEMENT_OPTIONS = {
  [PaymentGatewayType.KICC]: {
    NAME: 'kicc-payments-form',
    METHOD: 'post',
    CHARSET: 'euc-kr',
    DISPLAY_TYPE: 'hidden',
  },
  [PaymentGatewayType.ONE_PG]: {
    NAME: 'one-pg-payments-form',
    METHOD: 'post',
    CHARSET: 'utf-8',
    DISPLAY_TYPE: 'hidden',
  },
};

export const COUNTRY_PAYMENT_GATEWAY: Record<string, PaymentGatewayType> = {
  KR: PaymentGatewayType.ONE_PG,
  SG: PaymentGatewayType.AIRWALLEX,
};

export const CREDIT_CARD_ISSUERS = {
  BC_CARD: 'BCCard',
  KB_CARD: 'KBCard',
  SAMSUNG_CARD: 'SamsungCard',
  SHINHAN_CARD: 'ShinhanCard',
  KAKAO_BANK: 'KakaoBank',
  HYUNDAI_CARD: 'HyundaiCard',
  LOTTE_CARD: 'LotteCard',
  NH_CARD: 'NHCard',
  HANA_CARD: 'HanaCard',
  WOORI_CARD: 'WooriCard',
  SUHYUP_CARD: 'SuhyupCard',
  JEONBUK_CARD: 'JeonbukCard',
  GWANGJU_BANK: 'GwangjuBank',
  JEJU_CARD: 'JejuCard',
  CITY_CARD: 'CityCard',
  SHINHYUP_CARD: 'ShinhyupCard',
  KAKAO_MONEY_OR_NAVER_POINT: 'KakaoMoneyOrNaverPoint',
};

export const MAPPING_ISSUER_NAMES = [
  'BCCard',
  'KBCard',
  'SamsungCard',
  'ShinhanCard',
  'KakaoBank',
  'HyundaiCard',
  'LotteCard',
  'NHCard',
  'HanaCard',
  'WooriCard',
  'SuhyupCard',
  'JeonbukCard',
  'GwangjuBank',
  'JejuCard',
  'CityCard',
  'ShinhyupCard',
  'KakaoMoneyOrNaverPoint',
  'KakaoMoney',
];

export const KICC_REQUEST_VALUES = {
  ORDER_INFO: {
    GOODS_TYPE_CODE: {
      PRODUCT: '0',
      CONTENT: '1',
    },
  },
  PAY_METHOD: {
    CARD: '11',
    BANK: '21',
    VIRTUAL_BANK: '22',
    CELLPHONE: '31',
    NAVER_POINT: '50',
    ISSUING_BILLING_KEY: '81',
  },
  PAY_METHOD_INFO: {
    BILLING_KEY_METHOD_INFO: {
      CERT_TYPE: '0',
    },
  },
  CURRENCY: {
    KRW: '00',
  },
  DEVICE_TYPE: {
    MOBILE: 'mobile',
    PC: 'pc',
  },
  CLIENT_POPUP_TYPE: {
    INTEGRATED_SCREEN: '00',
  },
  CHARSET: {
    EUC_KR: 'EUC-KR',
    UTF8: 'UTF-8',
  },
  INSTALLMENT_FLAG: {
    DEFAULT: '',
  },
  INSTALLMENT_TERM: {
    DEFAULT: '',
  },
  APP_SCHEME: {
    DEFAULT: '',
  },
  SERVICE_CALL: {
    DEFAULT: '',
  },
  USED_CARD_CODE: {
    DEFAULT: '',
  },
  WINDOW_TYPE: {
    DEFAULT: 'submit',
    MOBILE: 'iframe',
  },
  SAMSUNG_PAYMENT_VERSION: {
    LATEST: '2',
  },
};

export const ONEPG_REQUEST_VALUES = {
  CREDIT_CARD_CODE: {
    [CREDIT_CARD_ISSUERS.BC_CARD]: 'BCC',
    [CREDIT_CARD_ISSUERS.KB_CARD]: 'CNB',
    [CREDIT_CARD_ISSUERS.SAMSUNG_CARD]: 'WIN',
    [CREDIT_CARD_ISSUERS.SHINHAN_CARD]: 'LGC',
    [CREDIT_CARD_ISSUERS.KAKAO_BANK]: 'KKB',
    [CREDIT_CARD_ISSUERS.HYUNDAI_CARD]: 'DIN',
    [CREDIT_CARD_ISSUERS.LOTTE_CARD]: 'AMX',
    [CREDIT_CARD_ISSUERS.NH_CARD]: 'NLC',
    [CREDIT_CARD_ISSUERS.HANA_CARD]: 'HNB',
    [CREDIT_CARD_ISSUERS.WOORI_CARD]: 'PHB',
    [CREDIT_CARD_ISSUERS.SUHYUP_CARD]: 'NFF',
    [CREDIT_CARD_ISSUERS.JEONBUK_CARD]: 'CBB',
    [CREDIT_CARD_ISSUERS.GWANGJU_BANK]: 'KJB',
    [CREDIT_CARD_ISSUERS.JEJU_CARD]: 'CJB',
    [CREDIT_CARD_ISSUERS.CITY_CARD]: 'CIT',
    [CREDIT_CARD_ISSUERS.SHINHYUP_CARD]: 'SYH',
    [CREDIT_CARD_ISSUERS.KAKAO_MONEY_OR_NAVER_POINT]: 'MONEY',
  },
  PAYMENT_METHOD: {
    WEB_PAY: 'easyCreditCard',
    EASY_PAY: 'easyPay',
  },
  PAYMENT_TYPE: {
    KAKAO_PAY: 'kakaoCert',
    NAVER_PAY: 'naverPayCert',
    SAMSUNG_PAY: 'samsungPay',
  },
};

export const AUTH_OR_DIRECT_PAYMENT_CALLBACK_URL = `${process.env.REACT_APP_API_URL}/v1/payments/callback`;

export const PAYMENT_GATEWAY_APIS = {
  [PaymentGatewayType.KICC]: {
    [PaymentMethodType.WEB_PAY]: `${process.env.REACT_APP_KICC_WEB_AND_CARD_PAYMENT_API_URL}`,
    [PaymentMethodType.KAKAO_PAY]: `${process.env.REACT_APP_KICC_DIRECT_PAYMENT_API_BASE_URL}/kko/DirectKakaoPayReqAction.do`,
    [PaymentMethodType.NAVER_PAY]: `${process.env.REACT_APP_KICC_DIRECT_PAYMENT_API_BASE_URL}/npay/DirectNaverPayReqAction.do`,
    [PaymentMethodType.SAMSUNG_PAY]: `${process.env.REACT_APP_KICC_DIRECT_PAYMENT_API_BASE_URL}/smp/v2/DirectSmpCertReqAction.do`,
    [PaymentMethodType.CREDIT_CARD]: `${process.env.REACT_APP_API_URL}/v1/cards`,
  },
  [PaymentGatewayType.ONE_PG]: {
    [PaymentMethodType.WEB_PAY]: `${process.env.REACT_APP_ONEPG_PAYMENT_URL}/pay/easyCreditCard`,
    [PaymentMethodType.KAKAO_PAY]: `${process.env.REACT_APP_ONEPG_PAYMENT_URL}/pay/easyPay/${ONEPG_REQUEST_VALUES.PAYMENT_TYPE.KAKAO_PAY}`,
    [PaymentMethodType.NAVER_PAY]: `${process.env.REACT_APP_ONEPG_PAYMENT_URL}/pay/easyPay/${ONEPG_REQUEST_VALUES.PAYMENT_TYPE.NAVER_PAY}`,
    [PaymentMethodType.SAMSUNG_PAY]: `${process.env.REACT_APP_ONEPG_PAYMENT_URL}/pay/easyPay/${ONEPG_REQUEST_VALUES.PAYMENT_TYPE.SAMSUNG_PAY}`,
    [PaymentMethodType.CREDIT_CARD]: `${process.env.REACT_APP_ONEPG_PAYMENT_URL}`, // TODO: need to change
  },
};

export const PAYMENT_GATEWAY_REQUEST_VALUES = {
  [PaymentGatewayType.KICC]: KICC_REQUEST_VALUES,
  [PaymentGatewayType.ONE_PG]: ONEPG_REQUEST_VALUES,
  [PaymentGatewayType.AIRWALLEX]: {},
};

export const KICC_CREDIT_CARD_TYPE = {
  N: CREDIT_CARD_TYPE.CREDIT_CARD,
  Y: CREDIT_CARD_TYPE.CHECK_CARD,
  G: CREDIT_CARD_TYPE.GIFT,
};

export const KICC_SERVER_API_RESULT_CODE = {
  SUCCESS: '0000',
};

export const PAYMENT_CALLBACK_RESULT_CODE = {
  SUCCESS: '00',
};

export const COUNTRY_VAT_PERCENT: Record<COUNTRY_CODE, number> = {
  [COUNTRY_CODE.KR]: (1 / 11) * 100,
  [COUNTRY_CODE.SG]: 9,
};

export const COUNTRY_CURRENCY: Record<string, string> = {
  KR: 'KRW',
  SG: 'SGD',
};
