import * as React from "react";

function SvgMicrophoneOff(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4027 16.1099L20.6464 21.3536L21.3535 20.6465L3.35353 2.64648L2.64642 3.35359L9.5 10.2072V13.1667C9.5 13.5616 9.6248 14.135 10.0068 14.6213C10.4065 15.1302 11.0546 15.5 12 15.5C12.935 15.5 13.5652 15.1394 13.9578 14.665L14.6938 15.401C14.1771 16.0115 13.3443 16.5 12 16.5C10.5534 16.5 9.70259 15.9345 9.19918 15.2671C8.67761 14.5756 8.5 13.7333 8.5 13.1818V11H7.5V13.1818C7.5 13.903 7.72239 14.9698 8.40082 15.8693C9.02889 16.7019 10.0249 17.3588 11.5 17.4799V21H12.5V17.4799C13.8329 17.3705 14.7722 16.8239 15.4027 16.1099ZM12 3.5C11.0546 3.5 10.4065 3.86981 10.0068 4.37865C9.6248 4.86498 9.5 5.43843 9.5 5.83333V8.20711L14.5 13.2071V5.83333C14.5 5.43843 14.3752 4.86498 13.9932 4.37865C13.5935 3.86981 12.9454 3.5 12 3.5ZM16.5 13.1818V11H15.5V13.1818C15.5 13.3814 15.4765 13.6265 15.4181 13.8931L16.395 14.1069C16.4681 13.7727 16.5 13.456 16.5 13.1818Z"
        fill="#444C54"
      />
    </svg>
  );
}

export default SvgMicrophoneOff;
