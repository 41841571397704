import * as React from "react";

function SvgMaintenance(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.572 14.526l-.47.47a1.56 1.56 0 000 2.204.02.02 0 00.027.002l1.503-1.085a.71.71 0 01.917.073l.122.122a.71.71 0 01.073.916L6.66 18.73a.023.023 0 00.002.03 1.754 1.754 0 002.48 0l.3-.3c.642-.641.85-1.598.535-2.448l-.144-.39a.074.074 0 01.017-.079l5.694-5.693a.074.074 0 01.078-.017l.39.144c.85.316 1.807.107 2.448-.535l.3-.3a1.754 1.754 0 000-2.48.023.023 0 00-.03-.002l-1.502 1.084a.71.71 0 01-.916-.074l-.122-.121a.71.71 0 01-.073-.917l1.085-1.503a.02.02 0 00-.002-.027 1.56 1.56 0 00-2.205 0l-.469.47a2.37 2.37 0 00-.582 2.396l.087.274a.071.071 0 01-.018.072l-5.699 5.7a.071.071 0 01-.072.017l-.274-.088a2.37 2.37 0 00-2.396.583z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.007 13.828l3.405 4.215c.253.314.66.46 1.056.379v0a2.515 2.515 0 001.956-1.956v0a1.078 1.078 0 00-.379-1.056l-4.216-3.403M10.497 11.475L7.55 8.527l-.466-.014a1 1 0 01-.823-.48L5.327 6.5l1.17-1.17 1.535.934a1 1 0 01.48.823l.014.466 2.947 2.947"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgMaintenance;
