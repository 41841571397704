import * as React from "react";

function SvgVideoOff(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 10.232C20 9.41739 19.0782 8.94457 18.4166 9.41983L14.8998 11.9462L18.4166 14.4725C19.0782 14.9477 20 14.4749 20 13.6603V10.232Z"
        stroke="#444C54"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6465 21.3536L0.646484 3.35359L1.35359 2.64648L19.3536 20.6465L18.6465 21.3536Z"
        fill="#444C54"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.429 15.7219V8.46552C15.429 7.38268 14.5554 6.5 13.4718 6.5H6.20711L7.20711 7.5H13.4718C13.9977 7.5 14.429 7.92959 14.429 8.46552V14.7219L15.429 15.7219ZM5.79289 7.5H5.45719C4.93123 7.5 4.5 7.92959 4.5 8.46552V15.7931C4.5 16.3291 4.93123 16.7587 5.45719 16.7587H13.4718C13.8959 16.7587 14.2585 16.4793 14.3829 16.09L15.1302 16.8373C14.7844 17.3902 14.1719 17.7587 13.4718 17.7587H5.45719C4.37358 17.7587 3.5 16.876 3.5 15.7931V8.46552C3.5 7.58367 4.07939 6.83457 4.87976 6.58686L5.79289 7.5Z"
        fill="#444C54"
      />
    </svg>
  );
}

export default SvgVideoOff;
