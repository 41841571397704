import * as React from 'react';

// TODO: this is outline type -> need filled type later

function SvgSheetNote(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="icon_order">
        <g id="color_fill">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.24836 6.49668C7.11119 6.49668 7 6.60788 7 6.74504V26.7188C7 26.856 7.11119 26.9672 7.24836 26.9672H23.477C23.6142 26.9672 23.7254 26.856 23.7254 26.7188V6.74504C23.7254 6.60788 23.6142 6.49668 23.477 6.49668H7.24836ZM5 6.74504C5 5.50331 6.00663 4.49668 7.24836 4.49668H23.477C24.7188 4.49668 25.7254 5.50331 25.7254 6.74504V26.7188C25.7254 27.9605 24.7188 28.9672 23.477 28.9672H7.24836C6.00663 28.9672 5 27.9605 5 26.7188V6.74504Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6172 2C12.1695 2 12.6172 2.44772 12.6172 3V6.74508C12.6172 7.29737 12.1695 7.74508 11.6172 7.74508C11.0649 7.74508 10.6172 7.29737 10.6172 6.74508V3C10.6172 2.44772 11.0649 2 11.6172 2ZM19.1073 2C19.6596 2 20.1073 2.44772 20.1073 3V6.74508C20.1073 7.29737 19.6596 7.74508 19.1073 7.74508C18.5551 7.74508 18.1073 7.29737 18.1073 6.74508V3C18.1073 2.44772 18.5551 2 19.1073 2Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.36914 12.3627C9.36914 11.8104 9.81686 11.3627 10.3691 11.3627H20.356C20.9083 11.3627 21.356 11.8104 21.356 12.3627C21.356 12.915 20.9083 13.3627 20.356 13.3627H10.3691C9.81686 13.3627 9.36914 12.915 9.36914 12.3627ZM9.36914 17.3561C9.36914 16.8039 9.81686 16.3561 10.3691 16.3561H17.8593C18.4116 16.3561 18.8593 16.8039 18.8593 17.3561C18.8593 17.9084 18.4116 18.3561 17.8593 18.3561H10.3691C9.81686 18.3561 9.36914 17.9084 9.36914 17.3561ZM9.36914 22.3496C9.36914 21.7973 9.81686 21.3496 10.3691 21.3496H15.3626C15.9149 21.3496 16.3626 21.7973 16.3626 22.3496C16.3626 22.9019 15.9149 23.3496 15.3626 23.3496H10.3691C9.81686 23.3496 9.36914 22.9019 9.36914 22.3496Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSheetNote;
