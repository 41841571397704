import * as React from "react";

function SvgSearchProperty(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.571 13.143h-.5a.5.5 0 00.5.5v-.5zm4.572 0v.5a.5.5 0 00.5-.5h-.5zm0-2.857h.5a.5.5 0 00-.147-.354l-.353.354zM10.857 8l.354-.354a.5.5 0 00-.707 0l.353.354zm-2.286 2.286l-.353-.354a.5.5 0 00-.147.354h.5zm11.783 9.36l-4.572-4.571-.707.707 4.571 4.572.708-.708zm-9.497-2.432A6.357 6.357 0 014.5 10.857h-1a7.357 7.357 0 007.357 7.357v-1zm6.357-6.357a6.357 6.357 0 01-6.357 6.357v1a7.357 7.357 0 007.357-7.357h-1zM10.857 4.5a6.357 6.357 0 016.357 6.357h1A7.357 7.357 0 0010.857 3.5v1zm0-1A7.357 7.357 0 003.5 10.857h1A6.357 6.357 0 0110.857 4.5v-1zM8.571 13.643h4.572v-1H8.57v1zm5.072-.5v-2.857h-1v2.857h1zm-.147-3.21l-2.285-2.287-.707.708 2.285 2.285.707-.707zm-2.992-2.287L8.218 9.932l.707.707 2.286-2.285-.707-.708zm-2.433 2.64v2.857h1v-2.857h-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSearchProperty;
