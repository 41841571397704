import * as React from "react";

function SvgLoop(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 7.429l.354.353a.5.5 0 000-.707L20 7.429zM4 16.57l-.354-.353a.5.5 0 000 .707L4 16.571zM16.218 4.354l3.428 3.428.708-.707-3.429-3.429-.707.708zm3.428 2.721l-3.428 3.429.707.707 3.429-3.429-.708-.707zM7.782 19.646l-3.428-3.428-.708.707 3.429 3.429.707-.708zm-3.428-2.721l3.428-3.429-.707-.707-3.429 3.429.708.707zM4 17.071h12.571v-1H4v1zm16.5-3.928v-1.714h-1v1.714h1zm-3.929 3.928a3.929 3.929 0 003.929-3.928h-1a2.929 2.929 0 01-2.929 2.928v1zM20 6.93H7.429v1H20v-1zM3.5 10.857v1.714h1v-1.714h-1zM7.429 6.93A3.929 3.929 0 003.5 10.857h1A2.929 2.929 0 017.429 7.93v-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLoop;
