import * as React from "react";

function SvgMask(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.697 9.413c.528-.308 1.743-1.03 2.377-1.453l.154-.104c.82-.56 2.229-1.523 4.335-.16a17.61 17.61 0 001.954 1.08c.736.362 1.286.631 1.743 1.165.769.896.528 2.509.264 3.433-.264.924-1.188 3.565-2.376 4.093-1.189.528-6.074.528-7.13 0-1.057-.528-1.717-1.32-2.51-3.697-1.018-3.056.487-3.943 1.161-4.341l.028-.016zm2.96.912a3.491 3.491 0 011.635-.731c.658-.096 1.384.035 2.05.7a.5.5 0 10.707-.707c-.92-.919-1.976-1.118-2.902-.983a4.489 4.489 0 00-2.13.953.5.5 0 00.64.768z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.136 11.628c-.133-.066-.257-.127-.368-.185l.464-.886c.08.042.179.09.293.146.479.236 1.217.599 1.812 1.082.75.608 1.43 1.54 1.042 2.793-.33 1.065-1.205 1.523-2.143 1.785-.469.132-.988.224-1.51.314l-.113.02c-.493.085-.994.171-1.498.29l-.23-.974c.536-.126 1.065-.217 1.554-.301l.117-.02c.528-.092.999-.176 1.41-.292.825-.23 1.28-.543 1.458-1.118.21-.676-.098-1.218-.717-1.72-.496-.403-1.09-.697-1.571-.934zM6.864 11.628c.133-.066.257-.127.368-.185l-.464-.886c-.08.042-.179.09-.293.146-.479.236-1.217.599-1.812 1.082-.75.608-1.43 1.54-1.042 2.793.34 1.098 1.387 1.54 2.408 1.79.525.128 1.1.22 1.665.309l.008.001c.576.091 1.143.182 1.683.309l.23-.974c-.578-.136-1.178-.23-1.743-.32l-.013-.002a20.194 20.194 0 01-1.592-.295c-.99-.243-1.523-.572-1.69-1.114-.21-.676.097-1.218.716-1.72.496-.403 1.09-.697 1.571-.934z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMask;
