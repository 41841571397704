import * as React from "react";

function SvgSoundOn(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.003 16l.257-.429-.119-.071h-.138v.5zm0-8v.5h.138l.119-.071L7.003 8zm6.67-4h.5a.5.5 0 00-.757-.429l.258.429zm0 16l-.257.429a.5.5 0 00.758-.429h-.5zm7.025-12.354l-.354-.353-.707.707.354.354.707-.708zm-.707 8l-.354.354.707.707.354-.353-.707-.708zM7.003 15.5H4.334v1h2.669v-1zm-2.669 0a.833.833 0 01-.834-.833h-1c0 1.014.822 1.833 1.834 1.833v-1zm-.834-.833V9.333h-1v5.334h1zm0-5.334c0-.46.373-.833.834-.833v-1C3.322 7.5 2.5 8.32 2.5 9.333h1zm.834-.833h2.669v-1H4.334v1zm2.926-.071l6.67-4-.514-.858-6.67 4 .514.858zM13.174 4v16h1V4h-1zm.757 15.571l-6.671-4-.514.858 6.67 4 .515-.858zM20.345 8l-.354.353-.002-.001.006.006.03.034c.03.033.074.085.128.158.11.146.26.37.412.674.303.605.614 1.53.614 2.776h1c0-1.42-.356-2.496-.72-3.224a5.429 5.429 0 00-.506-.826 3.726 3.726 0 00-.254-.303L20.345 8zm.834 4c0 1.247-.311 2.17-.614 2.776a4.431 4.431 0 01-.412.674 2.705 2.705 0 01-.158.192l-.006.006h.001v-.001l.355.353c.353.354.353.354.354.353v-.001l.003-.002.005-.006a1.52 1.52 0 00.071-.077c.044-.05.105-.123.175-.217.141-.188.324-.463.506-.826.364-.728.72-1.804.72-3.224h-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSoundOn;
